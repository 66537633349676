import { FaCloudUploadAlt } from "react-icons/fa"
import { Box, TextField, MenuItem, Button } from "@mui/material"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { titleCase } from "../../utils/helpers"
import countryList from "react-select-country-list"
import { industry } from "../../utils/data"
import { useMemo } from "react"

const CompanyProfile = ({ formik }) => {
  const options = useMemo(() => countryList().getData(), [])

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        width: "100%",
        mt: "1rem",
        mx: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // "& .MuiTextField-root": { mt: "1rem" },
        "& .MuiSvgIcon-root": {
          color: "#000000",
        },
      }}
      onSubmit={formik.handleSubmit}
    >
      {/**company logo */}
      <Box sx={{ width: "100%" }}>
        <input
          type="file"
          id="company_logo"
          accept="image/*"
          onChange={e => {
            formik.setFieldValue("company_logo", e.currentTarget.files[0])
          }}
          hidden
        />

        <label
          htmlFor="company_logo"
          className={`${
            Boolean(formik.errors.company_logo)
              ? "border-[#d32f2f]"
              : "border-[#cccccc]"
          } bg-white border rounded-[4px]  mt-4 text-black text-base w-full cursor-pointer flex items-center justify-between`}
        >
          <span className="py-[8.5px] px-[14px]">
            {formik.values.company_logo?.name
              ? formik.values.company_logo?.name
              : formik.values.company_logo
              ? formik.values.company_logo
              : "Choose a file..."}
          </span>
          <span className="flex items-center justify-end bg-gray-100 border-l border-[#cccccc] py-[8.5px] px-[14px]">
            <FaCloudUploadAlt style={{ marginRight: "8px" }} /> Choose Profile
            Picture
          </span>
        </label>

        {Boolean(formik.errors.company_logo) && (
          <p className="text-[#d32f2f] text-xs my-1 ml-[14px] tracking-[0.0333em]">
            {formik.touched.company_logo && formik.errors.company_logo}
          </p>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "1rem",
          width: "100%",
        }}
      >
        {/**company_name */}
        <Box sx={{ width: "49%" }}>
          <TextField
            required
            variant="outlined"
            fullWidth
            size="small"
            id="company_name"
            label="Company Name"
            value={titleCase(formik.values.company_name)}
            onChange={formik.handleChange("company_name")}
            error={
              formik.touched.company_name && Boolean(formik.errors.company_name)
            }
            helperText={
              formik.touched.company_name && formik.errors.company_name
            }
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        <Box sx={{ width: "49%" }}>
          <TextField
            required
            variant="outlined"
            fullWidth
            size="small"
            id="industry"
            label="Industry"
            value={formik.values.industry}
            onChange={formik.handleChange("industry")}
            select
            SelectProps={{ MenuProps: { disableScrollLock: true } }}
            error={formik.touched.industry && Boolean(formik.errors.industry)}
            helperText={formik.touched.industry && formik.errors.industry}
            InputLabelProps={{ shrink: true }}
            //placeholder={formik.values.industry}
          >
            {industry.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "1rem",
          width: "100%",
        }}
      >
        {/**Website */}
        <Box sx={{ width: "49%" }}>
          <TextField
            required
            variant="outlined"
            fullWidth
            size="small"
            id="company_website"
            label="Company Website"
            value={formik.values.company_website}
            onChange={formik.handleChange("company_website")}
            error={
              formik.touched.company_website &&
              Boolean(formik.errors.company_website)
            }
            helperText={
              formik.touched.company_website && formik.errors.company_website
            }
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        {/*Company Email */}
        <Box sx={{ width: "49%" }}>
          <TextField
            required
            variant="outlined"
            fullWidth
            size="small"
            id="company_email"
            label="Company Email"
            value={formik.values.company_email}
            onChange={formik.handleChange("company_email")}
            error={
              formik.touched.company_email &&
              Boolean(formik.errors.company_email)
            }
            helperText={
              formik.touched.company_email && formik.errors.company_email
            }
            InputLabelProps={{ shrink: true }}
            disabled
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "1rem",
          width: "100%",
        }}
      >
        <Box sx={{ width: "49%" }}>
          <PhoneInput
            inputProps={{
              id: "phone",
              required: true,
            }}
            inputStyle={{ width: "100%" }}
            country={"ng"}
            value={formik.values.company_phone_number}
            placeholder="+234 812 345 6789"
            onChange={phone =>
              formik.setFieldValue("company_phone_number", phone)
            }
            autoComplete="off"
          />

          {Boolean(formik.errors.company_phone_number) ? (
            <p className="text-[#d32f2f] text-xs my-1 ml-[14px] tracking-[0.0333em]">
              {formik.touched.company_phone_number &&
                formik.errors.company_phone_number}
            </p>
          ) : (
            <p className="text-gray-500 text-xs my-1 ml-[14px] tracking-[0.0333em]">
              Provide company phone number
            </p>
          )}
        </Box>

        {/**Country */}
        <Box sx={{ width: "49%" }}>
          <TextField
            required
            variant="outlined"
            fullWidth
            size="small"
            id="country"
            label="Country"
            value={formik.values.country}
            onChange={formik.handleChange("country")}
            error={formik.touched.country && Boolean(formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country}
            InputLabelProps={{ shrink: true }}
            select
            SelectProps={{ MenuProps: { disableScrollLock: true } }}
          >
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "1rem",
          width: "100%",
        }}
      >
        {/**Contact Person */}
        <Box sx={{ width: "49%" }}>
          <TextField
            required
            variant="outlined"
            fullWidth
            size="small"
            id="contact_name"
            label="Contact Person"
            value={titleCase(formik.values.contact_name)}
            onChange={formik.handleChange("contact_name")}
            error={
              formik.touched.contact_name && Boolean(formik.errors.contact_name)
            }
            helperText={
              formik.touched.contact_name && formik.errors.contact_name
            }
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        {/**contact person phone number */}
        <Box sx={{ width: "49%" }}>
          <PhoneInput
            inputProps={{
              id: "phone",
              required: true,
            }}
            inputStyle={{ width: "100%" }}
            country={"ng"}
            value={formik.values.contact_phone_number}
            placeholder="+234 812 345 6789"
            onChange={phone =>
              formik.setFieldValue("contact_phone_number", phone)
            }
            autoComplete="off"
          />

          {Boolean(formik.errors.contact_phone_number) ? (
            <p className="text-[#d32f2f] text-xs my-1 ml-[14px] tracking-[0.0333em]">
              {formik.touched.contact_phone_number &&
                formik.errors.contact_phone_number}
            </p>
          ) : (
            <p className="text-gray-500 text-xs my-1 ml-[14px] tracking-[0.0333em]">
              Provide contact phone number
            </p>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "1rem",
          width: "100%",
        }}
      ></Box>

      <Box sx={{ mt: "1rem", display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          type="submit"
          disabled={!formik.dirty}
          sx={{
            p: "0.75rem 3rem",
            backgroundColor: "#3F51B5",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
        >
          UPDATE DETAILS
        </Button>
      </Box>
    </Box>
  )
}

export default CompanyProfile
