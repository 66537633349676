import React from "react";
import { titleCase } from "../utils/helpers";
import { filteredUnexpiredJobcards } from "../utils/helpers"; // Import the function for filtering expired jobs

const JobsByCategory = ({ data, category }) => {
  // Filter unexpired job cards
  const unexpiredJobCards = filteredUnexpiredJobcards(data);

  const groupedData = {};

  // Group the data based on selected category
  unexpiredJobCards.forEach((job) => {
    let key = "";

    switch (category) {
      case 1:
        key = `${job.job_city}, ${job.job_country}`;
        break;
      case 2:
        key = `${job.company_name}`;
        break;
      case 3:
        key = `${job.contract_type}`;
        break;
      default:
        key = `${job.job_title}`;
        break;
    }

    // Process the key by removing special characters and the text inside brackets
    let processedKey = key.toLowerCase();

    if (category === 0) {
      // Process the key by removing content within brackets and special characters
      processedKey = processedKey.replace(/\s*\([^)]*\)|[^\w\s]+/g, "");
    }

    if (!groupedData[processedKey]) {
      groupedData[processedKey] = { count: 1, job };
    } else {
      groupedData[processedKey].count += 1;
    }
  });

  // Create an array of objects with the grouped data
  const groupedArray = Object.entries(groupedData).map(([key, value]) => ({
    key,
    count: value.count,
    job: value.job,
  }));

  return (
    <div className="py-5 border-gray-100 text-black grid gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4">
      {groupedArray.map((group, index) => (
        <div className="py-5 border-gray-100 text-black" key={index}>
          <a href={`/jobs/collections/${category}/${group.key}`}>
            <span className="text-gray-300 text-xs md:text-xs">
              {titleCase(group.key)}{" "}
              <span className="text-orange-400 ml-1">({group.count})</span>
            </span>
          </a>
        </div>
      ))}
    </div>
  );
};

export default JobsByCategory;
