import { useEffect, useState } from "react"
import AdminLayout from "../components/AdminLayout"
import JobCard from "../components/JobCard"
import { jobs } from "../utils/data"
import { useNavigate, Link, useLocation } from "react-router-dom"
import { ImSpinner3 } from "react-icons/im"
import AdminHeader from "../components/AdminHeader"
import useAuth from "../hooks/useAuth"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import { Helmet } from "react-helmet-async"

const Jobs = () => {
  const [data, setData] = useState("")
  const [dataStatus, setDataStatus] = useState()
  const [searchResults, setSearchResults] = useState([])
  const [query, setQuery] = useState("")
  const [indexOfLastJob, setIndexOfLastJob] = useState(6)
  const [status, setStatus] = useState(0)
  const [applicants, setApplicants] = useState([])
  const [page, setPage] = useState(1)

  const currentJobs = array => {
    const newArray = array.slice(0, indexOfLastJob)
    return newArray
  }
  const [error, setError] = useState()

  const navigate = useNavigate()
  const location = useLocation()
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const request = {
      signal: controller.signal,
      headers: {
        authorization: `Bearer ${auth?.accessToken}`,
      },
    }

    const getData = async () => {
      const res = await axiosPrivate
        .get(`jobs/organization/${auth?.companyId}`, request)
        .catch(err => {
          console.error(err?.response)
          setError(err?.response.data.message)
          // navigate("/admin-login", { state: { from: location }, replace: true })
        })
      if (res?.data) {
        isMounted && setData(res?.data?.data)
      }
    }

    getData()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  const search = array => {
    return array.filter(
      item =>
        item.job_title.includes(query) ||
        item.company_name.includes(query) ||
        item.job_city.includes(query) ||
        item.job_country.includes(query)
    )
  }

  return (
    <AdminLayout page="jobs" role={auth?.role}>
      <Helmet>
        <title>Jobs | Edge</title>
      </Helmet>

      <AdminHeader
        page="manage-jobs"
        setSearchResults={setSearchResults}
        setQuery={setQuery}
      />
      {data ? (
        <div className="xl:px-12 sm:px-6 px-4 py-6 space-y-4 w-full">
          <div className="bg-white py-6 px-4 rounded-md shadow space-y-3">
            <span className="text-black">Search Results</span>
            {currentJobs(searchResults).map(job => (
              <Link to={`/jobs/${job.id}`} key={job.id}>
                <div className="py-2 border-b border-gray-100">
                  <JobCard data={job} />
                </div>
              </Link>
            ))}
            {searchResults.length > indexOfLastJob && (
              <button className="border-none pt-3 w-full text-center text-blue-700 hover:text-blue-400">
                Show More
              </button>
            )}
          </div>
          <div className="bg-white py-4 rounded-md shadow">
            <span className="text-black font-bold mx-4">Latest Jobs</span>
            <div className="grid 4xl:grid-cols-5 3xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 px-3 w-full">
              {currentJobs(search(data)).map(job => (
                <div className="px-3 py-4 w-full" key={job.id}>
                  <JobCard data={job} page="jobs" />
                </div>
              ))}
            </div>

            {data.length > indexOfLastJob ? (
              <div className="flex flex-col items-center justify-center mt-2.5">
                <button
                  className="bg-blueBtn text-white text-sm py-1.5 px-2.5 rounded text-center"
                  onClick={() => setIndexOfLastJob(indexOfLastJob + 6)}
                >
                  Show More
                </button>
              </div>
            ) : data.length > 6 ? (
              data.length < indexOfLastJob ? (
                <div className="flex flex-col items-center justify-center mt-2.5">
                  <button
                    className="bg-blueBtn text-white text-sm py-1.5 px-2.5 rounded text-center"
                    onClick={() => setIndexOfLastJob(indexOfLastJob - 6)}
                  >
                    Show Less
                  </button>
                </div>
              ) : null
            ) : null}
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-screen -mt-16">
          {error === "No job found!" ? (
            <div className="flex flex-col text-black">
              <img
                src={require("../assets/jobs.png")}
                alt=""
                width="150px"
                height="150px"
              />
              <p className="text-xl">No job found!</p>
            </div>
          ) : (
            <span className="flex text-black space-x-3 items-center justify-center">
              <ImSpinner3 size={48} className="animate-spin" />
              <p className="text-3xl">Loading</p>
            </span>
          )}
        </div>
      )}
    </AdminLayout>
  )
}

export default Jobs
