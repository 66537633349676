import React, { useState, useContext } from "react"
import { Link, useNavigate } from "react-router-dom"
import useAuth from "../hooks/useAuth"
import {
  FcSettings,
  FcManager,
  FcConferenceCall,
  FcBriefcase,
  FcStatistics,
} from "react-icons/fc"
import { IoIosArrowDown } from "react-icons/io"

import logo from "../assets/logopic_white.png"
import { SidebarContext } from "../contexts/SidebarContext"
import AccessControl from "./accessControl/AccessControl"

const Sidebar = ({ page, role }) => {
  //  styles
  const LinkReset = `p-0`
  const currentPage = `bg-slate-50 text-slate-700 font-bold cursor-pointer py-2 px-4 flex items-center w-full border-none`
  const regularPage = `text-white font-bold cursor-pointer py-2 px-4 flex items-center w-full hover:bg-slate-50 hover:text-slate-700`
  const currentSubMenu = `bg-slate-50 text-slate-700 font-bold cursor-pointer py-2 pl-10 pr-4 flex items-center w-full border-none`
  const regularSubMenu = `text-white font-bold cursor-pointer py-2 pl-10 pr-4 flex items-center w-full hover:bg-slate-50 hover:text-slate-700`

  // states, hooks, context
  const [showSubMenuApplicant, setShowSubMenuApplicant] = useState(false)
  const [showSubMenuRecruitment, setShowSubMenuRecruitment] = useState(false)
  const navigate = useNavigate()
  const { auth } = useAuth()
  const { showSidebar } = useContext(SidebarContext)

  return (
    <div
      className={`lg:w-1/4 lg:translate-x-0 lg:relative w-64 fixed inset-y-0 left-0 transform transition duration-200 shadow-2xl ease-in-out flex flex-col bg-slate-700 bg-cover text-white text-sm font-light z-50 overflow-y-auto ${
        showSidebar
          ? "translate-x-0 shadow-lg"
          : "-translate-x-full shadow-none"
      }`}
    >
      <div className="relative h-full flex flex-col justify-between">
        <div className="py-6">
          <Link to="/admin-dashboard" className={LinkReset}>
            <div className="flex items-center px-2">
              <img src={logo} alt="Edge" className="w-20" />
              <span className="text-lg text-white font-bold">THE EDGE APP</span>
            </div>
          </Link>

          <div className="flex flex-col mt-8">
            <Link to="/admin-dashboard" className={LinkReset}>
              <span
                className={page === "dashboard" ? currentPage : regularPage}
              >
                <FcStatistics size={24} className="mr-4" /> Dashboard
              </span>
            </Link>

            <div>
              <span
                className={
                  page.includes("applicants") ? currentPage : regularPage
                }
                onClick={() => {
                  setShowSubMenuApplicant(!showSubMenuApplicant)
                  setShowSubMenuRecruitment(false)
                }}
              >
                <FcConferenceCall size={24} className="mr-4" /> Applicant
                Management
                <IoIosArrowDown
                  size={20}
                  className={`ml-auto ${
                    showSubMenuApplicant ? "transform rotate-180" : ""
                  }`}
                  style={{
                    transform: showSubMenuApplicant ? "rotate(180deg)" : "none",
                  }}
                />
              </span>
              <div
                className={`${
                  showSubMenuApplicant ? "flex flex-col" : "hidden"
                } text-center bg-slate-600`}
              >
                <span
                  className={`${
                    page === "applicants" ? currentSubMenu : regularSubMenu
                  }`}
                  onClick={() => navigate("/applicants")}
                >
                  Manage Applicants
                </span>
                <span
                  className={`${
                    page === "applicants/upload"
                      ? currentSubMenu
                      : regularSubMenu
                  }`}
                  onClick={() => navigate("/applicants/upload")}
                >
                  Bulk Upload
                </span>
                <span
                  className={`${
                    page === "applicants/add" ? currentSubMenu : regularSubMenu
                  }`}
                  onClick={() => navigate("/applicants/add")}
                >
                  Add a new Applicant
                </span>
              </div>
            </div>

            <AccessControl
              userPermission={role}
              allowedPermissions={["superAdmin"]}
            >
              <Link to="/manage-admin" className={LinkReset}>
                <span
                  className={
                    page === "manage-admin" ? currentPage : regularPage
                  }
                >
                  <FcManager size={24} className="mr-4" /> Manage Admins
                </span>
              </Link>
            </AccessControl>

            <div>
              <span
                className={
                  page.includes("jobs") || page.includes("assessment")
                    ? currentPage
                    : regularPage
                }
                onClick={() => {
                  setShowSubMenuRecruitment(!showSubMenuRecruitment)
                  setShowSubMenuApplicant(false)
                }}
              >
                <FcBriefcase size={24} className="mr-4" /> Recruitment Tasks
                <IoIosArrowDown
                  size={20}
                  className={`ml-auto ${
                    showSubMenuRecruitment ? "transform rotate-180" : ""
                  }`}
                  style={{
                    transform: showSubMenuRecruitment
                      ? "rotate(180deg)"
                      : "none",
                  }}
                />
              </span>
              <div
                className={`${
                  showSubMenuRecruitment ? "flex flex-col" : "hidden"
                } text-center bg-slate-600`}
              >
                <span
                  className={`${
                    page === "jobs/add" ? currentSubMenu : regularSubMenu
                  }`}
                  onClick={() => navigate("/jobs/add")}
                >
                  Create a Job
                </span>
                <span
                  className={`${
                    page === "jobs" ? currentSubMenu : regularSubMenu
                  }`}
                  onClick={() => navigate("/jobs")}
                >
                  Manage Jobs
                </span>
                <span
                  className={`${
                    page === "assessment" ? currentSubMenu : regularSubMenu
                  }`}
                  onClick={() => navigate("/assessment")}
                >
                  Create Assessment
                </span>
                <span
                  className={`${
                    page === "assessments" ? currentSubMenu : regularSubMenu
                  }`}
                  onClick={() => navigate("/assessments")}
                >
                  Manage Assessment
                </span>
              </div>
            </div>

            <span
              className={page === "admin-settings" ? currentPage : regularPage}
              onClick={() => navigate("/admin-settings")}
            >
              <FcSettings size={24} className="mr-4" /> Settings
            </span>
          </div>
        </div>

        <div className="flex flex-col w-full">
          <img
            src={require("../assets/D1-image6.png")}
            alt=""
            className="relative left-20 w-16 h-16"
          />
          <div className="bg-bgcolor py-4 rounded-lg text-center">
            <h3 className="text-black font-bold text-sm">Support 24/7</h3>
            <p className="text-gray-400 text-xs">Contact Us anytime</p>
            <Link to="mailto:info@ivyarc.com">
              <button className="bg-blue-700 rounded-lg px-8 py-1 text-white my-3">
                info@ivyarc.com
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
