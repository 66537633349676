import { Box, Button } from "@mui/material"

const Preview = ({ formik, currentStep, setCurrentStep, selectedCurrency }) => {
  return (
    <div className="py-3 text-black">
      <div className="space-y-4">
        <div className="flex">
          <p className="text-lg font-semibold w-2/5">First Name:</p>
          <p className="w-3/5 text-sm break-words">
            {formik.values.first_name}
          </p>
        </div>
        <div className="flex">
          <p className="text-lg font-semibold w-2/5">Last Name:</p>
          <p className="w-3/5 text-sm break-words">{formik.values.last_name}</p>
        </div>
        <div className="flex">
          <p className="text-lg font-semibold w-2/5">Email:</p>
          <p className="w-3/5 text-sm break-words">{formik.values.email}</p>
        </div>
        <div className="flex">
          <p className="text-lg font-semibold w-2/5">Phone:</p>
          <p className="w-3/5 text-sm break-words">
            {formik.values.phone_number}
          </p>
        </div>
        <div className="flex">
          <p className="text-lg font-semibold w-2/5">Country:</p>
          <p className="w-3/5 text-sm break-words">{formik.values.country}</p>
        </div>
        <div className="flex">
          <p className="text-lg font-semibold w-2/5">LinkedIn:</p>
          <p className="w-3/5 text-sm break-words">{formik.values.linkedin}</p>
        </div>
        {formik.values?.github && (
          <div className="flex">
            <p className="text-lg font-semibold w-2/5">Github:</p>
            <p className="w-3/5 text-sm break-words">{formik.values.github}</p>
          </div>
        )}
        {formik.values?.resume && (
          <div className="flex">
            <p className="text-lg font-semibold w-2/5">Resume:</p>
            <p className="w-3/5 text-sm break-words">
              {formik.values.resume?.name}
            </p>
          </div>
        )}

        {formik.values?.cover_letter && (
          <div className="flex">
            <p className="text-lg font-semibold w-2/5">Cover Letter:</p>
            <p className="w-3/5 text-sm break-words">
              {formik.values?.cover_letter.name}
            </p>
          </div>
        )}
        <div className="flex">
          <p className="text-lg font-semibold w-2/5">Position:</p>
          <p className="w-3/5 text-sm break-words">{formik.values.position}</p>
        </div>
        <div className="flex">
          <p className="text-lg font-semibold w-2/5">Salary Expectation:</p>
          {formik.values.salary ? (
            <p className="w-3/5 text-sm break-words">{`${formik.values.salary} ${formik.values.currency}`}</p>
          ) : null}
        </div>
        <div className="flex">
          <p className="text-lg font-semibold w-2/5">Total Work Experience:</p>
          {formik.values.experience ? (
            <p className="w-3/5 text-sm break-words">{`${
              formik.values.experience === 1
                ? `${formik.values.experience} year`
                : `${formik.values.experience} years`
            }`}</p>
          ) : null}
        </div>
        <div className="flex">
          <p className="text-lg font-semibold w-2/5">Notice Period:</p>
          {formik.values.notice ? (
            <p className="w-3/5 text-sm break-words">{`${
              formik.values.notice === 1
                ? `${formik.values.notice} week`
                : `${formik.values.notice} weeks`
            }`}</p>
          ) : null}
        </div>
        <div className="flex">
          <p className="text-lg font-semibold w-2/5">Employment Type:</p>
          <p className="w-3/5 text-sm break-words">
            {formik.values.availability}
          </p>
        </div>
        <div className="flex">
          <p className="text-lg font-semibold w-2/5">Contract Type:</p>
          <p className="w-3/5 text-sm break-words">
            {formik.values.contract_type}
          </p>
        </div>
        <div className="flex">
          <p className="text-lg font-semibold w-2/5">Status:</p>
          <p className="w-3/5 text-sm break-words">{formik.values.status}</p>
        </div>
      </div>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#3F51B5",
            mt: "1rem",
            py: "0.5rem",
            px: "1.5rem",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
          onClick={() => {
            setCurrentStep(currentStep - 1)
          }}
        >
          PREV
        </Button>

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#3F51B5",
            mt: "1rem",
            py: "0.5rem",
            px: "1.5rem",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
          onClick={e => {
            formik.handleSubmit(e)
          }}
        >
          SUBMIT
        </Button>
      </Box>
    </div>
  )
}

export default Preview
