import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import LandingHeader from "../components/LandingPage/LandingHeader";
import JobCard from "../components/JobCard";
import axios from "../api/axios";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { filteredUnexpiredJobcards } from "../utils/helpers";
import { data } from "autoprefixer";

const FilteredJobsPage = () => {
  const [queryJob, setQueryJob] = useState("");
  const [queryCompany, setQueryCompany] = useState("");
  const [query, setQuery] = useState("");
  let params = useParams();
  const [jobs, setJobs] = useState([{}]);
  const [indexOfLastJob, setIndexOfLastJob] = useState(6);
  const currentJobs = (array) => {
    const newArray = array.slice(0, indexOfLastJob);
    return newArray;
  };
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const request = {
      signal: controller.signal,
    };

    const getData = async () => {
      const res = await axios
        .get(
          `jobs/collections/filters?category=${params.category}&key=${params.key}`,
          request
        )
        .catch(async (err) => {
          console.error(err?.response);
        });
      if (res?.data?.data) {
        isMounted && setJobs(res?.data?.data);
      }
    };

    getData();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [params]);

  const search = (array, query) => {
    const lowercaseQuery = query.toLowerCase();
    return array.filter(
      (item) =>
        (item.job_title &&
          item.job_title.toLowerCase().includes(lowercaseQuery)) ||
        (item.company_name &&
          item.company_name.toLowerCase().includes(lowercaseQuery)) ||
        (item.job_city &&
          item.job_city.toLowerCase().includes(lowercaseQuery)) ||
        (item.job_country &&
          item.job_country.toLowerCase().includes(lowercaseQuery))
    );
  };

  const search2 = (array, queryJob) => {
    const lowercaseQuery = queryJob.toLowerCase();
    return array.filter(
      (item) =>
        (item.job_title &&
          item.job_title.toLowerCase().includes(lowercaseQuery)) ||
        (item.company_name &&
          item.company_name.toLowerCase().includes(lowercaseQuery))
    );
  };

  const search3 = (array, queryCompany) => {
    const lowercaseQuery = queryCompany.toLowerCase();
    return array.filter(
      (item) =>
        (item.job_city &&
          item.job_city.toLowerCase().includes(lowercaseQuery)) ||
        (item.job_country &&
          item.job_country.toLowerCase().includes(lowercaseQuery))
    );
  };

  console.log({ jobs });

  const unexpiredJobCards = filteredUnexpiredJobcards(jobs);

  console.log({ unexpiredJobCards });

  return (
    <div className="flex w-full min-h-screen font-roboto relative backdrop-grayscale-[.5] bg-no-repeat bg-cover bg-fixed bg-center bg-[url('/public/techteam2.jpg')]">
      <LandingHeader setQuery={setQuery} />
      <div className="xl:px-16 mt-24 md:mt-16 px-8 py-10 w-full">
        <button
          onClick={() => navigate(-1)}
          className="flex py-1.5 px-6 bg-white text-black items-center border-2 rounded-2xl hover:bg-blue-200 hover:rounded-2xl"
        >
          <FaLongArrowAltLeft className="mr-2" />
          Go back
        </button>
        <div className="text-center">
          <h1 className="text-white text-4xl uppercase font-bold">
            Filtered Jobs
          </h1>
        </div>
        <form action="" className="mt-8 pt-10 w-full">
          <div className="w-full flex flex-wrap justify-between">
            <div className="sm:w-1/2 w-full sm:pr-2 mt-2">
              <label htmlFor="" className="text-white text-sm font-bold mb-1">
                What
              </label>
              <input
                type="text"
                placeholder="Job title or Company"
                onChange={(event) => setQueryJob(event.target.value)}
                className="text-gray-700 font-semi-bold text-sm py-2 w-full border placeholder:text-sm placeholder: pl-3 drop-shadow outline-offset-1 outline-cyan-500"
              />
            </div>

            <div className="sm:w-1/2 w-full sm:pl-2 mt-2">
              <label htmlFor="" className="text-white text-sm font-bold mb-1">
                Where
              </label>
              <input
                type="text"
                placeholder="City or Country"
                onChange={(event) => setQueryCompany(event.target.value)}
                className="text-gray-700 font-semi-bold text-sm py-2 w-full border placeholder:text-sm placeholder: pl-3 drop-shadow outline-offset-1 outline-cyan-500 "
              />
            </div>
          </div>
        </form>

        <div className="mt-8">
          <div className="bg-slate-500 bg-opacity-50 py-4 rounded-md shadow">
            <span className="text-white font-bold mx-4">Searched Result :</span>
            <div className="grid 4xl:grid-cols-6 3xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 w-full px-3">
              {currentJobs(
                search3(search2(search(jobs, query), queryJob), queryCompany) &&
                  unexpiredJobCards
              ).map((job) => (
                <div className="px-3 py-4" key={job.id}>
                  <JobCard
                    data={job}
                    page="home-job-description"
                    key={job.id}
                  />
                </div>
              ))}
            </div>
            {jobs.length > indexOfLastJob && (
              <div className="flex flex-col items-center justify-center">
                <button
                  className="bg-blueBtn text-white text-sm py-1.5 px-2.5 rounded text-center hover:text-blue-400"
                  onClick={() => setIndexOfLastJob(indexOfLastJob + 6)}
                >
                  Show More
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilteredJobsPage;
