import { useState, useEffect } from "react"
import "react-calendar/dist/Calendar.css"
import { useNavigate, useLocation } from "react-router-dom"
import CandidateLayout from "../components/CandidateLayout"
import useAuth from "../hooks/useAuth"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import JobCard from "../components/JobCard"
import { Link } from "react-router-dom"
import CandidateHeader from "../components/CandidateHeader"
import useProfileChecker from "../hooks/useProfileChecker"
import Loading from "../components/Loading"
import { filteredUnexpiredJobcards } from "../utils/helpers"
import { Helmet } from "react-helmet-async"

const CandidateDashboard = () => {
  const [data, setData] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const from = location?.state?.from
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const [query, setQuery] = useState("")
  const [queryJob, setQueryJob] = useState("")
  const [queryCompany, setQueryCompany] = useState("")

  const [indexOfLastJob, setIndexOfLastJob] = useState(6)
  const currentJobs = array => {
    const newArray = array.slice(0, indexOfLastJob)
    return newArray
  }

  const profileChecker = useProfileChecker()
  profileChecker()

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const request = {
      signal: controller.signal,
      headers: {
        authorization: `Bearer ${auth?.accessToken}`,
      },
    }

    const getData = async () => {
      const res = await axiosPrivate.get("jobs/", request).catch(async err => {
        console.error(err?.response)
        // navigate("/", { state: { from: location }, replace: true })
      })
      if (res?.data?.data) {
        isMounted && setData(res?.data?.data)
      }
    }

    getData()
    setIsLoading(false)

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  const unexpiredJobCards = filteredUnexpiredJobcards(data)

  const search = (array, query) => {
    const lowercaseQuery = query.toLowerCase()

    return array.filter(
      item =>
        (item.job_title &&
          item.job_title.toLowerCase().includes(lowercaseQuery)) ||
        (item.company_name &&
          item.company_name.toLowerCase().includes(lowercaseQuery)) ||
        (item.job_city &&
          item.job_city.toLowerCase().includes(lowercaseQuery)) ||
        (item.job_country &&
          item.job_country.toLowerCase().includes(lowercaseQuery))
    )
  }

  const search2 = (array, queryJob) => {
    const lowercaseQuery = queryJob.toLowerCase()
    return array.filter(
      item =>
        (item.job_title &&
          item.job_title.toLowerCase().includes(lowercaseQuery)) ||
        (item.company_name &&
          item.company_name.toLowerCase().includes(lowercaseQuery))
    )
  }

  const search3 = (array, queryCompany) => {
    const lowercaseQuery = queryCompany.toLowerCase()
    return array.filter(
      item =>
        (item.job_city &&
          item.job_city.toLowerCase().includes(lowercaseQuery)) ||
        (item.job_country &&
          item.job_country.toLowerCase().includes(lowercaseQuery))
    )
  }

  if (isLoading) {
    return (
      <CandidateLayout page="dashboard">
        <Helmet>
          <title>Dashboard | Edge</title>
        </Helmet>

        <CandidateHeader page="candidate-dashboard" setQuery={setQuery} />
        <Loading />
      </CandidateLayout>
    )
  }

  return (
    <CandidateLayout page="dashboard">
      <Helmet>
        <title>Dashboard | Edge</title>
      </Helmet>

      <div className="min-h-screen bg-no-repeat bg-cover bg-fixed bg-center bg-[url('/public/techteam2.jpg')]">
        <CandidateHeader page="candidate-dashboard" setQuery={setQuery} />

        <div className="xl:px-16 sm:px-8 px-4 py-10 w-full ">
          <div className="text-center">
            <h1 className="text-white text-4xl uppercase font-bold">
              find your dream job.
            </h1>
            <h1 className="text-white text-2xl pt-3 uppercase font-bold">
              The home for tech jobs.
            </h1>
          </div>
          <form action="" className="mt-6 pt-10 w-full">
            <div className="w-full flex flex-wrap justify-between">
              <div className="sm:w-1/2 w-full sm:pr-2 mt-2">
                <label htmlFor="" className="text-white text-sm font-bold mb-1">
                  What
                </label>
                <input
                  type="text"
                  placeholder="Job title or Company"
                  onChange={event => setQueryJob(event.target.value)}
                  className="text-gray-700 font-semi-bold text-sm py-2 w-full sm:w-6/8 border placeholder:text-sm placeholder: pl-3 drop-shadow outline-offset-1 outline-cyan-500"
                />
              </div>

              <div className="sm:w-1/2 w-full sm:pl-2 mt-2">
                <label htmlFor="" className="text-white text-sm font-bold mb-1">
                  Where
                </label>
                <input
                  type="text"
                  placeholder="City or Country"
                  onChange={event => setQueryCompany(event.target.value)}
                  className="text-gray-700 font-semi-bold text-sm py-2 w-full sm:w-6/8 border placeholder:text-sm placeholder: pl-3 drop-shadow outline-offset-1 outline-cyan-500"
                />
              </div>
            </div>
          </form>

          <div className="mt-8">
            <div className="bg-slate-500 bg-opacity-50 py-4 rounded-md shadow">
              <span className="text-white font-bold mx-4">Latest Jobs</span>
              <div className="grid 4xl:grid-cols-5 3xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 px-3 w-full">
                {currentJobs(
                  search3(
                    search2(search(unexpiredJobCards, query), queryJob),
                    queryCompany
                  )
                ).map(job => (
                  <div key={job.id} className="px-3 py-4 w-full">
                    <JobCard data={job} page="job-description" key={job.id} />
                  </div>
                ))}
              </div>

              {unexpiredJobCards.length > indexOfLastJob ? (
                <div className="flex flex-col items-center justify-center mt-2.5">
                  <button
                    className="bg-blueBtn text-white text-sm py-1.5 px-2.5 rounded text-center"
                    onClick={() => setIndexOfLastJob(indexOfLastJob + 6)}
                  >
                    Show More
                  </button>
                </div>
              ) : unexpiredJobCards.length > 6 ? (
                unexpiredJobCards.length < indexOfLastJob ? (
                  <div className="flex flex-col items-center justify-center mt-2.5">
                    <button
                      className="bg-blueBtn text-white text-sm py-1.5 px-2.5 rounded text-center"
                      onClick={() => setIndexOfLastJob(indexOfLastJob - 6)}
                    >
                      Show Less
                    </button>
                  </div>
                ) : null
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </CandidateLayout>
  )
}

export default CandidateDashboard
