import { FaCloudUploadAlt } from "react-icons/fa"
import { Box, TextField, MenuItem, Button } from "@mui/material"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { MuiChipsInput } from "mui-chips-input"
import { titleCase } from "../../utils/helpers"
import { currency } from "../../utils/data"
import countryList from "react-select-country-list"
import { useMemo } from "react"

const ProfileForm = ({ formik }) => {
  const options = useMemo(() => countryList().getData(), [])

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        width: "100%",
        mt: "1rem",
        mx: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // "& .MuiTextField-root": { mt: "1rem" },
        "& .MuiSvgIcon-root": {
          color: "#000000",
        },
      }}
      onSubmit={formik.handleSubmit}
    >
      {/**avatar */}
      <Box sx={{ width: "100%" }}>
        <input
          type="file"
          id="avatar"
          accept="image/*"
          onChange={e => {
            formik.setFieldValue("avatar", e.currentTarget.files[0])
          }}
          hidden
        />

        <label
          htmlFor="avatar"
          className={`${
            Boolean(formik.errors.avatar)
              ? "border-[#d32f2f]"
              : "border-[#cccccc]"
          } bg-white border rounded-[4px]  mt-4 text-black text-base w-full cursor-pointer flex items-center justify-between`}
        >
          <span className="py-[8.5px] px-[14px]">
            {formik.values.avatar?.name
              ? formik.values.avatar?.name
              : formik.values.avatar
              ? formik.values.avatar
              : "Choose a file..."}
          </span>
          <span className="flex items-center justify-end bg-gray-100 border-l border-[#cccccc] py-[8.5px] px-[14px]">
            <FaCloudUploadAlt style={{ marginRight: "8px" }} /> Choose Profile
            Picture
          </span>
        </label>

        {Boolean(formik.errors.avatar) && (
          <p className="text-[#d32f2f] text-xs my-1 ml-[14px] tracking-[0.0333em]">
            {formik.touched.avatar && formik.errors.avatar}
          </p>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "1rem",
          width: "100%",
        }}
      >
        {/**first name */}
        <Box sx={{ width: "49%" }}>
          <TextField
            required
            variant="outlined"
            fullWidth
            size="small"
            id="first_name"
            label="First Name"
            value={titleCase(formik.values.first_name)}
            onChange={formik.handleChange("first_name")}
            error={
              formik.touched.first_name && Boolean(formik.errors.first_name)
            }
            helperText={formik.touched.first_name && formik.errors.first_name}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        {/**last name */}
        <Box sx={{ width: "49%" }}>
          <TextField
            required
            variant="outlined"
            fullWidth
            size="small"
            id="last_name"
            label="Last Name"
            value={titleCase(formik.values.last_name)}
            onChange={formik.handleChange("last_name")}
            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
            helperText={formik.touched.last_name && formik.errors.last_name}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "1rem",
          width: "100%",
        }}
      >
        {/**email */}
        <Box sx={{ width: "49%" }}>
          <TextField
            required
            disabled
            variant="outlined"
            fullWidth
            size="small"
            id="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange("email")}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        {/**phone number */}
        <Box sx={{ width: "49%" }}>
          <PhoneInput
            inputProps={{
              id: "phone",
              required: true,
            }}
            inputStyle={{ width: "100%" }}
            country={"ng"}
            value={formik.values.phone_number}
            placeholder="+234 812 345 6789"
            onChange={phone => formik.setFieldValue("phone_number", phone)}
            autoComplete="off"
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "1rem",
          width: "100%",
        }}
      >
        {/**country */}
        <Box sx={{ width: "49%" }}>
          <TextField
            required
            variant="outlined"
            fullWidth
            size="small"
            id="country"
            label="Country"
            value={formik.values.country}
            onChange={formik.handleChange("country")}
            error={formik.touched.country && Boolean(formik.errors.country)}
            InputLabelProps={{ shrink: true }}
            select
            SelectProps={{ MenuProps: { disableScrollLock: true } }}
          >
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        {/**job title */}
        <Box sx={{ width: "49%" }}>
          <TextField
            required
            variant="outlined"
            fullWidth
            size="small"
            id="job_title"
            label="Job Title"
            value={formik.values.job_title}
            onChange={formik.handleChange("job_title")}
            error={formik.touched.job_title && Boolean(formik.errors.job_title)}
            helperText={formik.touched.job_title && formik.errors.job_title}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "1rem",
          width: "100%",
        }}
      >
        {/**linkedin */}
        <Box sx={{ width: "49%" }}>
          <TextField
            required
            variant="outlined"
            fullWidth
            size="small"
            id="linkedin"
            label="Linkedin"
            value={formik.values.linkedin}
            onChange={formik.handleChange("linkedin")}
            error={formik.touched.linkedin && Boolean(formik.errors.linkedin)}
            helperText={formik.touched.linkedin && formik.errors.linkedin}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        {/**github */}
        <Box sx={{ width: "49%" }}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            id="github"
            label="Github (optional)"
            value={formik.values.github}
            onChange={formik.handleChange("github")}
            error={formik.touched.github && Boolean(formik.errors.github)}
            helperText={formik.touched.github && formik.errors.github}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          "@media (max-width: 600px)" : {
            flexDirection:"column",
          }
        }}
      >
        {/**resume*/}
        <Box sx={{ width: "49%", "@media (max-width: 600px)" : {
            width:"100%",
          } }}>
          <input
            type="file"
            id="resume"
            accept=".doc, .docx, .pdf"
            onChange={e => {
              formik.setFieldValue("resume", e.currentTarget.files[0])
            }}
            hidden
          />

          <label
            htmlFor="resume"
            className={`${
              Boolean(formik.errors.resume)
                ? "border-[#d32f2f]"
                : "border-[#cccccc]"
            } bg-white border rounded-[4px]  mt-4 text-black text-base w-full cursor-pointer flex items-center justify-between`}
          >
            <span className="py-[8.5px] px-[14px] text-sm">
              {formik.values.resume?.name
                ? formik.values.resume?.name
                : formik.values.resume
                ? formik.values.resume
                : "Choose a file..."}
            </span>
            <span className="flex items-center justify-end bg-gray-100 border-l border-[#cccccc] py-[8.5px] px-[14px]">
              <FaCloudUploadAlt style={{ marginRight: "8px" }} />
              <p className="text-sm">Upload Resume</p>
            </span>
          </label>

          {Boolean(formik.errors.resume) && (
            <p className="text-[#d32f2f] text-xs my-1 ml-[14px] tracking-[0.0333em]">
              {formik.touched.resume && formik.errors.resume}
            </p>
          )}
        </Box>

        {/**cover letter */}
        <Box sx={{ width: "49%", "@media (max-width: 600px)" : {
            width:"100%",
          } }}>
          <input
            type="file"
            id="cover_letter"
            accept=".doc, .docx, .pdf"
            onChange={e =>
              formik.setFieldValue("cover_letter", e.currentTarget.files[0])
            }
            hidden
          />

          <label
            htmlFor="cover_letter"
            className={`${
              Boolean(formik.errors.cover_letter)
                ? "border-[#d32f2f]"
                : "border-[#cccccc]"
            } bg-white border rounded-[4px]  mt-4 text-black text-base w-full cursor-pointer flex items-center justify-between`}
          >
            <span className="py-[8.5px] px-[14px] text-sm">
              {formik.values.cover_letter?.name
                ? formik.values.cover_letter?.name
                : formik.values.cover_letter
                ? formik.values.cover_letter
                : "Choose a file..."}
            </span>
            <span className="flex items-center justify-end bg-gray-100 border-l border-[#cccccc] py-[8.5px] px-[14px]">
              <FaCloudUploadAlt style={{ marginRight: "8px" }} />
              <p className="text-xs">Upload Cover Letter</p>
            </span>
          </label>

          {Boolean(formik.errors.cover_letter) && (
            <p className="text-[#d32f2f] text-xs my-1 ml-[14px] tracking-[0.0333em]">
              {formik.touched.cover_letter && formik.errors.cover_letter}
            </p>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "1rem",
          width: "100%",
        }}
      >
        {/**experience */}
        <Box sx={{ width: "49%" }}>
          <TextField
            required
            variant="outlined"
            fullWidth
            size="small"
            id="experience"
            label="Total Work Experience (years)"
            value={formik.values.experience}
            onChange={formik.handleChange("experience")}
            error={
              formik.touched.experience && Boolean(formik.errors.experience)
            }
            helperText={formik.touched.experience && formik.errors.experience}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        <Box
          sx={{
            width: "49%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/**currency */}
          <Box sx={{ width: "39%" }}>
            <TextField
              required
              variant="outlined"
              fullWidth
              size="small"
              id="currency"
              label="Currency"
              value={formik.values.currency}
              onChange={formik.handleChange("currency")}
              select
              SelectProps={{ MenuProps: { disableScrollLock: true } }}
              error={formik.touched.currency && Boolean(formik.errors.currency)}
              helperText={formik.touched.currency && formik.errors.currency}
              InputLabelProps={{ shrink: true }}
              placeholder={formik.values.currency}
            >
              {currency.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box sx={{ width: "59%" }}>
            <TextField
              required
              variant="outlined"
              fullWidth
              size="small"
              id="salary"
              label="Salary Expectation"
              value={formik.values.salary}
              onChange={formik.handleChange("salary")}
              error={formik.touched.salary && Boolean(formik.errors.salary)}
              helperText={formik.touched.salary && formik.errors.salary}
              InputLabelProps={{ shrink: true }}
            />
          </Box>
        </Box>
      </Box>

      <Box sx={{ mt: "1rem", width: "100%" }}>
        {/**skills */}
        <MuiChipsInput
          required
          variant="outlined"
          fullWidth
          size="small"
          id="skills"
          label="Skills"
          disableEdition
          hideClearAll
          value={formik.values.skills}
          onAddChip={value =>
            formik.setFieldValue("skills", [...formik.values.skills, value])
          }
          onDeleteChip={(chip, index) => {
            let skillsArr = formik.values.skills
            skillsArr.splice(index, 1)

            formik.setFieldValue("skills", skillsArr)
          }}
          error={formik.touched.skills && Boolean(formik.errors.skills)}
          helperText={
            Boolean(formik.errors.skills)
              ? formik.touched.skills && formik.errors.skills
              : "Enter your skills"
          }
          InputLabelProps={{ shrink: true }}
        />
      </Box>

      <Box sx={{ mt: "1rem", display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          type="submit"
          disabled={!formik.dirty}
          sx={{
            p: "0.75rem 3rem",
            backgroundColor: "#3F51B5",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
        >
          UPDATE DETAILS
        </Button>
      </Box>
    </Box>
  )
}

export default ProfileForm
