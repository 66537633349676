import React, { useState, useContext, useEffect } from "react"
//import { useNavigate } from "react-router-dom"
import axios from "../api/axios"
import {
  BsCaretDownFill,
  // BsFillBellFill,
  // BsFillChatSquareDotsFill,
} from "react-icons/bs"
import { FaUser } from "react-icons/fa"
//import { IoIosSearch } from "react-icons/io"
import { FiMenu, FiX } from "react-icons/fi"
import AdminMenu from "./AdminMenu"
import { SidebarContext } from "../contexts/SidebarContext"
import useAuth from "../hooks/useAuth"

const AddApplicantHeader = ({ page, setQuery }) => {
  const [profile, setProfile] = useState({})
  const { auth } = useAuth()
  //const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)

  const { showSidebar, setShowSidebar } = useContext(SidebarContext)
  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const request = {
      signal: controller.signal,
    }

    const getCompanyProfile = async () => {
      const res = await axios
        .get(`companies/${auth?.companyId}`, request)
        .catch(async err => {
          console.error(err?.response)
          // navigate("/", { state: { from: location }, replace: true })
        })
      if (res?.data?.data) {
        isMounted && setProfile(res.data?.data?.company)
      }
    }

    getCompanyProfile()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [auth])

  return (
    <nav className="sticky top-0 w-full flex items-center justify-end h-16 px-8 bg-white shadow z-40">
      <div className="flex items-start text-gray-500 sm:space-x-4 space-x-2">
        <span
          className="flex flex-col items-center hover:text-gray-800 cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          {profile?.company_logo ? (
            <div className="flex relative w-6">
              <img
                crossOrigin="anonymous"
                src={`${process.env.REACT_APP_BASE_URL}storage/images/${profile?.company_logo}`}
                className="w-full h-full object-center rounded-full"
                alt=""
              />
            </div>
          ) : (
            <FaUser size={24} />
          )}
          <span className="sm:flex hidden items-start sm:space-x-1 space-x-0 mt-0.5">
            <p className="text-xs sm:block hidden">Me</p>
            <BsCaretDownFill size={12} className="mt-0.5" />
          </span>
          {isOpen && <AdminMenu />}
        </span>
        <span
          className="sm:pl-2 pl-0 lg:hidden flex"
          onClick={() => setShowSidebar(!showSidebar)}
        >
          {showSidebar ? <FiX size={28} /> : <FiMenu size={28} />}
        </span>
      </div>
    </nav>
  )
}

export default AddApplicantHeader
