const Preview = ({ questions, currentStep, setCurrentStep, onSubmit }) => {
  return (
    <div className="px-8 py-4 mt-8 text-black">
      <div>
        <span className="font-bold ">Questions</span>

        {questions.map((question, index) => (
          <div key={index} className="mb-2">
            <p>
              {index + 1}. {question.question}
            </p>

            <div className="flex justify-between">
              <p>A. {question.option_A}</p>
              <p>B. {question.option_B}</p>
              <p>C. {question.option_C}</p>
              <p>D. {question.option_D}</p>
            </div>

            <p>Correct Option: {question.correct_option}</p>
            <p>Time allowed per question: {question.time}</p>
          </div>
        ))}
      </div>

      <div className="flex flex-row justify-between py-3 mt-6">
        <button
          className="bg-red-400 py-2 px-3 rounded-2xl shadow-lg text-sm text-slate-900 hover:bg-black hover:text-white hover:opacity-90"
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          BACK
        </button>
        <button
          className="bg-green-400 py-2 px-3 rounded-2xl shadow-lg text-sm text-slate-900 hover:bg-black hover:text-white hover:opacity-90"
          onClick={() => onSubmit()}
        >
          SUBMIT
        </button>
      </div>
    </div>
  )
}

export default Preview
