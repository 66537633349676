import { useEffect, useState } from "react"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import CandidateLayout from "../components/CandidateLayout"
import CandidateHeader from "../components/CandidateHeader"
import axios from "../api/axios"
import useAuth from "../hooks/useAuth"
import AppliedJobCard from "../components/AppliedJobCard"
import { Link } from "react-router-dom"
import useProfileChecker from "../hooks/useProfileChecker"
import Loading from "../components/Loading"
import { Helmet } from "react-helmet-async"

const MyApplications = () => {
  const [data, setData] = useState([])
  const [query, setQuery] = useState("")
  const [disabled, setDisabled] = useState()
  const [isLoading, setIsLoading] = useState(true)
  let params = useParams()
  const { auth } = useAuth()
  // console.log(auth);

  const profileChecker = useProfileChecker()
  profileChecker()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const getData = async () => {
      const res = await axios
        .get(`applications/jobs?userId=${auth.id}`)
        .catch(error => {
          console.error(error?.res)
          // navigate("/admin-login", { state: { from: location }, replace: true })
        })
      if (res?.data) {
        // console.log(res.data.data)
        isMounted && setData(res?.data?.data)
        setIsLoading(false)
      }
    }

    getData()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [auth])

  const search = (array, query) => {
    const lowercaseQuery = query.toLowerCase()
    return array?.filter(
      item =>
        (item?.job_title &&
          item?.job_title.toLowerCase().includes(lowercaseQuery)) ||
        (item?.company_name &&
          item?.company_name.toLowerCase().includes(lowercaseQuery)) ||
        (item?.job_city &&
          item?.job_city.toLowerCase().includes(lowercaseQuery)) ||
        (item?.job_country &&
          item?.job_country.toLowerCase().includes(lowercaseQuery))
    )
  }

  if (isLoading) {
    return (
      <CandidateLayout page="applications">
        <Helmet>
          <title>My Applications | Edge</title>
        </Helmet>

        <Loading />
      </CandidateLayout>
    )
  }

  return (
    <CandidateLayout page="applications">
      <Helmet>
        <title>My Applications | Edge</title>
      </Helmet>

      <div className="min-h-screen drop-shadow bg-no-repeat bg-cover bg-fixed bg-center bg-[url('/public/techteam2.jpg')]">
        <CandidateHeader page="candidate-dashboard" setQuery={setQuery} />

        <div className="bg-slate-500 bg-opacity-50 py-4 rounded-md shadow">
          <span className="mt-3 px-6 text-white font-bold text-lg uppercase">
            Applied Jobs
          </span>

          <div className="grid 4xl:grid-cols-5 3xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 w-full px-3">
            {search(data, query).map(job => (
              <div className="px-3 py-4 w-full" key={job.id}>
                <AppliedJobCard data={job} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </CandidateLayout>
  )
}

export default MyApplications
