import { Box } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom"
import axios from "../api/axios"
import { PasswordResetContext } from "../contexts/PasswordResetContext"
import useAuth from "../hooks/useAuth"
import usePasswordToggle from "../hooks/usePasswordToggle"
import "../styles/loginBG.css"
import { useFormik } from "formik"
import * as yup from "yup"
import { Helmet } from "react-helmet-async"

const validationSchema = yup.object({
  email: yup.string().email().required("This field is required"),
  password: yup.string().required("This field is required"),
})

const UserLogin = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.prevURL
    ? location.state?.prevURL
    : "/candidate-dashboard"
  const [message, setMessage] = useState("")
  const labelStyles = "block text-gray-600 font-semibold text-sm"

  const { setAuth, persist, setPersist } = useAuth()
  const { setRole } = useContext(PasswordResetContext)

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      const res = await axios
        .post("users/login", {
          email: values.email,
          password: values.password,
        })
        .catch(err => {
          console.error(err.response)
          setMessage(err.response.data.message)
        })
      resetForm()
      if (res) {
        localStorage.setItem("refresh", res?.data?.data?.refreshToken)
        setAuth({
          id: res?.data?.data?.id,
          email: res?.data?.data?.email,
          accessToken: res?.data?.data?.accessToken,
          role: res?.data?.data?.role,
        })

        navigate(from)
      }
    },
  })

  const togglePersist = () => {
    setPersist(prev => !prev)
  }

  useEffect(() => {
    localStorage.setItem("persist", persist)
  }, [persist])

  const [PasswordInputType, ToggleIcon] = usePasswordToggle()

  return (
    <div className="h-screen bg-slate-200 flex justify-center items-center w-screen relative">
      <Helmet>
        <title>Edge | Log In or Sign Up</title>
      </Helmet>

      <div className="background">
        <div className="absolute top-4 left-4">
          <div className="h-24 w-24">
            <Link to="/">
              <img
                src={require("../assets/logopic.png")}
                alt="ARC Digital Logo"
              />
            </Link>
          </div>
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="flex justify-center items-center flex-col w-screen h-screen"
        >
          <div className="bg-slate-50 px-10 py-4 rounded-xl shadow-md max-w-sm">
            <div className="space-y-3">
              <h1 className="text-center text-2xl font-semibold text-gray-600">
                Login
              </h1>
              <div>
                {message ? (
                  <p className="text-danger text-red-600 text-base">
                    {message}
                  </p>
                ) : null}
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block mb-1 text-gray-600 font-semibold text-xl"
                >
                  Email
                </label>
                <input
                  type="email"
                  className="text-black bg-indigo-50 px-4 py-2 outline-none rounded-md w-full text-xl"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange("email")}
                  onBlur={formik.handleBlur}
                  placeholder="Email"
                  id="email"
                  autoComplete="off"
                />
                <div className="text-danger text-red-600 text-sm">
                  {formik.touched.email && formik.errors.email}
                </div>
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-1 text-gray-600 font-semibold text-xl"
                >
                  Password
                </label>
                <span>
                  <input
                    type={PasswordInputType}
                    className="text-black bg-indigo-50 px-4 py-2 outline-none rounded-md w-full text-xl"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange("password")}
                    onBlur={formik.handleBlur}
                    placeholder="Password"
                    id="password"
                    autoComplete="off"
                  />
                  <Box
                    sx={{
                      position: "relative",
                      left: "12rem",
                      bottom: "2rem",
                    }}
                    className="password-toggle-icon"
                  >
                    {ToggleIcon}
                  </Box>
                </span>
                <div className="text-danger text-red-600 text-sm">
                  {formik.touched.password && formik.errors.password}
                </div>
              </div>
            </div>

            <button
              className="mt-4 w-full bg-blue-900 font-semibold py-2 rounded-md text-xl tracking-wide"
              onClick={formik.handleSubmit}
            >
              Login
            </button>

            <div className="flex justify-start items-end mt-4">
              <input
                className="h-5 w-5 mr-1.5 mb-0.5 ml-0.5"
                type="checkbox"
                id="persist"
                onChange={() => togglePersist()}
                checked={persist}
              />
              <label htmlFor="persist" className={labelStyles}>
                Remember this device
              </label>
            </div>
          </div>
          <div
            className="text-black text-center text-sm font-semibold mt-2 cursor-pointer"
            onClick={() => navigate("/registration")}
          >
            Don't have account? Create account
          </div>
          <div
            className="text-black text-center text-sm font-semibold mt-2 cursor-pointer"
            onClick={() => {
              setRole("user")
              navigate("/forgot-password")
            }}
          >
            Forgot Password?
          </div>
        </form>
      </div>
    </div>
  )
}
export default UserLogin
