import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import "../styles/Adminlog.css"
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material"
import * as yup from "yup"
import Swal from "sweetalert2"
import axios from "../api/axios"
import { useFormik } from "formik"
import { Helmet } from "react-helmet-async"

const validationSchema = yup.object({
  product_experience: yup
    .string("Select your product experience")
    .required("An option is required"),
  product_recommendation: yup
    .string("Select your recommendation options")
    .required("An option is required"),
  product_changes: yup
    .string("Enter your product changes")
    .required("This field is required")
    .min(3, "Product changes must be at least 3 characters"),
  application_process_improvement: yup
    .string("Enter your application process improvement")
    .required("This field is required")
    .min(3, "Application process improvement must be at least 3 characters"),
  new_product_features: yup
    .string("Enter your first name")
    .required("This field is required")
    .min(3, "New product features must be at least 3 characters"),
})

export const UserFeedback = () => {
  let [message, setMessage] = useState("")
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      product_experience: "",
      product_recommendation: "",
      product_changes: "",
      application_process_improvement: "",
      new_product_features: "",
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      const res = await axios
        .post("feedback/", {
          product_experience: values.product_experience,
          product_recommendation: values.product_recommendation,
          product_changes: values.product_changes,
          application_process_improvement:
            values.application_process_improvement,
          new_product_features: values.new_product_features,
        })
        .catch(err => {
          setMessage(err.response.data.message)
        })
      if (res) {
        resetForm()
        Swal.fire({
          toast: true,
          icon: "success",
          title: "Feedback Sent Successfully",
          position: "top",
          timer: 3000,
          showConfirmButton: false,
        })
        navigate(-1)
      }
    },
  })

  return (
    <div>
      <Helmet>
        <title>Survey</title>
      </Helmet>

      <div className="background">
        <div className="absolute top-4 left-4">
          <div className="h-24 w-24">
            <Link to="/candidate-dashboard">
              <img src={require("../assets/logopic.png")} alt="" />
            </Link>
          </div>
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col pt-20 items-center"
        >
          <div className="bg-slate-50 px-10 py-8 rounded-xl w-screen shadow-md md:max-w-xl lg:max-w-2xl mb-2">
            <div className="space-y-3">
              <h1 className="text-left text-2xl font-semibold text-black">
                Customer Feedback
              </h1>
              <p className="text-lg text-black ">
                Kindly take few minutes to complete Our Product Survey.
              </p>
              <div>
                {message ? (
                  <p className="text-danger text-red-600 text-base">
                    {message}
                  </p>
                ) : null}
              </div>
            </div>
          </div>

          {/* QUESTION 1 */}
          <div className="bg-slate-50 px-10 py-4 rounded-xl w-screen shadow-md md:max-w-xl lg:max-w-2xl mb-2">
            <FormControl>
              <FormLabel
                // id="productExperience"
                sx={{
                  fontSize: "18px",
                  fontFamily: "Roboto condensed",
                  color: "black",
                  fontWeight: "600",
                  mb: "1rem",
                }}
              >
                How will you rate your experience with the product?
              </FormLabel>
              <RadioGroup
                id="product_experience"
                value={formik.values.product_experience}
                onChange={formik.handleChange("product_experience")}
                onBlur={formik.handleBlur}
                name="radio-group1"
              >
                <FormControlLabel
                  value="very dissastisfied"
                  control={<Radio />}
                  label="Very dissatisfied"
                  sx={{
                    color: "black",
                    fontFamily: "Roboto condensed",
                  }}
                />
                <FormControlLabel
                  value="somewhat dissatisfied"
                  control={<Radio />}
                  label="Somewhat dissatisfied"
                  sx={{
                    color: "black",
                    fontFamily: "Roboto condensed",
                  }}
                />
                <FormControlLabel
                  value="slightly dissatisfied"
                  control={<Radio />}
                  label="Slightly dissatisfied"
                  sx={{
                    color: "black",
                  }}
                />
                <FormControlLabel
                  value="neither satisfied nor dissatisfied"
                  control={<Radio />}
                  label="Neither satisfied nor dissatisfied"
                  sx={{
                    color: "black",
                  }}
                />
                <FormControlLabel
                  value="slightly satisfied"
                  control={<Radio />}
                  label="Slightly satisfied"
                  sx={{
                    color: "black",
                  }}
                />
                <FormControlLabel
                  value="somewhat satisfied"
                  control={<Radio />}
                  label="Somewhat satisfied"
                  sx={{
                    color: "black",
                  }}
                />
                <FormControlLabel
                  value="very satisfied"
                  control={<Radio />}
                  label="Very satisfied"
                  sx={{
                    color: "black",
                  }}
                />
              </RadioGroup>
            </FormControl>
            <div className="text-danger text-red-600 text-base">
              {formik.touched.product_experience &&
                formik.errors.product_experience}
            </div>
          </div>

          {/* QUESTION 2 */}
          <div className="bg-slate-50 px-10 py-4 rounded-xl w-screen shadow-md md:max-w-xl lg:max-w-2xl mb-2">
            <FormControl>
              <FormLabel
                id="product_recommendation"
                sx={{
                  fontSize: "18px",
                  fontFamily: "Roboto condensed",
                  color: "black",
                  fontWeight: "600",
                  mb: "1rem",
                }}
              >
                How likely are you to recommend the company to a friend or
                colleague?
              </FormLabel>

              <RadioGroup
                row
                id="product_recommendation"
                value={formik.values.product_recommendation}
                onChange={formik.handleChange("product_recommendation")}
                onBlur={formik.handleBlur}
                name="radio-group1"
              >
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="1"
                  labelPlacement="top"
                  sx={{
                    color: "black",
                    fontFamily: "Roboto condensed",
                    width: {
                      xs: "0.3rem",
                      sm: "1rem",
                    },
                  }}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="2"
                  labelPlacement="top"
                  sx={{
                    color: "black",
                    fontFamily: "Roboto condensed",
                    width: {
                      xs: "0.1rem",
                      sm: "1rem",
                    },
                  }}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="3"
                  labelPlacement="top"
                  sx={{
                    color: "black",
                    fontFamily: "Roboto condensed",
                    width: {
                      xs: "0.1rem",
                      sm: "1rem",
                    },
                  }}
                />
                <FormControlLabel
                  value="4"
                  control={<Radio />}
                  label="4"
                  labelPlacement="top"
                  sx={{
                    color: "black",
                    fontFamily: "Roboto condensed",
                    width: {
                      xs: "0.1rem",
                      sm: "1rem",
                    },
                  }}
                />
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label="5"
                  labelPlacement="top"
                  sx={{
                    color: "black",
                    fontFamily: "Roboto condensed",
                    width: {
                      xs: "0.1rem",
                      sm: "1rem",
                    },
                  }}
                />
                <FormControlLabel
                  value="6"
                  control={<Radio />}
                  label="6"
                  labelPlacement="top"
                  sx={{
                    color: "black",
                    fontFamily: "Roboto condensed",
                    width: {
                      xs: "0.1rem",
                      sm: "1rem",
                    },
                  }}
                />
                <FormControlLabel
                  value="7"
                  control={<Radio />}
                  label="7"
                  labelPlacement="top"
                  sx={{
                    color: "black",
                    fontFamily: "Roboto condensed",
                    width: {
                      xs: "0.1rem",
                      sm: "1rem",
                    },
                  }}
                />
                <FormControlLabel
                  value="8"
                  control={<Radio />}
                  label="8"
                  labelPlacement="top"
                  sx={{
                    color: "black",
                    fontFamily: "Roboto condensed",
                    width: {
                      xs: "0.1rem",
                      sm: "1rem",
                    },
                  }}
                />
                <FormControlLabel
                  value="9"
                  control={<Radio />}
                  label="9"
                  labelPlacement="top"
                  sx={{
                    color: "black",
                    fontFamily: "Roboto condensed",
                    width: {
                      xs: "0.1rem",
                      sm: "1rem",
                    },
                  }}
                />
                <FormControlLabel
                  value="10"
                  control={<Radio />}
                  label="10"
                  labelPlacement="top"
                  sx={{
                    color: "black",
                    fontFamily: "Roboto condensed",
                    width: {
                      xs: "0.1rem",
                      sm: "1rem",
                    },
                  }}
                />
              </RadioGroup>
            </FormControl>
            <div className="text-danger text-red-600 text-base">
              {formik.touched.product_recommendation &&
                formik.errors.product_recommendation}
            </div>
          </div>

          {/* QUESTION 3 */}
          <div className="bg-slate-50 px-10 py-4 rounded-xl w-screen shadow-md md:max-w-xl lg:max-w-2xl mb-2">
            <div className="space-y-3">
              <p className="text-left text-lg font-semibold text-black text-600">
                If you could change just one thing about our product, what would
                it be?
              </p>
              <textarea
                value={formik.values.product_changes}
                onChange={formik.handleChange("product_changes")}
                onBlur={formik.handleBlur}
                type="text"
                className="text-black bg-indigo-50 px-4 py-2 mb-3 outline-none rounded-md w-full text-lg"
                name="question3"
                placeholder="Your answer"
                id="product_changes"
                autoComplete="off"
              />

              <div className="text-danger text-red-600 text-base">
                {formik.touched.product_changes &&
                  formik.errors.product_changes}
              </div>
            </div>
          </div>

          {/* QUESTION 4 */}
          <div className="bg-slate-50 px-10 py-4 rounded-xl w-screen shadow-md md:max-w-xl lg:max-w-2xl mb-2">
            <div className="space-y-3">
              <p className="text-left text-lg font-semibold text-black text-600">
                Was there anything about the profile and job application process
                we could improve?
              </p>
              <textarea
                value={formik.values.application_process_improvement}
                onChange={formik.handleChange(
                  "application_process_improvement"
                )}
                onBlur={formik.handleBlur}
                type="text"
                className="text-black bg-indigo-50 px-4 py-2 mb-3 outline-none rounded-md w-full text-lg"
                name="question4"
                placeholder="Your answer"
                id="application_process_improvement"
                autoComplete="off"
              />
              <div className="text-danger text-red-600 text-base">
                {formik.touched.application_process_improvement &&
                  formik.errors.application_process_improvement}
              </div>
            </div>
          </div>

          {/* QUESTION 5 */}
          <div className="bg-slate-50 px-10 py-4 rounded-xl w-screen shadow-md lg:max-w-2xl md:max-w-xl mb-2">
            <div className="space-y-3">
              <p className="text-left text-lg font-semibold text-black text-600">
                What other feature would you like to see in this product?
              </p>
              <textarea
                value={formik.values.new_product_features}
                onChange={formik.handleChange("new_product_features")}
                onBlur={formik.handleBlur}
                type="text"
                className="text-black bg-indigo-50 px-4 py-2 mb-3 outline-none rounded-md w-full text-lg"
                name="newProductFeatures"
                placeholder="Your answer"
                id="newProductFeatures"
                autoComplete="off"
              />
              <div className="text-danger text-red-600 text-base">
                {formik.touched.new_product_features &&
                  formik.errors.new_product_features}
              </div>
            </div>
          </div>
        </form>

        <div className="flex justify-between xl:ml-96 lg:ml-44 md:ml-24 my-8 w-screen lg:max-w-2xl md:max-w-xl">
          <button
            className="mt-4 w-24 bg-blue-900 font-semibold py-2 rounded-md text-lg "
            type={"submit"}
            onClick={e => {
              formik.handleSubmit(e)
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  )
}

export default UserFeedback
