import { Box, Button, Typography } from "@mui/material"
import { FaUser } from "react-icons/fa"
import { getFileExt, titleCase } from "../../utils/helpers"
import { FiDownload } from "react-icons/fi"

const Preview = ({ user, formik, page, setPage, job }) => {
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        width: "100%",
        mt: "1rem",
        mx: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
      onSubmit={formik.handleSubmit}
    >
      <Typography variant="body2">Review Your Application</Typography>
      <div className="mt-2.5 w-full flex flex-col space-y-2.5 ">
        {/**contact info */}
        <div className="pt-6 pb-3 border-t border-gray-200 relative">
          <button
            className="absolute top-4 right-0 text-sm text-blue-600 font-medium hover:underline"
            onClick={() => setPage(1)}
          >
            Edit
          </button>

          <Typography variant="body2" gutterBottom sx={{ fontWeight: 600 }}>
            Contact Info
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                position: "relative",
                height: "64px",
                width: "64px",
                borderRadius: "50%",
                backgroundColor: "rgb(209 213 219)",
              }}
            >
              {user?.avatar ? (
                <img
                  crossOrigin="anonymous"
                  src={`${process.env.REACT_APP_BASE_URL}storage/images/${user?.avatar}`}
                  className="w-full h-full object-center rounded-full"
                  alt=""
                />
              ) : (
                <FaUser
                  size={24}
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white"
                />
              )}
            </Box>

            <Box sx={{ marginLeft: "1rem" }}>
              <Typography variant="body2">{`${titleCase(
                user?.first_name
              )} ${titleCase(user?.last_name)}`}</Typography>
              <Typography variant="body2">
                {titleCase(user?.job_title)}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ mt: 1 }}>
            <Typography variant="body2" sx={{ fontSize: "0.75rem" }}>
              Email Address
            </Typography>
            <Typography variant="body1">{user?.email}</Typography>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Typography variant="body2" sx={{ fontSize: "0.75rem" }}>
              Phone Number
            </Typography>
            <Typography variant="body1">{user?.phone_number}</Typography>
          </Box>
        </div>

        {/**resume */}
        <div className="pt-6 pb-3 border-t border-gray-200 relative">
          <button
            className="absolute top-4 right-0 text-sm text-blue-600 font-medium hover:underline"
            onClick={() => setPage(2)}
          >
            Edit
          </button>

          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            Resume
          </Typography>
          <Typography variant="body2" gutterBottom>
            Be sure to include an updated resume *
          </Typography>

          <div className="mt-2.5">
            {typeof formik.values.resume === "string" ? (
              <div className="flex rounded-lg relative border border-gray-200 h-16 w-full">
                {getFileExt(formik.values.resume) === "PDF" ? (
                  <div className="bg-red-700 rounded-l-lg text-white flex items-center justify-center text-xs px-2">
                    <Typography variant="body2">
                      {getFileExt(formik.values.resume)}
                    </Typography>
                  </div>
                ) : (
                  <div className="bg-blue-700 rounded-l-lg text-white flex items-center justify-center text-xs px-2">
                    <Typography variant="body2">
                      {getFileExt(formik.values.resume)}
                    </Typography>
                  </div>
                )}

                <Typography
                  variant="body2"
                  sx={{ display: "flex", alignItems: "center", px: "0.75rem" }}
                >
                  {formik.values.resume}
                </Typography>

                <button className="absolute right-2 top-0 bottom-0 my-auto flex items-center justify-end px-1.5">
                  <FiDownload size={24} className="mr-2" />
                </button>
              </div>
            ) : typeof formik.values.resume === "object" ? (
              <div className="flex rounded-lg relative border border-gray-200 h-16 w-full">
                {getFileExt(formik.values.resume?.name) === "PDF" ? (
                  <div className="bg-red-700 rounded-l-lg text-white flex items-center justify-center px-2">
                    <Typography variant="body2">
                      {getFileExt(formik.values.resume?.name)}
                    </Typography>
                  </div>
                ) : (
                  <div className="bg-blue-700 rounded-l-lg text-white flex items-center justify-center px-2">
                    <Typography variant="body2">
                      {getFileExt(formik.values.resume?.name)}
                    </Typography>
                  </div>
                )}

                <Typography
                  variant="body2"
                  sx={{ display: "flex", alignItems: "center", px: "0.75rem" }}
                >
                  {formik.values.resume?.name}
                </Typography>
              </div>
            ) : null}
          </div>
        </div>

        {/**q&a */}
        {formik.values.screeningAnswers.length > 0 && (
          <div className="pt-6 pb-3 border-t border-gray-200 relative">
            <button
              className="absolute top-4 right-0 text-sm text-blue-600 font-medium hover:underline"
              onClick={() => setPage(3)}
            >
              Edit
            </button>

            <Typography variant="body2" gutterBottom sx={{ fontWeight: 600 }}>
              Additional Questions
            </Typography>

            <div className="w-full flex flex-col space-y-2.5">
              {job?.screeningQuestions.map((question, index) => (
                <div key={question.id} className="w-full ">
                  {question?.screening_question_group ===
                    "tool or technology" && (
                    <div className="w-full mb-2.5">
                      <Typography variant="body2">
                        {`How many years of experience do you have using ${titleCase(
                          question?.screening_question_value
                        )}?`}
                        <span>
                          {question?.screening_must_have_qualification
                            ? "*"
                            : null}
                        </span>
                      </Typography>
                    </div>
                  )}

                  {question?.screening_question_group === "language" && (
                    <div className="w-full mb-2.5">
                      <Typography variant="body2">
                        {`What is your level of proficiency in ${titleCase(
                          question?.screening_question_value
                        )}?`}
                        <span>
                          {question?.screening_must_have_qualification
                            ? "*"
                            : null}
                        </span>
                      </Typography>
                    </div>
                  )}

                  {question?.screening_question_group === "industry" && (
                    <div className="w-full mb-2.5">
                      <Typography variant="body2">
                        {`How many years of ${titleCase(
                          question?.screening_question_value
                        )} experience do you currently have?`}
                        <span>
                          {question?.screening_must_have_qualification
                            ? "*"
                            : null}
                        </span>
                      </Typography>
                    </div>
                  )}

                  {question?.screening_question_group === "custom" && (
                    <div className="w-full mb-2.5">
                      <Typography variant="body2">
                        {question?.screening_question_value}
                        <span>
                          {question?.screening_must_have_qualification
                            ? "*"
                            : null}
                        </span>
                      </Typography>
                    </div>
                  )}

                  <Typography variant="body2">
                    {titleCase(formik.values.screeningAnswers[index])}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <Box
        sx={{
          mt: "2rem",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#3F51B5",
            py: "0.5rem",
            px: "1.5rem",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
          onClick={() => {
            if (job.screeningQuestions.length < 1) {
              setPage(2)
            } else {
              setPage(3)
            }
          }}
        >
          PREV
        </Button>

        <Button
          variant="contained"
          type="submit"
          sx={{
            backgroundColor: "#3F51B5",
            py: "0.5rem",
            px: "1.5rem",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
        >
          SUBMIT
        </Button>
      </Box>
    </Box>
  )
}

export default Preview
