import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import React, { useState } from "react"

import { useNavigate } from "react-router"

import Swal from "sweetalert2"
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"

function EditAssessmentQuestions({ data, id }) {
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()

  const [questions, setQuestions] = useState(data)
  const [isTouch, setIsTouch] = useState(false)

  const handleQuestionChange = (index, e) => {
    const updatedQuestions = [...questions]
    updatedQuestions[index].question = e.target.value
    setQuestions(updatedQuestions)
    setIsTouch(true)
  }

  const handleOptionAChange = (index, e) => {
    const updatedQuestions = [...questions]
    updatedQuestions[index].option_A = e.target.value
    setQuestions(updatedQuestions)
    setIsTouch(true)
  }

  const handleOptionBChange = (index, e) => {
    const updatedQuestions = [...questions]
    updatedQuestions[index].option_B = e.target.value
    setQuestions(updatedQuestions)
    setIsTouch(true)
  }

  const handleOptionCChange = (index, e) => {
    const updatedQuestions = [...questions]
    updatedQuestions[index].option_C = e.target.value
    setQuestions(updatedQuestions)
    setIsTouch(true)
  }

  const handleOptionDChange = (index, e) => {
    const updatedQuestions = [...questions]
    updatedQuestions[index].option_D = e.target.value
    setQuestions(updatedQuestions)
    setIsTouch(true)
  }

  const handleCorrect_OptionChange = (index, e) => {
    const updatedQuestions = [...questions]
    updatedQuestions[index].correct_option = e.target.value
    setQuestions(updatedQuestions)
    setIsTouch(true)
  }

  const handleTimeChange = (index, e) => {
    const updatedQuestions = [...questions]
    updatedQuestions[index].time = e.target.value
    setQuestions(updatedQuestions)
    setIsTouch(true)
  }

  const handleUpdate = async event => {
    if (isTouch) {
      let updatedDetails = {
        multiple_questions: questions,
      }

      try {
        const res = await axiosPrivate.put(`assessment/${id}`, updatedDetails)
        if (res.status === 201) {
          Swal.fire({
            toast: true,
            icon: "success",
            text: res.data.message,
            position: "top",
            timer: 3000,
            showConfirmButton: false,
          })
          navigate("/admin-dashboard/")
        }
      } catch (error) {
        if (error.response.status === 500) {
          Swal.fire({
            toast: true,
            icon: "error",
            title: "Oops..",
            text: "An error occurred. Please try again",
            position: "top",
            timer: 3000,
            showConfirmButton: false,
          })
        } else {
          Swal.fire({
            toast: true,
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
            position: "top",
            timer: 3000,
            showConfirmButton: false,
          })
        }
      }
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        title: "Oops...",
        text: "No changes to update",
        position: "top",
        timer: 3000,
        showConfirmButton: false,
      })
    }
  }

  const inputStyles =
    "text-black text-sm px-2 py-1.5 border border-gray-300 rounded w-2/3 placeholder:text-gray-400 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-0"

  const labelStyles = "block text-gray-600 font-semibold text-sm py-3"

  return (
    <div className="rounded-lg border border-gray-200 px-6">
      <div className="flex justify-between items-center mt-8 px-2 py-3 bg-slate-200 rounded-t-lg">
        <span className={labelStyles}>EDIT ASSESSMENT QUESTIONS</span>
        {/* <FiX
          size={24}
          // onClick={() => removeQuestion(index)}
          className="cursor-pointer"
        /> */}
      </div>
      {data.map((assessment, index) => (
        <div key={index} className="mb-4">
          <div className="px-2 py-4">
            <p className="font-semibold text-blue-500">
              {index + 1}. {assessment.question}
            </p>
            <textarea
              type="text"
              name="question"
              value={assessment.question}
              placeholder="Try asking a question like, 'Will you be able to bring your own device?'"
              className="text-black text-sm px-2 py-4 border border-gray-300 rounded w-full placeholder:text-gray-400 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-0"
              onChange={e => handleQuestionChange(index, e)}
              key="customQuestion"
            />
          </div>

          <div className="">
            <div className="flex justify-between items-end px-2 py-4 w-full">
              <p className={labelStyles}>Options</p>

              {/**options */}
              <div className="flex justify-between items-end w-3/4">
                {/**option a */}
                <input
                  name="option_A"
                  className={`${inputStyles} w-1/5`}
                  onChange={e => handleOptionAChange(index, e)}
                  value={assessment.option_A}
                  key="option_A"
                />

                {/**option b */}
                <input
                  name="option_B"
                  className={`${inputStyles} w-1/5`}
                  onChange={e => handleOptionBChange(index, e)}
                  value={assessment.option_B}
                  key="option_B"
                />

                {/**option c */}
                <input
                  name="option_C"
                  className={`${inputStyles} w-1/5`}
                  placeholder="Option C"
                  onChange={e => handleOptionCChange(index, e)}
                  value={assessment.option_C}
                  key="option_C"
                />

                {/**option d */}
                <input
                  name="option_D"
                  className={`${inputStyles} w-1/5`}
                  placeholder="Option D"
                  onChange={e => handleOptionDChange(index, e)}
                  value={assessment.option_D}
                  key="option_D"
                />
              </div>
            </div>

            <div className="py-4 px-2 border-t border-gray-200 flex justify-between items-end w-full">
              <label htmlFor="correct_option" className={labelStyles}>
                Correct Option
              </label>
              <div className="">
                <input
                  name="correct_option"
                  className={`${inputStyles} w-1/2`}
                  placeholder="C"
                  onChange={e => handleCorrect_OptionChange(index, e)}
                  value={assessment.correct_option}
                />
              </div>
            </div>

            <div className="py-4 px-2 border-t border-gray-200 flex justify-between items-end w-full">
              <label htmlFor="time" className={labelStyles}>
                Time per question
              </label>
              <div className="">
                <input
                  name="time"
                  className={`${inputStyles} w-2/2`}
                  placeholder="2 minutes per question"
                  onChange={e => handleTimeChange(index, e)}
                  value={assessment.time}
                />
              </div>
            </div>
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#3F51B5",
                mt: "1rem",
                py: "0.75rem",
                px: "1.5rem",
                "&:hover": {
                  backgroundColor: "#3F51B5",
                },
              }}
            >
              PREV
            </Button>

            <Button
              variant="contained"
              sx={{
                backgroundColor: "#3F51B5",
                mt: "1rem",
                py: "0.75rem",
                px: "1.5rem",
                "&:hover": {
                  backgroundColor: "#3F51B5",
                },
              }}
              onClick={e => {
                handleUpdate(e)
              }}
            >
              UPDATE
            </Button>
          </Box>
        </div>
      ))}
    </div>
  )
}

export default EditAssessmentQuestions
