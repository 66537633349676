import { titleCase } from "../../utils/helpers"
import { AiOutlineClockCircle } from "react-icons/ai"
import Swal from "sweetalert2"

const AssessmentCard = ({
  item,
  setPage,
  takenAssessments,
  setShowModal,
  setQuery,
}) => {
  return (
    <div className="bg-white py-5 px-3 w-full rounded-md shadow-md text-black flex flex-col items-center mt-3">
      <p className="mt-4 font-medium">{item?.assessment.role}</p>
      <p className="mb-4 text-md font-medium">{titleCase(item?.company)}</p>
      <span className="flex items-center">
        <AiOutlineClockCircle />
        <p className="ml-2"> {item?.totalTime} minutes</p>
      </span>

      {takenAssessments.includes(item?.assessment.id) === true ? (
        <button
          className="mt-2.5 py-1 w-full rounded-md border border-blueBtn text-blueBtn"
          onClick={() => {
            setShowModal(true)
            setQuery(item?.assessment)
          }}
        >
          View Result
        </button>
      ) : (
        <button
          className="mt-2.5 py-1 w-full rounded-md border border-blueBtn text-blueBtn"
          onClick={() => {
            Swal.fire({
              title: "Are you sure you want to take the test?",
              text: "",
              icon: "warning",
              showCancelButton: "true",
              confirmButtonColor: "#3085d5",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
            }).then(result => {
              if (result.isConfirmed) {
                setPage(2)
                setQuery(item.assessment)
              }
            })
          }}
        >
          Start Quiz
        </button>
      )}
    </div>
  )
}

export default AssessmentCard
