import { FiX } from "react-icons/fi"

const inputStyles =
  "text-black text-sm px-2 py-1.5 border border-gray-300 rounded w-full placeholder:text-gray-400 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-0"

const labelStyles = "block text-gray-600 font-semibold text-sm"

export const Custom = ({ question, index, handleOnChange, removeQuestion }) => {
  return (
    <div className="rounded-lg border border-gray-200">
      <div className="flex justify-between items-center px-2 py-3 bg-slate-200 rounded-t-lg">
        <span className={labelStyles}>Write a Custom Assessment Question.</span>
        <FiX
          size={24}
          onClick={() => removeQuestion(index)}
          className="cursor-pointer"
        />
      </div>
      <div className="px-2 py-4">
        <textarea
          type="text"
          name="question"
          value={question.question}
          placeholder="Try asking a question like, 'Will you be able to bring your own device?'"
          className="text-black text-sm px-2 py-4 border border-gray-300 rounded w-full placeholder:text-gray-400 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-0"
          onChange={e => handleOnChange(e, index)}
          key="customQuestion"
        />
      </div>

      <div className="">
        <div className="flex justify-between items-end px-2 py-4 w-full">
          <p className={labelStyles}>Options</p>

          {/**options */}
          <div className="flex justify-between items-end w-3/4">
            {/**option a */}
            <input
              name="option_A"
              className={`${inputStyles} w-1/5`}
              placeholder="Option A"
              onChange={e => handleOnChange(e, index)}
              value={question.option_A}
              key="option_A"
            />

            {/**option b */}
            <input
              name="option_B"
              className={`${inputStyles} w-1/5`}
              placeholder="Option B"
              onChange={e => handleOnChange(e, index)}
              value={question.option_B}
              key="option_B"
            />

            {/**option c */}
            <input
              name="option_C"
              className={`${inputStyles} w-1/5`}
              placeholder="Option C"
              onChange={e => handleOnChange(e, index)}
              value={question.option_C}
              key="option_C"
            />

            {/**option d */}
            <input
              name="option_D"
              className={`${inputStyles} w-1/5`}
              placeholder="Option D"
              onChange={e => handleOnChange(e, index)}
              value={question.option_D}
              key="option_D"
            />
          </div>
        </div>

        <div className="py-4 px-2 border-t border-gray-200 flex justify-between items-end w-full">
          <label htmlFor="correct_option" className={labelStyles}>
            Correct Option
          </label>
          <div className="">
            <input
              name="correct_option"
              className={`${inputStyles} w-1/2`}
              placeholder="C"
              onChange={e => handleOnChange(e, index)}
              value={question.correct_option}
            />
          </div>
        </div>

        <div className="py-4 px-2 border-t border-gray-200 flex justify-between items-end w-full">
          <label htmlFor="time" className={labelStyles}>
            Time per question
          </label>
          <div className="">
            <input
              name="time"
              className={`${inputStyles} w-2/2`}
              placeholder="2 minutes per question"
              onChange={e => handleOnChange(e, index)}
              value={question.time}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
