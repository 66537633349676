import { Box, Button, Typography } from "@mui/material"
import { getFileExt } from "../../utils/helpers"
import { FiDownload, FiX } from "react-icons/fi"

const Resume = ({ user, formik, page, setPage }) => {
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        width: "100%",
        mt: "1rem",
        mx: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
      onSubmit={formik.handleSubmit}
    >
      <Typography variant="body2" sx={{ fontWeight: 600 }}>
        Resume
      </Typography>
      <Typography variant="body2" gutterBottom>
        Be sure to include an updated resume *
      </Typography>

      {typeof formik.values.resume === "string" ? (
        <div className="flex rounded-lg relative border border-gray-200 h-16 w-full">
          {getFileExt(formik.values.resume) === "PDF" ? (
            <div className="bg-red-700 rounded-l-lg text-white flex items-center justify-center text-xs px-2">
              <Typography variant="body2">
                {getFileExt(formik.values.resume)}
              </Typography>
            </div>
          ) : (
            <div className="bg-blue-700 rounded-l-lg text-white flex items-center justify-center text-xs px-2">
              <Typography variant="body2">
                {getFileExt(formik.values.resume)}
              </Typography>
            </div>
          )}

          <Typography
            variant="body2"
            sx={{ display: "flex", alignItems: "center", px: "0.75rem" }}
          >
            {formik.values.resume}
          </Typography>

          <button className="absolute right-2 top-0 bottom-0 my-auto flex items-center justify-end px-1.5">
            <a
              href={`${process.env.REACT_APP_BASE_URL}storage/files/${formik.values.resume}`}
              target="_blank"
            >
              <FiDownload size={24} className="mr-2" />
            </a>
            <FiX size={24} onClick={() => formik.setFieldValue("resume", 0)} />
          </button>
        </div>
      ) : typeof formik.values.resume === "object" && formik.values.resume ? (
        <div className="flex rounded-lg relative border border-gray-200 h-16 w-full">
          {getFileExt(formik.values.resume?.name) === "PDF" ? (
            <div className="bg-red-700 rounded-l-lg text-white flex items-center justify-center px-2">
              <Typography variant="body2">
                {getFileExt(formik.values.resume?.name)}
              </Typography>
            </div>
          ) : (
            <div className="bg-blue-700 rounded-l-lg text-white flex items-center justify-center px-2">
              <Typography variant="body2">
                {getFileExt(formik.values.resume?.name)}
              </Typography>
            </div>
          )}

          <Typography
            variant="body2"
            sx={{ display: "flex", alignItems: "center", px: "0.75rem" }}
          >
            {formik.values.resume?.name}
          </Typography>

          <button className="absolute right-2 top-0 bottom-0 my-auto flex items-center justify-end px-1.5">
            <FiX size={24} onClick={() => formik.setFieldValue("resume", 0)} />
          </button>
        </div>
      ) : null}

      {/**upload resume */}
      <Box>
        <input
          onChange={e => {
            formik.setFieldValue("resume", e.currentTarget.files[0])
          }}
          type="file"
          id="resume"
          accept=".doc, .docx, .pdf"
          hidden
        />

        <Box
          component="label"
          htmlFor="resume"
          sx={{
            color: "#3F51B5",
            backgroundColor: "#FFFFFF",
            border: "1px solid #3F51B5",
            borderRadius: "9999px",
            py: 0.75,
            px: 1.5,
            fontSize: "1rem",
            cursor: "pointer",
          }}
        >
          Upload Resume
        </Box>
      </Box>

      <Box
        sx={{
          mt: "2rem",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#3F51B5",
            py: "0.5rem",
            px: "1.5rem",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
          onClick={() => {
            setPage(page - 1)
          }}
        >
          PREV
        </Button>

        <Button
          variant="contained"
          type="submit"
          sx={{
            backgroundColor: "#3F51B5",
            py: "0.5rem",
            px: "1.5rem",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
        >
          NEXT
        </Button>
      </Box>
    </Box>
  )
}

export default Resume
