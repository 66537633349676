import { useNavigate } from "react-router-dom"

const Menu = () => {
  const navigate = useNavigate()

  return (
    <div className="origin-top-right absolute top-0 right-0 mt-12 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-50">
      {/* sign in & registration */}
      <ul className="text-gray-500">
        <li
          className="group hover:bg-blueBtn py-2 px-4 cursor-pointer"
          onClick={() => navigate("/user-login")}
        >
          <span className="group-hover:text-white cursor-pointer text-sm font-medium">
            Log In
          </span>
        </li>

        <li
          className="group hover:bg-blueBtn py-2 px-4 cursor-pointer"
          onClick={() => navigate("/registration")}
        >
          <span className="group-hover:text-white cursor-pointer text-sm font-medium">
            Register
          </span>
        </li>

        <li
          className="group hover:bg-blueBtn py-2 px-4 cursor-pointer"
          onClick={() => navigate("/admin-login")}
        >
          <span className="group-hover:text-white text-blueBtn cursor-pointer text-sm font-medium">
            Post A Job
          </span>
        </li>

        {/* <li className="py-2 px-4 flex justify-center">
          <button className="py-2 px-6 bg-white border-2 border-blueBtn rounded-md text-blueBtn font-medium hover:bg-blueBtn hover:text-white hover:shadow-md text-sm">
            Post A Job
          </button>
        </li> */}
      </ul>
    </div>
  )
}

export default Menu
