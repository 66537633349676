import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { IoIosSearch } from "react-icons/io"
import { FaBars, FaTimes } from "react-icons/fa"
import { FiX, FiMenu } from "react-icons/fi"
import logo from "../../assets/logopic_white.png"
import { Box, Divider } from "@mui/material"
import Menu from "./Menu"

const LandingHeader = ({ setQuery }) => {
  const [nav, setNav] = useState(false)
  const [showMenu, setShowMenu] = useState(false)

  const navigate = useNavigate()

  const handleClick = () => {
    setNav(!nav)
  }

  return (
    <div className="sm:mb-[72px] mb-[68px]">
      <nav className="fixed top-0 w-full flex items-center justify-between h-16 lg:px-8 sm:px-6 px-4 bg-white shadow z-40">
        <div className="flex items-center xl:space-x-32 md:space-x-16">
          {/* logo */}
          <div
            className="flex items-center cursor-pointer pt-2.5"
            onClick={() => navigate("/")}
          >
            <img
              src={require("../../assets/logopic.png")}
              alt="Edge"
              className="w-16 h-auto"
            />
            <span className=" text-black font-bold">THE EDGE APP</span>
          </div>

          {/* search */}
          <form className="relative w-64 lg:flex hidden items-center">
            <input
              type="search"
              name="search"
              placeholder="Search by title or company"
              onChange={event => setQuery(event.target.value)}
              className="w-full bg-blue-50 pl-8 pr-4 py-2 rounded-full border-none text-black text-sm outline-none focus-within:ring-2 focus-within:ring-blue4500 placeholder:text-gray-400 sm:block hidden"
            />
            <IoIosSearch className="w-5 h-5 absolute left-2.5 text-black cursor-pointer sm:block hidden" />
          </form>
        </div>

        <div className="lg:flex hidden lg:items-end text-gray-500 xl:space-x-4 lg:space-x-2 space-x-2">
          <Link to="/user-login">
            <div className="">
              <button className="bg-white py-2 px-2 text-gray-500 hover:text-blueBtn">
                Log In
              </button>
            </div>
          </Link>

          <Divider orientation="vertical" sx={{ height: "16px", my: "auto" }} />

          <Link to="/registration">
            <div className="">
              <button className="bg-white py-2 px-2 text-gray-500 hover:text-blueBtn">
                Register
              </button>
            </div>
          </Link>

          <Link to="/admin-login">
            <div>
              <button className="bg-blueBtn py-2 px-6 text-white rounded-md">
                Post A Job
              </button>
            </div>
          </Link>
        </div>

        {/* Hamburger */}
        <div className="lg:hidden block relative">
          {showMenu ? (
            <FiX
              size={24}
              className="cursor-pointer text-slate-500"
              onClick={() => setShowMenu(!showMenu)}
            />
          ) : (
            <FiMenu
              size={24}
              className="cursor-pointer text-slate-500"
              onClick={() => setShowMenu(!showMenu)}
            />
          )}
          {showMenu && <Menu />}
        </div>

        {/* <div onClick={handleClick} className="md:hidden z-10">
          {!nav ? (
            <FaBars className="text-slate-500 bg-cover w-6 h-6" />
          ) : (
            <FaTimes className="text-slate-500 bg-cover w-6 h-6" />
          )}
        </div>
        <div className="origin-top-right absolute top-0 right-0 mt-16 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-50">
          <ul className={!nav ? "hidden" : "flex flex-col py-2 text-gray-500"}>
            <li className="py-2">
              <Link to="/user-login" smooth={true} duration={500}>
                <div className="">
                  <button className="text-bold text-lg text-black px-3 cursor-auto">
                    Log In
                  </button>
                </div>
              </Link>
            </li>
            <li className="py-2">
              <Link to="/registration" smooth={true} duration={500}>
                <button className="text-bold text-lg text-black px-3 cursor-auto">
                  Register
                </button>
              </Link>
            </li>
            <li className="py-2 ml-2">
              <Link to="/admin-login" smooth={true} duration={500}>
                <button className="bg-blueBtn text-bold text-lg text-white px-3 cursor-auto rounded-md">
                  Post A Job
                </button>
              </Link>
            </li>
          </ul>
        </div> */}
      </nav>
    </div>
  )
}

export default LandingHeader
