import React from "react"
import { FaUser } from "react-icons/fa"
import { titleCase } from "../utils/helpers"

const AppCard = ({ applicant, setPage, setItem, setResume, setShowModal }) => {
  return (
    <div className="w-full py-2.5 px-3 text-black rounded-md shadow-md border border-gray-200 cursor-pointer relative">
      <div className="flex flex-col md:flex-row gap-1 items-center md:items-start">
        <div
          className={`${
            applicant?.avatar ? "bg-white" : "bg-gray-300"
          } w-16 h-16 rounded-full mr-0 md:mr-4 relative`}
        >
          {applicant?.avatar ? (
            <img
              crossOrigin="anonymous"
              src={`${process.env.REACT_APP_BASE_URL}storage/images/${applicant?.avatar}`}
              className="w-full h-full object-center object-contain rounded-full "
              alt=""
            />
          ) : (
            <FaUser
              size={24}
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white"
            />
          )}
        </div>

        <div className="flex flex-col">
          <div className="flex flex-col md:flex-row items-center">
            <div className="text-center md:text-left">
              <p className="text-sm md:text-normal">{`${titleCase(
                applicant?.first_name
              )} ${titleCase(applicant?.last_name)}`}</p>
              <p className="text-xs md:text-sm">{applicant?.email}</p>
              <p className="text-xs md:text-sm">{applicant?.phone_number}</p>
            </div>
          </div>

          {/* buttons */}
          <div className="mt-1.5 flex justify-center gap-2">
            <button
              className="bg-blueBtn text-white text-xs mt-2 py-1.5 px-4 rounded-2xl"
              onClick={() => {
                setPage(2)
                setItem(applicant)
              }}
            >
              View More
            </button>
            {applicant?.resume.includes(".pdf") ? (
              <button
                className="bg-blueBtn disabled:bg-gray-200 text-white text-xs mt-2 py-1.5 px-4 rounded-2xl"
                onClick={() => {
                  setResume(applicant?.resume)
                  setShowModal(true)
                }}
              >
                Preview CV
              </button>
            ) : (
              <a
                href={`${process.env.REACT_APP_BASE_URL}storage/files/${applicant?.resume}`}
                target="_blank"
                rel="noreferrer"
                className="bg-blueBtn text-white text-xs mt-2 py-1.5 px-4 rounded-2xl"
              >
                Preview CV
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppCard
