import React, { useState, useRef, useEffect } from "react"
import Swal from "sweetalert2"
import useAuth from "../../hooks/useAuth"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import { useParams } from "react-router"

const ShareLinkModal = ({ item }) => {
  const [showModal, setShowModal] = useState(false)
  const [emails, setEmails] = useState([])
  const [email, setEmail] = useState("")
  const [sentEmails, setSentEmails] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState("")
  const dropdownRef = useRef(null)

  let params = useParams()
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()

  const currentUrlLink = window.location.href

  const validateEmail = email => {
    // Email regex pattern for basic validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailPattern.test(email)
  }

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setShowModal(false);
  //   if (!email || !validateEmail(email)) {
  //     Swal.fire({
  //       toast: true,
  //       icon: "error",
  //       title: "Invalid email",
  //       text: "Please enter a valid email address",
  //       position: "top",
  //       timer: 2000,
  //       showConfirmButton: false,
  //     });
  //     return;
  //   }
  //   const response = await axiosPrivate
  //     .post(`jobs/send-email/${params.id}`, {
  //       company_id: auth?.companyId,
  //       job_id: params?.id,
  //       adminEmail: auth.email,
  //       adminName: auth.firstName,
  //       email: email,
  //       currentUrlLink: currentUrlLink,
  //     })
  //     .then(() => {
  //       Swal.fire({
  //         toast: true,
  //         icon: "success",
  //         title: "Link sent successfully",
  //         position: "top",
  //         timer: 2000,
  //         showConfirmBottom: false,
  //       });
  //     })
  //     .catch((error) => {
  //       Swal.fire({
  //         toast: true,
  //         icon: "success",
  //         title: "Failed to send link",
  //         position: "top",
  //         timer: 2000,
  //         showConfirmBottom: false,
  //       });
  //       console.error("Failed to send link", error);
  //     });
  //   setSentEmails([...sentEmails, email]);
  //   setEmail("");
  // };

  const handleSubmit = async () => {
    const res = await axiosPrivate
      .post(`share/applicantCard/${item.application_id}`, {
        admin_id: auth?.id,
        company_id: auth?.companyId,
        job_id: params?.id,
        email: email,
      })
      .catch(err => {
        console.error(err)
      })

    if (res) {
      setShowModal(false)
    }
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleEmailSelection = selectedEmail => {
    const updatedEmails = [...emails, selectedEmail]
    setEmails(updatedEmails)
    setEmail(selectedEmail)
    setIsOpen(false)
  }

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside)
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(currentUrlLink)
      .then(() => {
        Swal.fire({
          toast: true,
          icon: "success",
          title: "Link copied to clipboard",
          position: "top",
          timer: 2000,
          showConfirmBottom: false,
        })
      })
      .catch(error => {
        console.error("Failed to copy link", error)
      })
  }

  const emailOptions = [...emails, ...sentEmails]

  return (
    <>
      <button
        className="bg-blue-50 text-black active:bg-blue-100 
      font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ml-4 mb-1"
        type="button"
        onClick={() => setShowModal(true)}
      >
        Share
      </button>
      {showModal ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                  <button
                    className="text-black float-right"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="text-black opacity-7 h-6 w-6 text-xl block bg-gray-400 py-0 rounded-full">
                      x
                    </span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <form>
                    <div className="flex items-center">
                      <div className="relative" ref={dropdownRef}>
                        <input
                          placeholder="Enter email"
                          type="email"
                          value={email}
                          onClick={toggleDropdown}
                          onChange={e => setEmail(e.target.value)}
                          className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500 text-sm"
                        />
                        <span className="ml-2">
                          <select
                            value={selectedUser}
                            onChange={e => setSelectedUser(e.target.value)}
                            className="px-2 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500 text-sm"
                          >
                            <option value="admin">Admin</option>
                            <option value="guest">Guest</option>
                          </select>
                        </span>
                      </div>
                      {isOpen && (
                        <div className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded mt-2 shadow max-h-40 overflow-y-auto overflow-x-hidden">
                          <ul className="p-0 m-0">
                            {emailOptions.map(emailOption => (
                              <li
                                key={emailOption}
                                className="py-2 px-4 cursor-pointer hover:bg-gray-100 text-sm"
                                onClick={() =>
                                  handleEmailSelection(emailOption)
                                }
                              >
                                {emailOption}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
                <div className="flex items-center justify-between p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-white bg-blueBtn active:bg-yellow-700 font-bold text-sm px-3 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    type="Submit"
                    onClick={handleSubmit}
                  >
                    Send
                  </button>
                  <button
                    className="text-black active:bg-yellow-700 font-bold text-sm px-3 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    onClick={copyToClipboard}
                  >
                    Copy link
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}
export default ShareLinkModal
