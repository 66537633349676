import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import { availability, jobContractType, jobLevel } from "../../../utils/data"
import { FaUser, FaCloudUploadAlt } from "react-icons/fa"
import { FiPlus } from "react-icons/fi"
import axios from "axios"

const Details = ({ formik }) => {
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        width: "100%",
        mt: "1rem",
        mx: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .MuiTextField-root": { mt: "1rem" },
        "& .MuiSvgIcon-root": {
          color: "#000000",
        },
      }}
    >
      {/**job title */}
      <TextField
        required
        variant="outlined"
        fullWidth
        size="small"
        id="job_title"
        label="Title"
        value={formik.values.job_title}
        onChange={formik.handleChange("job_title")}
        error={formik.touched.job_title && Boolean(formik.errors.job_title)}
        helperText={formik.touched.job_title && formik.errors.job_title}
      />

      {/**company name */}
      <TextField
        required
        variant="outlined"
        fullWidth
        size="small"
        id="company_name"
        label="Company Name"
        value={formik.values.company_name}
        onChange={formik.handleChange("company_name")}
        error={
          formik.touched.company_name && Boolean(formik.errors.company_name)
        }
        helperText={formik.touched.company_name && formik.errors.company_name}
      />

      {/**company logo */}
      <Box sx={{ width: "100%" }}>
        <input
          type="file"
          id="logo"
          accept="image/*"
          onChange={e => formik.setFieldValue("logo", e.currentTarget.files[0])}
          hidden
        />

        <label
          htmlFor="logo"
          className={`${
            Boolean(formik.errors.logo)
              ? "border-[#d32f2f]"
              : "border-[#cccccc]"
          } bg-white border rounded-[4px]  mt-4 text-black text-base w-full cursor-pointer flex items-center justify-between`}
        >
          <span className="py-[8.5px] px-[14px]">
            {formik.values.logo ? formik.values.logo.name : "Choose a file..."}
          </span>
          <span className="flex items-center justify-end bg-gray-100 border-l border-[#cccccc] py-[8.5px] px-[14px]">
            <FaCloudUploadAlt style={{ marginRight: "8px" }} /> Upload Logo
          </span>
        </label>

        {Boolean(formik.errors.logo) && (
          <p className="text-[#d32f2f] text-xs my-1 ml-[14px] tracking-[0.0333em]">
            {formik.touched.logo && formik.errors.logo}
          </p>
        )}
      </Box>

      {/**job country */}
      <TextField
        required
        variant="outlined"
        fullWidth
        size="small"
        id="job_country"
        label="Country"
        value={formik.values.job_country}
        onChange={formik.handleChange("job_country")}
        error={formik.touched.job_country && Boolean(formik.errors.job_country)}
        helperText={formik.touched.job_country && formik.errors.job_country}
      />

      {/**job city */}
      <TextField
        required
        variant="outlined"
        fullWidth
        size="small"
        id="job_city"
        label="City"
        value={formik.values.job_city}
        onChange={formik.handleChange("job_city")}
        error={formik.touched.job_city && Boolean(formik.errors.job_city)}
        helperText={formik.touched.job_city && formik.errors.job_city}
      />

      {/**job level */}
      <TextField
        required
        variant="outlined"
        fullWidth
        size="small"
        id="job_level"
        label="Select Job Level"
        value={formik.values.job_level}
        onChange={formik.handleChange("job_level")}
        error={formik.touched.job_level && Boolean(formik.errors.job_level)}
        helperText={formik.touched.job_level && formik.errors.job_level}
        select
        SelectProps={{ MenuProps: { disableScrollLock: true } }}
      >
        {jobLevel.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      {/**contract type */}
      <TextField
        required
        variant="outlined"
        fullWidth
        size="small"
        id="contract_type"
        label="Select Contract Type"
        value={formik.values.contract_type}
        onChange={formik.handleChange("contract_type")}
        error={
          formik.touched.contract_type && Boolean(formik.errors.contract_type)
        }
        helperText={formik.touched.contract_type && formik.errors.contract_type}
        select
        SelectProps={{ MenuProps: { disableScrollLock: true } }}
      >
        {jobContractType.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      {/**availability */}
      <TextField
        required
        variant="outlined"
        fullWidth
        size="small"
        id="employment_type"
        label="Select Employment Type"
        value={formik.values.employment_type}
        onChange={formik.handleChange("employment_type")}
        error={
          formik.touched.employment_type &&
          Boolean(formik.errors.employment_type)
        }
        helperText={
          formik.touched.employment_type && formik.errors.employment_type
        }
        select
        SelectProps={{ MenuProps: { disableScrollLock: true } }}
      >
        {availability.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      {/**openings */}
      <TextField
        required
        variant="outlined"
        fullWidth
        type="number"
        size="small"
        id="openings"
        label="Number of Openings"
        value={formik.values.openings}
        onChange={formik.handleChange("openings")}
        error={formik.touched.openings && Boolean(formik.errors.openings)}
        helperText={formik.touched.openings && formik.errors.openings}
      />

      <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#3F51B5",
            mt: "1rem",
            py: "0.75rem",
            px: "1.5rem",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
          onClick={e => {
            formik.handleSubmit(e)
          }}
        >
          NEXT
        </Button>
      </Box>
    </Box>
  )
}

export default Details
