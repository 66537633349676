import { FaUser } from "react-icons/fa"
import { titleCase } from "../utils/helpers"
import { HiMail } from "react-icons/hi"
import { MdPhoneCallback } from "react-icons/md"

const AppDetailsCard = ({ applicant, index, setSelected }) => {
  return (
    <div
      className="w-full py-2.5 px-3 text-black rounded-md shadow-md cursor-pointer"
      onClick={() => setSelected(index)}
    >
      <div className="flex flex-col items-center">
        <div
          className={`${
            applicant?.avatar ? "bg-white" : "bg-gray-300"
          } w-16 h-16 rounded-full mr-0 md:mr-4 relative`}
        >
          {applicant?.avatar ? (
            <img
              crossOrigin="anonymous"
              src={`${process.env.REACT_APP_BASE_URL}storage/images/${applicant?.avatar}`}
              className="w-full h-full object-center object-contain rounded-full"
              alt=""
            />
          ) : (
            <FaUser
              size={24}
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white"
            />
          )}
        </div>
        <div className="flex flex-col mt-1 items-center">
          <div className="text-center">
            <p className="text-sm xl:text-normal">{`${titleCase(
              applicant?.first_name
            )} ${titleCase(applicant?.last_name)}`}</p>

            <p className="text-xs xl:text-sm">{applicant?.email}</p>
            <p className="text-xs xl:text-sm">{applicant?.phone_number}</p>
          </div>
          <div className="flex mt-1.5 ml-0">
            <a
              href={`mailto:${applicant?.email}`}
              className="bg-blue-500 rounded-full relative h-6 w-6 flex item-center justify-center mr-2"
            >
              <HiMail size={16} className="text-white m-auto" />
            </a>
            <a
              href={`telto:${applicant?.phone_number}`}
              className="bg-green-500 rounded-full relative h-6 w-6 flex item-center justify-center"
            >
              <MdPhoneCallback size={16} className="text-white m-auto" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppDetailsCard
