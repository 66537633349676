import { useNavigate } from "react-router-dom"
import useAuth from "../hooks/useAuth"
import * as yup from "yup"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import axios, { axiosPrivate } from "../api/axios"
import CandidateLayout from "../components/CandidateLayout"
import UserProfileHeader from "../components/UserProfileHeader"
import { FaUser } from "react-icons/fa"
import Swal from "sweetalert2"
import ProfileForm from "../components/myProfile/ProfileForm"
import Loading from "../components/Loading"
import { titleCase } from "../utils/helpers"
import { Helmet } from "react-helmet-async"

const validationSchema = yup.object({
  first_name: yup
    .string("Enter your first name")
    .required("This field is required."),
  last_name: yup
    .string("Enter your last name")
    .required("This field is required."),
  phone_number: yup
    .string("Enter your phone number")
    .required("This field is required."),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("This field is required."),
  avatar: yup.string("Select a file").required("This field is required."),
  country: yup
    .string("Select your country")
    .required("This field is required."),
  job_title: yup
    .string("Enter your job title")
    .required("This field is required."),
  linkedin: yup
    .string("Enter your linkedin")
    .required("This field is required."),
  experience: yup
    .string("Enter your years of experience")
    .required("This field is required."),
  currency: yup
    .string("Select your currency")
    .required("This field is required."),
  salary: yup
    .string("Enter your salary expectations")
    .required("This field is required."),
  skills: yup
    .array(yup.string("Enter your skills"))
    .required("This field is required."),
  // github: yup.string("Enter your github"),
  // resume: yup.string("Select a file"),
  // cover_letter: yup.string("Select a file"),
})

const CandidateProfile = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState()
  const [selectedSkills, setSelectedSkills] = useState([])
  const { auth } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const request = {
      signal: controller.signal,
    }

    const getUser = async () => {
      const res = await axios
        .get(`users?email=${auth?.email}`, request)
        .catch(err => console.error(err.response))
      if (res) {
        isMounted && setData(res.data.data.user)

        if (res.data.data.user.skills) {
          const newArr = res.data.data.user.skills.split(",")
          isMounted && setSelectedSkills(newArr)
        }
      }
      setIsLoading(false)
    }

    getUser()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [auth])

  const formik = useFormik({
    initialValues: {
      first_name: data?.first_name,
      last_name: data?.last_name,
      phone_number: data?.phone_number,
      email: data?.email,
      avatar: data?.avatar,
      country: data?.country,
      job_title: data?.job_title,
      linkedin: data?.linkedin,
      experience: data?.experience,
      skills: selectedSkills || [],
      currency: data?.currency || "NGN",
      salary: data?.salary,
      github: data?.github,
      resume: data?.resume,
      cover_letter: data?.cover_letter,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      if (values.resume && typeof values.resume === "object") {
        const formData = new FormData()
        formData.append("resume", values.resume)
        await axiosPrivate
          .post(`storage/upload-resume`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: `Bearer ${auth?.accessToken}`,
            },
          })
          .catch(err => console.error(err))
      }

      if (values.cover_letter && typeof values.cover_letter === "object") {
        const formData = new FormData()
        formData.append("coverLetter", values.cover_letter)
        await axiosPrivate
          .post(`storage/upload-coverLetter`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: `Bearer ${auth?.accessToken}`,
            },
          })
          .catch(err => console.error(err))
      }

      if (typeof values.avatar === "object") {
        const formData = new FormData()
        formData.append("file", values.avatar)
        const res = await axiosPrivate
          .post(`storage/upload-image`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: `Bearer ${auth?.accessToken}`,
            },
          })
          .catch(err => console.error(err))
        if (res) {
          const response = await axiosPrivate
            .put(`users/${data?.id}`, {
              first_name: values.first_name,
              last_name: values.last_name,
              phone_number: values.phone_number,
              email: values.email,
              avatar: res.data.data.filename,
              country: values.country,
              job_title: values.job_title,
              linkedin: values.linkedin,
              experience: values.experience,
              skills: values.skills.toString(),
              currency: values.currency || "NGN",
              salary: values.salary,
              github: values.github,
              resume:
                values.resume && typeof values.resume === "object"
                  ? values.resume.name
                  : values.resume,
              cover_letter:
                values.cover_letter && typeof values.cover_letter === "object"
                  ? values.cover_letter.name
                  : values.cover_letter,
            })
            .catch(error => {
              if (error.response.status === 500) {
                Swal.fire({
                  toast: true,
                  icon: "error",
                  title: "Oops..",
                  text: "An error occurred. Please try again",
                  position: "top",
                  timer: 3000,
                  showConfirmButton: false,
                })
              } else {
                Swal.fire({
                  toast: true,
                  icon: "error",
                  title: "Oops...",
                  text: error.response.data.message,
                  position: "top",
                  timer: 3000,
                  showConfirmButton: false,
                })
              }
            })
          if (response) {
            Swal.fire({
              toast: true,
              icon: "success",
              text: "Profile updated successfully",
              position: "top",
              timer: 3000,
              showConfirmButton: false,
            })
            resetForm()
            navigate("/candidate-dashboard/")
          }
        }
      } else {
        const response = await axiosPrivate
          .put(`users/${data?.id}`, {
            first_name: values.first_name,
            last_name: values.last_name,
            phone_number: values.phone_number,
            email: values.email,
            avatar: values.avatar,
            country: values.country,
            job_title: values.job_title,
            linkedin: values.linkedin,
            experience: values.experience,
            skills: values.skills.toString(),
            currency: values.currency,
            salary: values.salary,
            github: values.github,
            resume:
              values.resume && typeof values.resume === "object"
                ? values.resume.name
                : values.resume,
            cover_letter:
              values.cover_letter && typeof values.cover_letter === "object"
                ? values.cover_letter.name
                : values.cover_letter,
          })
          .catch(error => {
            if (error.response.status === 500) {
              Swal.fire({
                toast: true,
                icon: "error",
                title: "Oops..",
                text: "An error occurred. Please try again",
                position: "top",
                timer: 3000,
                showConfirmButton: false,
              })
            } else {
              Swal.fire({
                toast: true,
                icon: "error",
                title: "Oops...",
                text: error.response.data.message,
                position: "top",
                timer: 3000,
                showConfirmButton: false,
              })
            }
          })

        if (response) {
          Swal.fire({
            toast: true,
            icon: "success",
            text: "Profile updated successfully",
            position: "top",
            timer: 3000,
            showConfirmButton: false,
          })
          resetForm()
          navigate("/candidate-dashboard/")
        }
      }
    },
  })

  if (isLoading) {
    return (
      <CandidateLayout page="profile">
        <Helmet>
          <title>Edge</title>
        </Helmet>

        <Loading />
      </CandidateLayout>
    )
  }

  return (
    <CandidateLayout page="profile">
      <Helmet>
        <title>
          {titleCase(data?.first_name)} {titleCase(data?.last_name)} | Edge
        </title>
      </Helmet>

      <div className="min-h-screen">
        <UserProfileHeader />

        <div className="w-full py-10">
          <div className="mx-auto rounded-2xl bg-white pb-2 shadow-xl lg:w-3/5 sm:w-3/4 w-full py-6 px-4">
            <p className="text-black text-lg text-center">My Profile</p>
            <div className="my-4 p-4 text-black">
              <div className="flex justify-center items-center">
                {data?.avatar ? (
                  <div className="h-36 w-36 relative flex items-center justify-center rounded-full">
                    <img
                      crossOrigin="anonymous"
                      src={`${process.env.REACT_APP_BASE_URL}storage/images/${data?.avatar}`}
                      className="w-full h-full object-center rounded-full"
                      alt=" "
                    />
                  </div>
                ) : (
                  <div className="h-36 w-36 bg-gray-100 relative flex items-center justify-center rounded-full">
                    <FaUser size={100} className="text-white" />
                  </div>
                )}
              </div>

              <ProfileForm formik={formik} />
            </div>
          </div>
        </div>
      </div>
    </CandidateLayout>
  )
}

export default CandidateProfile
