import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import "./styles/index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import AuthContextProvider from "./contexts/AuthContext"
import PasswordResetContextProvider from "./contexts/PasswordResetContext"

ReactDOM.render(
  <BrowserRouter>
    <AuthContextProvider>
      <PasswordResetContextProvider>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </PasswordResetContextProvider>
    </AuthContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
