import React from 'react';
//import Menu from '../../components/Menu/index';
import AdminHeader from "../../components/AdminHeader";
import AdminLayout from "../../components/AdminLayout";
import RequestsTable from '../../components/Requests/index';
//import '../../components/Requests/style.scss';

const Requests = () => {
    return (
        <>
        <AdminLayout>
            <AdminHeader/>
            
            <div>
                <RequestsTable />
            </div>
        </AdminLayout>
        </>
    )
}

export default Requests;