import React from "react";
import CandidateLayout from "../components/CandidateLayout";
import CandidateHeader from "../components/CandidateHeader";
import Calendar from '../components/BigCalendar'
//import Menu from '../../components/Menu'
import './Leave/style.scss'
import Sidebar from '../components/Sidebar'

const MyLeave = () => {
    return (
        <>
        <CandidateLayout page="my-leave">
        <CandidateHeader />
            <div className="dashboard-mainpanel">
                <div className="dashboard-calendar">
                    <Calendar />
                </div> 
                <div className="dashboard-sidebar">
                    <Sidebar />
                </div>
            </div>
        </CandidateLayout>
        </>
    );

}
export default MyLeave;