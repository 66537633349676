import {
  changeApplicationDeadlineFormat,
  changeDateFormat,
  timestampToDate,
  isInThePast,
  titleCase,
  timestampToYYYYMMDD,
} from "../utils/helpers";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import useFetcherPrivate from "../hooks/useFetcherPrivate.js";

const JobCard = ({ data, page }) => {
  const navigate = useNavigate();
  const fetcherPrivate = useFetcherPrivate();
  const {
    data: status,
    isError,
    isLoading,
    isSuccess,
  } = useQuery(["status", data.id], () =>
    fetcherPrivate(`applications/status-per-job/${data.id}`)
  );

  const isExpired = isInThePast(new Date(data.application_deadline));

  const inputDateStr = new Date(data?.application_deadline);
  const inputDate = new Date(inputDateStr);
  const day = inputDate.getDate();
  const month = inputDate.getMonth() + 1;
  const year = inputDate.getFullYear();

  const outputDateStr = `${day.toString().padStart(2, "0")}/${month
    .toString()
    .padStart(2, "0")}/${year.toString()}`;

  return (
    <div className="bg-white border border-gray-200 rounded-md w-full py-2.5 px-3 shadow-md">
      <div className="flex">
        <div className="w-12 md:w-16">
          {data.company_logo ? (
            <div className="w-full h-auto mt-2.5 bg-white flex items-center">
              <img
                crossOrigin="anonymous"
                src={`${process.env.REACT_APP_BASE_URL}storage/images/${data.company_logo}`}
                alt=""
              />
            </div>
          ) : (
            <div className="w-full h-auto bg-gray-100"></div>
          )}
        </div>

        <div className="w-full pl-3 flex flex-col">
          <div className="h-[88px] flex flex-col">
            <span className="text-blueBtn font-semibold text-sm">
              {titleCase(data.job_title)}
            </span>
            <span className="text-black text-xs">
              {titleCase(data.company_name)}
            </span>
            <span className="text-gray-500 text-xs">{`${titleCase(
              data.job_city
            )}, ${titleCase(data.job_country)}`}</span>
          </div>

          <div className="">
            {isExpired ? (
              <p className="font-semibold text-xs md:text-xs text-slate-700">
                Expired:
                <span className="text-blue-500 text-xs px-2 md:text-xs">
                  {timestampToYYYYMMDD(data.application_deadline)}
                </span>
              </p>
            ) : (
              <p className="font-semibold text-xs md:text-xs text-slate-700">
                Posted On:
                <span className="text-blue-500 text-xs px-2 md:text-xs">
                  {timestampToYYYYMMDD(data.created_at)}
                </span>
              </p>
            )}
          </div>

          <button
            className="bg-blueBtn text-white text-sm py-1.5 px-2.5 rounded mt-2 sm:w-3/5 w-3/4"
            onClick={() =>
              navigate(
                `/${page}/${data?.id}/${data?.job_title.replace(
                  "/",
                  "___"
                )} at ${data?.company_name}`
              )
            }
          >
            View Job
          </button>
        </div>
      </div>
      {page === "jobs" && status && (
        <div className="flex flex-wrap justify-between">
          <div className="w-1/2 py-2 my-1">
            <div className="flex items-center">
              <div className="w-20">
                <button className="text-xs text-black font-font-medium">
                  Applications
                </button>
              </div>
              <span className="bg-purple-500 text-white text-xs px-1.6 py-1 h-6 w-7 rounded-md text-center drop-shadow-xl">
                {status.total}
              </span>
            </div>
          </div>

          <div className="w-1/2 py-2 my-1">
            <div className="flex items-center">
              <div className="w-20">
                <button className="text-xs text-black font-font-medium">
                  Shortlisted
                </button>
              </div>
              <span className="bg-blue-500 text-white text-xs px-1.6 py-1 h-6 w-7 rounded-md text-center drop-shadow-xl">
                {status.shortlisted}
              </span>
            </div>
          </div>

          <div className="w-1/2 py-2 my-1">
            <div className="flex items-center">
              <div className="w-20">
                <button className="text-xs text-black font-font-medium">
                  Rejected
                </button>
              </div>
              <span className="bg-red-500 text-white text-xs px-1.6 py-1 h-6 w-7 rounded-md text-center drop-shadow-xl">
                {status.rejected}
              </span>
            </div>
          </div>

          <div className="w-1/2 py-2 my-1">
            <div className="flex items-center">
              <div className="w-20">
                <button className="text-xs text-black font-font-medium">
                  Selected
                </button>
              </div>
              <span className="bg-green-200 text-slate-900 text-xs px-1.6 py-1 h-6 w-7 rounded-md text-center drop-shadow-xl">
                {status.selected}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobCard;
