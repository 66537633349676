import React, { useState, useContext, useEffect } from "react"
//import { useNavigate } from "react-router-dom"
import axios from "../api/axios"
import {
  BsCaretDownFill,
  // BsFillBellFill,
  // BsFillChatSquareDotsFill,
} from "react-icons/bs"
import { FaUser } from "react-icons/fa"
//import { IoIosSearch } from "react-icons/io"
import { FiMenu, FiX } from "react-icons/fi"
import CandidateMenu from "./CandidateMenu"
import { SidebarContext } from "../contexts/SidebarContext"
import useAuth from "../hooks/useAuth"

const SettingsHeader = ({ page, setQuery }) => {
  const [user, setUser] = useState({})
  const { auth } = useAuth()
  //const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)

  const { showSidebar, setShowSidebar } = useContext(SidebarContext)

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const request = {
      signal: controller.signal,
    }

    const getUser = async () => {
      const res = await axios
        .get(`users?email=${auth.email}`, request)
        .catch(async err => {
          console.error(err?.response)
          // navigate("/", { state: { from: location }, replace: true })
        })
      if (res?.data?.data) {
        isMounted && setUser(res.data?.data?.user)
      }
    }

    getUser()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [auth])

  return (
    <nav className="sticky top-0 w-full flex items-center justify-end h-16 px-8 bg-white shadow z-40">
      <div className="flex items-ends text-gray-500 sm:space-x-4 space-x-2">
        <span
          className="flex flex-col items-center justify-center hover:text-gray-800 cursor-pointer relative"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="w-6 h-6 flex justify-center rounded-full bg-gray-300 relative">
            {user?.avatar ? (
              <img
                crossOrigin="anonymous"
                src={`${process.env.REACT_APP_BASE_URL}storage/images/${user?.avatar}`}
                className="w-full h-full object-center rounded-full"
                alt=" "
              />
            ) : (
              <FaUser
                size={16}
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white"
              />
            )}
          </span>
          <span className="flex items-center">
            <p className="text-xs sm:block hidden sm:mr-1 mr-0">Me</p>
            <BsCaretDownFill size={12} />
          </span>
          {isOpen && <CandidateMenu />}
        </span>
        <span
          className="sm:pl-2 pl-0 lg:hidden flex"
          onClick={() => setShowSidebar(!showSidebar)}
        >
          {showSidebar ? <FiX size={28} /> : <FiMenu size={28} />}
        </span>
      </div>
    </nav>
  )
}

export default SettingsHeader
