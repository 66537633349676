import { FiX } from "react-icons/fi"
import useAuth from "../hooks/useAuth"
import * as yup from "yup"
import Swal from "sweetalert2"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import { useFormik } from "formik"
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", sm: "50%" },
  maxHeight: "90vh",
  bgcolor: "background.paper",
  color: "#000000",
  border: "0px",
  boxShadow: 24,
  px: 2,
  py: 4,
  outline: "none",
  overflow: "auto",
}

const validationSchema = yup.object().shape({
  first_name: yup
    .string()
    .required("This field is required")
    .min(3, "First name must be at least 3 characters"),
  last_name: yup
    .string()
    .required("This field is required")
    .min(3, "Last name must be at least 3 characters"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("This field is required"),
})

const AddAdmin = ({ showModal, setShowModal }) => {
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()

  const request = {
    headers: {
      authorization: `Bearer ${auth.accessToken}`,
    },
  }

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
    },
    validationSchema,
    validateBlur: false,
    validateOnChange: false,
    onSubmit: async values => {
      const res = await axiosPrivate
        .post(
          "admins/companies/",
          {
            company_id: auth?.companyId,
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
          },
          request
        )
        .catch(error => {
          Swal.fire({
            toast: true,
            icon: "error",
            title: "Oops...",
            text: error?.response?.data?.message,
            position: "top",
            timer: 2000,
            showConfirmButton: false,
          })
        })
      if (res) {
        Swal.fire({
          toast: true,
          icon: "success",
          title: "Admin Created",
          text: res?.data?.message,
          position: "top",
          timer: 2000,
          showConfirmBottom: false,
        })
        setShowModal(false)
      }
    },
  })

  return (
    <Modal
      open={showModal}
      aria-labelledby="modal-modal-title"
      disableScrollLock
    >
      <Box sx={style}>
        <Box sx={{ position: "relative" }}>
          <IconButton
            sx={{ position: "fixed", top: "0px", right: "0px" }}
            onClick={() => {
              setShowModal(false)
            }}
          >
            <FiX />
          </IconButton>
        </Box>

        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ textAlign: "center", fontWeight: "700" }}
        >
          Add new Admin
        </Typography>

        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            width: "80%",
            mt: "1rem",
            mx: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& .MuiTextField-root": { mt: "1rem" },
            "& .MuiSvgIcon-root": {
              color: "#000000",
            },
          }}
          onSubmit={formik.handleSubmit}
        >
          {/**first name */}
          <TextField
            required
            variant="outlined"
            size="small"
            fullWidth
            id="first_name"
            label="First Name"
            value={formik.values.first_name}
            onChange={formik.handleChange("first_name")}
            error={
              formik.touched.first_name && Boolean(formik.errors.first_name)
            }
            helperText={formik.touched.first_name && formik.errors.first_name}
          />

          {/**last name */}
          <TextField
            required
            variant="outlined"
            size="small"
            fullWidth
            id="last_name"
            label="Last Name"
            value={formik.values.last_name}
            onChange={formik.handleChange("last_name")}
            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
            helperText={formik.touched.last_name && formik.errors.last_name}
          />

          {/**email */}
          <TextField
            required
            variant="outlined"
            size="small"
            fullWidth
            id="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange("email")}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: "1rem" }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
export default AddAdmin
