export const bgColors = {
  a: "bg-red-400",
  b: "bg-orange-400",
  c: "bg-amber-400",
  d: "bg-yellow-400",
  e: "bg-lime-400",
  f: "bg-green-400",
  g: "bg-emerald-400",
  h: "bg-teal-400",
  i: "bg-cyan-400",
  j: "bg-sky-400",
  k: "bg-blue-400",
  l: "bg-indigo-400",
  m: "bg-violet-400",
  n: "bg-purple-400",
  o: "bg-fuchsia-400",
  p: "bg-pink-400",
  q: "bg-rose-400",
  r: "bg-red-300",
  s: "bg-orange-300",
  t: "bg-amber-300",
  u: "bg-yellow-300",
  v: "bg-lime-300",
  w: "bg-green-300",
  x: "bg-emerald-300",
  y: "bg-teal-300",
  z: "bg-cyan-300",
  guest: "bg-sky-300",
}
