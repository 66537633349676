export const server = "https://api.cloudinary.com/"

const apiList = {
  //login: `${server}/auth/login`,
  //signup: `${server}/auth/signup`,
  uploadResume: `${server}/v1_1/edge-app/upload`,
  uploadCoverLetter: `${server}/v1_1/edge-app/upload`,
  uploadProfileImage: `${server}/v1_1/edge-app/upload`,
  jobs: `${server}/api/jobs`,
  applications: `${server}/api/applications`,
  rating: `${server}/api/rating`,
  user: `${server}/v1/users/user`,
  applicants: `${server}/api/applicants`,
}

export default apiList
