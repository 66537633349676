import { useState, useEffect } from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import ApplicantList from "../components/ApplicantList"
import AdminLayout from "../components/AdminLayout"
import AdminHeader from "../components/AdminHeader"
import useAuth from "../hooks/useAuth"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import Loading from "../components/Loading"
import { Helmet } from "react-helmet-async"

const ManageApplicant = () => {
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(1)
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const [query, setQuery] = useState("")

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const request = {
      signal: controller.signal,
      headers: {
        authorization: `Bearer ${auth?.accessToken}`,
      },
    }

    const fetchData = async () => {
      const res = await axiosPrivate
        .get(`applicants/${auth?.companyId}?page=${page}`, request)
        .catch(err => {
          console.error(err.response)
        })

      if (res) {
        isMounted && setData(res.data.data)
      }

      setIsLoading(false)
    }

    fetchData()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [page])

  const handlePrev = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const handleNext = () => {
    setPage(page + 1)
  }

  const search = array => {
    return array.filter(
      item =>
        item?.applicant?.first_name?.includes(query) ||
        item?.applicant?.last_name?.includes(query) ||
        item?.applicant?.country?.includes(query) ||
        item?.applicant?.email?.includes(query) ||
        item?.applicant?.phone_number?.includes(query) ||
        item?.application?.job_role?.includes(query) ||
        item?.application?.status?.includes(query)
    )
  }

  if (isLoading) {
    return (
      <AdminLayout page="applicant">
        <AdminHeader page="manage-applicants" setQuery={setQuery} />
        <Loading />
      </AdminLayout>
    )
  }

  return (
    <AdminLayout page="applicants" role={auth?.role}>
      <Helmet>
        <title>Applicant Management | Edge</title>
      </Helmet>

      <AdminHeader page="manage-applicants" setQuery={setQuery} />
      <div className="w-full sm:py-10 py-5 sm:px-8 px-2">
        <ApplicantList data={data} search={search} />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#3F51B5",
              mt: "1rem",
              py: "0.75rem",
              px: "1.5rem",
              "&:hover": {
                backgroundColor: "#3F51B5",
              },
            }}
            onClick={() => handlePrev()}
          >
            PREV
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#3F51B5",
              mt: "1rem",
              py: "0.75rem",
              px: "1.5rem",
              "&:hover": {
                backgroundColor: "#3F51B5",
              },
            }}
            onClick={() => handleNext()}
          >
            NEXT
          </Button>
        </Box>
      </div>
    </AdminLayout>
  )
}

export default ManageApplicant
