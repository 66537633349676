import { useState } from "react"
import UserAssessmentHeader from "../../components/UserAssessmentHeader"
import CandidateLayout from "../../components/CandidateLayout"
import AssessmentLanding from "./AssessmentLanding"
import AssessmentQuiz from "./AssessmentQuiz"
import { Helmet } from "react-helmet-async"

const AssessmentPage = () => {
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState({})

  return (
    <CandidateLayout page="quiz">
      <Helmet>
        <title>My Assessments | Edge</title>
      </Helmet>

      <div className="min-h-screen bg-no-repeat bg-cover bg-center bg-fixed bg-[url('/public/techteam2.jpg')]">
        <UserAssessmentHeader />

        <div>
          {page === 1 && (
            <AssessmentLanding
              setPage={setPage}
              setQuery={setQuery}
              query={query}
            />
          )}

          {page === 2 && <AssessmentQuiz setPage={setPage} query={query} />}
        </div>
      </div>
    </CandidateLayout>
  )
}

export default AssessmentPage
