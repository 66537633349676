import { useState } from "react";
import OnbImg from "../assets/onboarding-logo.jpg";

const OnboardingNav = () => {
  const [state, setState] = useState({
    admin: {
      name: "John",
    },
  });

  let { admin } = state;

  return (
    <div className="w-full">
      <div className="flex justify-between items-center py-5 border-b-2 shadow-md">
        <div className=""></div>
        <div className="flex justify-center items-center space-x-2">
          <img src={OnbImg} alt="" className="w-12 h-12 rounded-full" />
          <div className="text-gray-400 text-2xl">Employee Onboarding</div>
          
        </div>

        <div className="">
          <div className="flex">
            <div className="text-gray-700 font-bold">Welcome</div>
            <span className="text-gray-600 px-6">{admin.name}</span>
          </div>

          <div className="flex flex-row py-2">
            <div className="px-2">
              <input type="checkbox" />
              <span className="text-gray-600 pl-1">HR</span>
            </div>
            <div>
              <input type="checkbox" />
              <span className="text-gray-600 pl-1">Manager</span>
            </div>
          </div>
        </div>
      </div>
      <div className="px-2 mt-1">
        <div className="bg-blue-300 py-4 text-center">
          <div>Dashboard</div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingNav;
