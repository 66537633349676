import React from "react"
import { FiPlus } from "react-icons/fi"
import { titleCase } from "../../utils/helpers"
import { Custom, Industry, Language, Tool } from "./ScreeningOptions"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"

const Screening = ({
  screeningQuestions,
  setScreeningQuestions,
  jobId,
  formik,
  currentStep,
  setCurrentStep,
}) => {
  const handleOnChange = (event, index) => {
    event.preventDefault()
    let data = [...screeningQuestions]
    data[index][event.target.name] = event.target.value
    setScreeningQuestions(data)
    // setUserData({ ...userData, job_screening: data })
  }

  const addQuestion = value => {
    let newField = {
      job_id: jobId,
      screening_question_group: value,
      screening_question_value: "",
      screening_required_answer: "",
      screening_must_have_qualification: false,
    }
    // setUserData({
    //   ...userData,
    //   job_screening: [...screeningQuestions, newField],
    // })
    setScreeningQuestions([...screeningQuestions, newField])
  }

  const removeQuestion = index => {
    const newList = [...screeningQuestions]
    newList.splice(index, 1)
    // setUserData({ ...userData, job_screening: newList })
    setScreeningQuestions(newList)
  }

  const handleCheckbox = (event, index) => {
    let data = [...screeningQuestions]
    data[index][event.target.name] = event.target.checked
    // setUserData({ ...userData, job_screening: data })
    setScreeningQuestions(data)
  }

  return (
    <div className="py-3 px-6">
      {screeningQuestions.length > 0
        ? screeningQuestions.map((question, index) => {
            return (
              <div key={index} className="mb-4">
                {question.screening_question_group === "tool or technology" && (
                  <Tool
                    question={question}
                    index={index}
                    handleOnChange={handleOnChange}
                    removeQuestion={removeQuestion}
                    handleCheckbox={handleCheckbox}
                  />
                )}

                {question.screening_question_group === "language" && (
                  <Language
                    question={question}
                    index={index}
                    handleOnChange={handleOnChange}
                    removeQuestion={removeQuestion}
                    handleCheckbox={handleCheckbox}
                  />
                )}

                {question.screening_question_group === "industry" && (
                  <Industry
                    question={question}
                    index={index}
                    handleOnChange={handleOnChange}
                    removeQuestion={removeQuestion}
                    handleCheckbox={handleCheckbox}
                  />
                )}

                {question.screening_question_group === "custom" && (
                  <Custom
                    question={question}
                    index={index}
                    handleOnChange={handleOnChange}
                    removeQuestion={removeQuestion}
                    handleCheckbox={handleCheckbox}
                  />
                )}
              </div>
            )
          })
        : null}

      <div className="border-t border-gray-200 py-3 mt-6">
        <span className="text-gray-600 text-sm">Add screening questions:</span>
        <div className="flex flex-wrap mt-3 space-x-2">
          <button
            className="py-1.5 px-3 text-gray-600 text-sm border border-gray-400 flex items-center space-x-2"
            onClick={() => addQuestion("tool or technology")}
          >
            <p>Expertise with tools</p> <FiPlus size={16} />
          </button>
          <button
            className="py-1.5 px-3 text-gray-600 text-sm border border-gray-400 flex items-center space-x-2"
            onClick={() => addQuestion("language")}
          >
            <p>Language</p> <FiPlus size={16} />
          </button>
          <button
            className="py-1.5 px-3 text-gray-600 text-sm border border-gray-400 flex items-center space-x-2"
            onClick={() => addQuestion("industry")}
          >
            <p>Industry</p> <FiPlus size={16} />
          </button>
          <button
            className="py-1.5 px-3 text-gray-600 text-sm border border-gray-400 flex items-center space-x-2"
            onClick={() => addQuestion("custom")}
          >
            <p>Custom Question</p> <FiPlus size={16} />
          </button>
        </div>
      </div>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#3F51B5",
            mt: "1rem",
            py: "0.75rem",
            px: "1.5rem",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
          onClick={() => {
            setCurrentStep(currentStep - 1)
          }}
        >
          PREV
        </Button>

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#3F51B5",
            mt: "1rem",
            py: "0.75rem",
            px: "1.5rem",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
          onClick={e => {
            formik.handleSubmit(e)
          }}
        >
          NEXT
        </Button>
      </Box>
    </div>
  )
}

export default Screening
