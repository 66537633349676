import { titleCase } from "../../utils/helpers"
import { Box, Button, MenuItem, TextField, Typography } from "@mui/material"

const AdditionalQuestions = ({ job, formik, page, setPage }) => {
  const handleOnChange = (event, index) => {
    event.preventDefault()
    let data = [...formik.values.screeningAnswers]
    data[index] = event.target.value
    formik.setFieldValue("screeningAnswers", data)
  }
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        width: "100%",
        mt: "1rem",
        mx: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        "& .MuiTextField-root": { mt: "1rem" },
        "& .MuiSvgIcon-root": {
          color: "#000000",
        },
      }}
      onSubmit={formik.handleSubmit}
    >
      <Typography variant="body2" sx={{ fontWeight: 600 }} gutterBottom>
        Additional Questions
      </Typography>

      {job?.screeningQuestions.map((question, index) => (
        <Box sx={{ width: "100%" }} key={question.id}>
          {question?.screening_question_group === "tool or technology" && (
            <TextField
              required={question?.screening_must_have_qualification == 1}
              variant="outlined"
              size="small"
              fullWidth
              id="tool"
              type="number"
              label={`How many years of experience do you have using ${titleCase(
                question?.screening_question_value
              )}?`}
              value={formik.values.screeningAnswers[index]}
              onChange={e => handleOnChange(e, index)}
            />
          )}

          {question?.screening_question_group === "language" && (
            <TextField
              required={question?.screening_must_have_qualification == 1}
              variant="outlined"
              size="small"
              fullWidth
              id="language"
              label={`What is your level of proficiency in ${titleCase(
                question?.screening_question_value
              )}?`}
              value={formik.values.screeningAnswers[index]}
              onChange={e => handleOnChange(e, index)}
              select
              SelectProps={{ MenuProps: { disableScrollLock: true } }}
            >
              <MenuItem value="beginner">Beginner</MenuItem>
              <MenuItem value="intermediate">Intermediate</MenuItem>
              <MenuItem value="expert">Expert</MenuItem>
              <MenuItem value="native">Native</MenuItem>
            </TextField>
          )}

          {question?.screening_question_group === "industry" && (
            <TextField
              required={question?.screening_must_have_qualification == 1}
              variant="outlined"
              size="small"
              fullWidth
              id="industry"
              label={`How many years of ${titleCase(
                question?.screening_question_value
              )} experience do you currently have?`}
              value={formik.values.screeningAnswers[index]}
              onChange={e => handleOnChange(e, index)}
            />
          )}

          {question?.screening_question_group === "custom" && (
            <TextField
              required={question?.screening_must_have_qualification == 1}
              variant="outlined"
              size="small"
              fullWidth
              id="custom"
              label={question?.screening_question_value}
              value={formik.values.screeningAnswers[index]}
              onChange={e => handleOnChange(e, index)}
            />
          )}
        </Box>
      ))}

      {formik.touched.screeningAnswers && formik.errors.screeningAnswers ? (
        <Typography variant="body2" sx={{ color: "red", mt: 1 }}>
          {formik.errors.screeningAnswers}
        </Typography>
      ) : null}

      <Box
        sx={{
          mt: "2rem",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#3F51B5",
            py: "0.5rem",
            px: "1.5rem",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
          onClick={() => {
            setPage(page - 1)
          }}
        >
          PREV
        </Button>

        <Button
          variant="contained"
          type="submit"
          sx={{
            backgroundColor: "#3F51B5",
            py: "0.5rem",
            px: "1.5rem",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
        >
          NEXT
        </Button>
      </Box>
    </Box>
  )
}

export default AdditionalQuestions
