import React from 'react';
import { Link } from "react-router-dom";

import '../styles/comingSoon.css';

const ComingSoon = () => {
	return (
		<div className='App'>
			<div className='container'>
				<h1>
					Feature
					<br />
					Coming Soon
				</h1>
				<Link to="/">Log Out</Link>
			</div>
		</div>
	);
};

export default ComingSoon;
