import { useQuery } from "react-query"
import Comment from "./Comment"
import Swal from "sweetalert2"
import useAuth from "../../hooks/useAuth"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import { useParams } from "react-router"
import useFetcherPrivate from "../../hooks/useFetcherPrivate"
import * as yup from "yup"
import { useFormik } from "formik"
import { Box, TextField } from "@mui/material"
import { titleCase } from "../../utils/helpers"
import { bgColors } from "../../utils/bgColors"
import { useState } from "react"
import AccessControl from "../accessControl/AccessControl"

const validationSchema = yup.object().shape({
  comment: yup.string().required("This field is required"),
})

const CommentSection = ({ item, permissions }) => {
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const fetcherPrivate = useFetcherPrivate()
  const [commentsLimit, setCommentsLimit] = useState(5)

  const params = useParams()

  const { data: comments, refetch } = useQuery(
    ["comments", item.application_id, params.id, commentsLimit],
    () =>
      fetcherPrivate(
        `jobs/comments/${item?.application_id}/${params?.id}?limit=${commentsLimit}`,
        auth?.accessToken
      )
  )

  const formik = useFormik({
    initialValues: {
      comment: "",
    },
    validationSchema,
    validateBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      const res = await axiosPrivate.post(`jobs/comment`, {
        job_id: params?.id,
        application_id: item?.application_id,
        name: permissions ? "guest" : auth?.firstName,
        comment: values.comment,
      })

      if (res) {
        resetForm()
        refetch()

        Swal.fire({
          toast: true,
          icon: "success",
          title: "Comment has been added successfully",
          position: "top",
          timer: 3000,
          showConfirmButton: false,
        })
      }
    },
  })

  return (
    <div>
      {/* add new comment */}
      <AccessControl
        userPermission={permissions ? permissions : auth?.role}
        allowedPermissions={["superAdmin", "admin", "contributor"]}
      >
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            width: "100%",
            mx: "auto",
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            "& .MuiSvgIcon-root": {
              color: "#000000",
            },
            border: "1px solid",
            borderColor: "#e2e8f0",
            borderRadius: "8px",
            cursor: "default",
          }}
          onSubmit={formik.handleSubmit}
        >
          <div className="flex gap-1.5 items-center">
            <div
              className={`${
                permissions
                  ? bgColors["guest"]
                  : bgColors[`${auth?.firstName?.charAt(0)}`]
              } w-8 h-8 rounded-full text-white flex items-center justify-center`}
            >
              {permissions ? (
                <p className="text-sm">G</p>
              ) : (
                <p className="text-sm">
                  {auth?.firstName?.charAt(0).toUpperCase()}
                </p>
              )}
            </div>

            <p className="text-sm text-slate-700">
              {permissions ? "Guest" : titleCase(auth?.firstName)}
            </p>
          </div>

          <TextField
            variant="standard"
            fullWidth
            size="small"
            placeholder="Leave a comment"
            value={formik.values.comment}
            onChange={formik.handleChange("comment")}
            error={formik.touched.comment && Boolean(formik.errors.comment)}
            helperText={formik.touched.comment && formik.errors.comment}
            multiline
            rows={1}
            InputProps={{ disableUnderline: true }}
            sx={{
              mt: "0.5rem",
              px: "4px",
              "& .MuiInputBase-input": { fontSize: "14px" },
            }}
          />

          <div className="mt-2 w-full flex justify-end">
            <button
              type="submit"
              className="py-2 px-6 rounded-md text-xs bg-blue-500"
            >
              Post
            </button>
          </div>
        </Box>
      </AccessControl>

      {/* comments */}
      <div className="flex flex-col gap-1.5 mt-8">
        {comments?.map(item => (
          <Comment
            key={item.id}
            item={item}
            permissions={permissions}
            auth={auth}
          />
        ))}

        {comments?.length === commentsLimit && (
          <button
            className="w-full py-2 text-black text-xs bg-gray-100 shadow-md rounded-md mt-8"
            onClick={() => {
              setCommentsLimit(comments?.length + 5)
              refetch()
            }}
          >
            Load More
          </button>
        )}
      </div>
    </div>
  )
}

export default CommentSection
