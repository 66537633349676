import React, { useState } from 'react'
import CandidateLayout from "../../components/CandidateLayout";
import CandidateHeader from "../../components/CandidateHeader";
import Button from '@mui/material/Button'
import Calendar from '../../components/BigCalendar'

import LeaveRequestModal from '../../components/LeaveRequestModal'
import './style.scss'

const Leave = () => {

    const [showModal, setShowModal] = useState(false)
    const [leaveData, setLeaveData] = useState("")

    return (
        <>
        <CandidateLayout page="leave">
        <CandidateHeader />
        
           <Button   variant="contained"  style={{ backgroundColor:"#3f51b5", padding: "10px 50px", marginTop: "20px", marginBottom:"20px", marginLeft:"10px" }} onClick={() => setShowModal(true)}>Apply for Leave</Button>
            <div>
                {showModal && ( <LeaveRequestModal
                setShowModal = {setShowModal} 
                leaveData={leaveData}
                setLeaveData={setLeaveData}
                />)}
            
                <div >
                    <Calendar />
                </div>
                
            </div>
        </CandidateLayout>
        </>
    );
}
export default Leave;