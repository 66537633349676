import React, { useContext, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import axios from "../api/axios"
import { PasswordResetContext } from "../contexts/PasswordResetContext"
import "../styles/loginBG.css"
import * as yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert2"
import { Helmet } from "react-helmet-async"

const validationSchema = yup.object({
  email: yup
    .string("Enter your company email")
    .email("Enter a valid email")
    .required("This field is required"),
})

const ForgotPassword = () => {
  const navigate = useNavigate()
  const [message, setMessage] = useState("")

  const { role } = useContext(PasswordResetContext)
  const url =
    role === "admin"
      ? "auth/reset-password/admin"
      : role === "user"
      ? "auth/reset-password/user"
      : null

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      const res = await axios
        .post(url, {
          email: values.email,
        })
        .catch(err => {
          console.error(err.response)
          setMessage(err.response.data.message)
        })

      if (res?.status === 201) {
        Swal.fire({
          toast: true,
          icon: "success",
          title:
            "An email has been sent with a link to change your password to this email.",
          position: "top",
          timer: 3000,
          showConfirmButton: false,
        })
        resetForm()
      }
    },
  })

  return (
    <div className="h-screen bg-slate-200 flex justify-center items-center w-screen relative">
      <Helmet>
        <title>Reset Password | Edge</title>
      </Helmet>

      <div className="background">
        <Link to="/" className="absolute top-4 left-4">
          <div className="h-24 w-24">
            <img src={require("../assets/logopic.png")} alt="" />
          </div>
        </Link>

        <form className="flex justify-center items-center flex-col w-screen h-screen">
          <div className="bg-slate-50 px-10 py-8 rounded-xl shadow-md max-w-sm">
            <div className="space-y-3">
              <h1 className="text-center text-xl font-semibold text-gray-600">
                Forgot Password?
              </h1>
              <div>
                {message ? (
                  <p className="text-danger text-red-600 text-base">
                    {message}
                  </p>
                ) : null}
              </div>

              {/**email */}
              <div>
                <label
                  htmlFor="email"
                  className="block text-gray-600 font-semibold text-xl"
                >
                  Email
                </label>
                <input
                  type="email"
                  className="text-black bg-indigo-50 px-4 py-2 outline-none rounded-md w-full text-xl"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange("email")}
                  onBlur={formik.handleBlur}
                  placeholder="Email"
                  id="email"
                  autoComplete="off"
                />
                <div className="text-danger text-red-600 text-sm">
                  {formik.touched.email && formik.errors.email}
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="mt-4 w-full bg-blue-900 font-semibold py-2 rounded-md text-xl tracking-wide"
              onClick={e => {
                formik.handleSubmit(e)
              }}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
export default ForgotPassword
