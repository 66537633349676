import { useState } from "react"
import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import { FiX } from "react-icons/fi"
import EditAssessmentQuestions from "./EditAssessmentQuestions"
import useAuth from "../../../hooks/useAuth"
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import Stepper from "./Stepper"
import Details from "./Details"
import Preview from "./Preview"
import Swal from "sweetalert2"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", sm: "50%" },
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: "0px",
  boxShadow: 24,
  px: 4,
  py: 2,
  outline: "none",
  overflow: "auto",
}

const EditAssessment = ({ data, setIsOpen, isOpen, id }) => {
  const [questions, setQuestions] = useState(data)
  const steps = ["Questions", "Preview"]
  const [currentStep, setCurrentStep] = useState(1)

  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const request = {
    headers: {
      authorization: `Bearer ${auth.accessToken}`,
    },
  }

  const onSubmit = async () => {
    if (currentStep === 1) {
      setCurrentStep(2)
    }

    if (currentStep === 2) {
      const res = await axiosPrivate
        .put(
          `assessment/${id}`,
          {
            multiple_questions: questions,
          },
          request
        )
        .catch(error => {
          Swal.fire({
            toast: true,
            icon: "error",
            title: error.response.data.message,
            position: "top",
            timer: 5000,
            showConfirmButton: false,
            // width: "auto",
          })
        })

      if (res) {
        setIsOpen(false)
        Swal.fire({
          toast: true,
          // icon: "success",
          title: "Your assessment has been edited successfully.",
          position: "top",
          timer: 5000,
          showConfirmButton: false,
          width: "auto",
        })
      }
    }
  }

  const displayStep = step => {
    switch (step) {
      case 1:
        return (
          <Details
            questions={questions}
            setQuestions={setQuestions}
            id={id}
            onSubmit={onSubmit}
          />
        )
      case 2:
        return (
          <Preview
            questions={questions}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            onSubmit={onSubmit}
          />
        )
      default:
    }
  }

  return (
    <div className="flex justify-center items-center py-6 px-16">
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="modal-modal-title"
        disableScrollLock
      >
        <Box sx={style}>
          <Box sx={{ position: "relative" }}>
            <IconButton
              sx={{ position: "fixed", top: "0px", right: "0px" }}
              onClick={() => {
                setIsOpen(false)
                // navigate(0)
              }}
            >
              <FiX />
            </IconButton>
          </Box>

          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", fontWeight: "700" }}
          >
            Edit Assessment
          </Typography>

          <Box>
            <Stepper steps={steps} currentStep={currentStep} />
            <div className="my-4 py-4">{displayStep(currentStep)}</div>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default EditAssessment
