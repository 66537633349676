import React from "react"
import { bgColors } from "../../utils/bgColors"
import { titleCase } from "../../utils/helpers"
import moment from "moment"

const SharedCardsHistory = ({ data }) => {
  return (
    <div className="flex flex-col gap-1.5 mt-4">
      {data?.map((item, index) => (
        <div className="flex w-full gap-2" key={index}>
          <div
            className={`${
              item?.sender === "guest"
                ? bgColors["guest"]
                : bgColors[`${item?.sender?.charAt(0)}`]
            } w-8 h-8 rounded-full text-white flex items-center justify-center`}
          >
            <p className="text-sm">{item?.sender?.charAt(0).toUpperCase()}</p>
          </div>

          <div className="flex-1">
            <p className="text-sm text-slate-800">{titleCase(item?.sender)}</p>
            <div className="text-slate-800 text-xs w-full flex flex-col gap-1 items-end">
              <div className="flex items-center gap-1 w-full flex-wrap">
                <span className="text-slate-800 break-words">
                  Shared this application card with
                </span>
                <span className="text-slate-800 font-semibold">
                  {item?.email_shared_to}
                </span>
              </div>

              <div>
                <span className="text-gray-400">
                  {moment(item?.created_at).fromNow()}
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default SharedCardsHistory
