export const availability = [
  {
    value: "Onsite",
    label: "Onsite",
  },
  {
    value: "Remote",
    label: "Remote",
  },
  {
    value: "Hybrid",
    label: "Hybrid",
  },
]

export const contractType = [
  {
    value: "Contract",
    label: "Contract",
  },
  {
    value: "Permanent",
    label: "Permanent",
  },
]

export const jobLevel = [
  {
    value: "Intermediate",
    label: "Intermediate",
  },
  {
    value: "Junior",
    label: "Junior",
  },
  {
    value: "Assistant",
    label: "Assistant",
  },
  {
    value: "Lead",
    label: "Lead",
  },
  {
    value: "Senior",
    label: "Senior",
  },
]
export const jobType = [
  {
    value: "Onsite",
    label: "Onsite",
  },
  {
    value: "Remote",
    label: "Remote",
  },
  {
    value: "Hybrid",
    label: "Hybrid",
  },
]
export const salaryType = [
  {
    value: "Monthly",
    label: "Monthly",
  },
  {
    value: "Weekly",
    label: "Weekly",
  },
  {
    value: "Daily",
    label: "Daily",
  },
]

export const status = [
  {
    value: "On Hold",
    label: "On Hold",
  },
  {
    value: "Prequalify",
    label: "Prequalify",
  },
  {
    value: "In Review",
    label: "In Review",
  },
  {
    value: "Forwarded",
    label: "Forwarded",
  },
  {
    value: "Shortlisted",
    label: "Shortlisted",
  },
  {
    value: "HR Review",
    label: "HR Review",
  },
  {
    value: "1st Tech Interview",
    label: "1st Tech Interview",
  },
  {
    value: "2nd Tech Interview",
    label: "2nd Tech Interview",
  },
  {
    value: "3rd Tech Interview",
    label: "3rd Tech Interview",
  },
  {
    value: "Client Discussion",
    label: "Client Discussion",
  },
  {
    value: "Selected",
    label: "Selected",
  },
  {
    value: "Onboarded",
    label: "Onboarded",
  },
  {
    value: "Rejected",
    label: "Rejected",
  },
  {
    value: "Performance Review",
    label: "Performance Review",
  },
]

export const recruitmentProgress = [
  {
    id: 1,
    fullname: "John Doe",
    profession: "UI Designer",
    status: "Tech Interview",
  },

  {
    id: 2,
    fullname: "Ade Wale",
    profession: "Content Designer",
    status: "Task",
  },

  {
    id: 3,
    fullname: "Martins Bello",
    profession: "Node.js Developer",
    status: "Resume review",
  },

  {
    id: 4,
    fullname: "Larry Uber",
    profession: "Product Designer",
    status: "Tech Interview",
  },

  {
    id: 5,
    fullname: "Mercy Chineye",
    profession: "Software Engineer",
    status: "Final Interview",
  },
]

export const newApplicantData = [
  {
    _id: 1,
    image:
      "https://media.istockphoto.com/photos/portrait-of-real-chinese-man-with-serious-expression-picture-id1143002044?k=20&m=1143002044&s=612x612&w=0&h=YWMJRpOU_83DN8kuZVrtpS1fsM80voT5lwf86W9ju2I=",
    fullname: "Lewis S. Lebron",
    appliedposition: "IOS Developer",
  },

  {
    _id: 2,
    image:
      "https://www.thesun.co.uk/wp-content/uploads/2019/09/NINTCHDBPICT000523318026.jpg",
    fullname: "Nelson Davies",
    appliedposition: "Node.js Developer",
  },

  {
    _id: 3,
    image: "https://images.mylawquestions.com/passport-photo.jpg",
    fullname: "Susan Lauren",
    appliedposition: "Marketing Manager",
  },

  {
    _id: 4,
    image:
      "https://cdn.xxl.thumbs.canstockphoto.com/portrait-of-a-beautiful-african-american-woman-outdoors-stock-image_csp61556462.jpg",
    fullname: "Jennifer Peterson",
    appliedposition: "Software Developer",
  },

  {
    _id: 5,
    image:
      "https://www.si.edu/sites/default/files/newsdesk/press_releases/kevin-young_melanie-dunea-color.jpg",
    fullname: "Timothy Watson",
    appliedposition: "Backend Developer",
  },
]

export const jobs = [
  {
    _id: 1,
    title: "Frontend Developer",
    company: {
      name: "Hack Attack",
      logo: "",
    },
    location: {
      state: "Lagos",
      country: "Nigeria",
    },
    applicants: [
      {
        firstName: "",
        lastName: "",
        status: "",
      },
      {
        firstName: "",
        lastName: "",
        status: "",
      },
      {
        firstName: "",
        lastName: "",
        status: "",
      },
      {
        firstName: "",
        lastName: "",
        status: "",
      },
      {
        firstName: "",
        lastName: "",
        status: "",
      },
    ],
  },
  {
    _id: 1,
    title: "Frontend Developer",
    company: {
      name: "Hack Attack",
      logo: "",
    },
    location: {
      state: "Lagos",
      country: "Nigeria",
    },
    applicants: [
      {
        firstName: "",
        lastName: "",
        status: "",
      },
      {
        firstName: "",
        lastName: "",
        status: "",
      },
      {
        firstName: "",
        lastName: "",
        status: "",
      },
      {
        firstName: "",
        lastName: "",
        status: "",
      },
      {
        firstName: "",
        lastName: "",
        status: "",
      },
    ],
  },
]

export const jobContractType = [
  {
    value: "Full Time",
    label: "Full Time",
  },
  {
    value: "Part Time",
    label: "Part Time",
  },
  {
    value: "Internship",
    label: "Internship",
  },
  {
    value: "Contract",
    label: "Contract",
  },
]

export const updateOption = [
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
]

export const jobRoles = [
  {
    value: "Frontend Developer",
    label: "Frontend Developer",
  },
  {
    value: "Backend Developer",
    label: "Backend Developer",
  },
  {
    value: "Product Manager",
    label: "Product Manager",
  },
  {
    value: "Team Lead",
    label: "Team Lead",
  },
]
export const industry = [
  {
    value: "Backend Developer",
    label: "Backend Developer",
  },
  {
    value: "Frontend Developer",
    label: "Frontend Developer",
  },
  {
    value: "Scrum Master",
    label: "Scrum Master",
  },
  {
    value: "Software Engineering",
    label: "Software Engineering",
  },
  {
    value: "Product Manger",
    label: "Product Manager",
  },
  {
    value: "Team Lead",
    label: "Team Lead",
  },
  {
    value: "IT",
    label: "IT",
  },
  {
    value: "Entertainment",
    label: "Entertainment",
  },
  {
    value: "Engineering",
    label: "Engineering",
  },
  {
    value: "Accounting",
    label: "Accounting",
  },
  {
    value: "Agriculture",
    label: "Agriculture",
  },
  {
    value: "Gaming",
    label: "Gaming",
  },
  {
    value: "Manufacturing",
    label: "Manufacturing",
  },
  {
    value: "Medical",
    label: "Medical",
  },
  {
    value: "Gas & Oil",
    label: "Gas & Oil",
  },
  {
    value: "Real Estate",
    label: "Real Estate",
  },
  {
    value: "Services",
    label: "Services",
  },
  {
    value: "Financial",
    label: "Financial",
  },
  {
    value: "Banking",
    label: "Banking",
  },
  {
    value: "Fashion",
    label: "Fashion",
  },
  {
    value: "Art/Craft",
    label: "Art/Craft",
  },
  {
    value: "Dairy",
    label: "Dairy",
  },
  {
    value: "Food/Beverages",
    label: "Hybrid",
  },
  {
    value: "Chemical",
    label: "Chemical",
  },
  {
    value: "Insuarance",
    label: "Insuarance",
  },
]

export const type = [
  {
    value: "Vacation",
    label: "Vacation",
  },
  {
    value: "Sick",
    label: "Sick",
  },
  {
    value: "Wedding",
    label: "Wedding",
  },
  {
    value: "Dead",
    label: "Dead",
  },
]

export const countryOptions = [
  { value: "+234", label: "Nigeria (+234)" },
  { value: "+1", label: "United States (+1)" },
  { value: "+44", label: "United Kingdom (+44)" },
  { value: "+33", label: "France (+33)" },
  // add more options as needed
]

export const currency = [
  { value: "NGN", label: "NGN" },
  { value: "USD", label: "USD" },
  { value: "EUR", label: "EUR" },
  { value: "GBP", label: "GBP" },
  { value: "AUD", label: "AUD" },
  { value: "ARS", label: "ARS" },
  { value: "BRL", label: "BRL" },
  { value: "CAD", label: "CAD" },
  { value: "CLP", label: "CLP" },
  { value: "CNY", label: "CNY" },
  { value: "COP", label: "COP" },
  { value: "CRC", label: "CRC" },
  { value: "CUP", label: "CUP" },
  { value: "EGP", label: "EGP" },
  { value: "ETB", label: "ETB" },
  { value: "ERN", label: "ERN" },
  { value: "GNF", label: "GNF" },
  { value: "GHS", label: "GHS" },
  { value: "GMD", label: "GMD" },
  { value: "ILS", label: "ILS" },
  { value: "JPY", label: "JPY" },
  { value: "KES", label: "KES" },
  { value: "KMF", label: "KMF" },
  { value: "LBP", label: "LBP" },
  { value: "LRD", label: "LRD" },
  { value: "LYD", label: "LYD" },
  { value: "MAD", label: "MAD" },
  { value: "MRO", label: "MRO" },
  { value: "MUR", label: "MUR" },
  { value: "NAD", label: "NAD" },
  { value: "NZD", label: "NZD" },
  { value: "PKR", label: "PKR" },
  { value: "QAR", label: "QAR" },
  { value: "RWF", label: "RWF" },
  { value: "SAR", label: "SAR" },
  { value: "XOF", label: "XOF" },
  { value: "XAF", label: "XAF" },
  { value: "ZAR", label: "ZAR" },
  // Add more currency options as needed
]

export const statusFilters = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "On Hold",
    label: "On Hold",
  },
  {
    value: "Prequalify",
    label: "Prequalify",
  },
  {
    value: "In Review",
    label: "In Review",
  },
  {
    value: "Forwarded",
    label: "Forwarded",
  },
  {
    value: "Shortlisted",
    label: "Shortlisted",
  },
  {
    value: "HR Review",
    label: "HR Review",
  },
  {
    value: "1st Tech Interview",
    label: "1st Tech Interview",
  },
  {
    value: "2nd Tech Interview",
    label: "2nd Tech Interview",
  },
  {
    value: "3rd Tech Interview",
    label: "3rd Tech Interview",
  },
  {
    value: "Client Discussion",
    label: "Client Discussion",
  },
  {
    value: "Selected",
    label: "Selected",
  },
  {
    value: "Onboarded",
    label: "Onboarded",
  },
  {
    value: "Rejected",
    label: "Rejected",
  },
  {
    value: "Performance Review",
    label: "Performance Review",
  },
]

export const job_option = [
  {
    value: "FullStack Developer",
    label: "FullStack Developer",
  },
  {
    value: "Backend Developer",
    label: "Backend Developer",
  },
  {
    value: "Frontend Developer",
    label: "Frontend Developer",
  },
  {
    value: "Scrum Master",
    label: "Scrum Master",
  },
  {
    value: "Software Engineer",
    label: "Software Engineer",
  },
  {
    value: "UI/UX Designer",
    label: "UI/UX Designer",
  },
  {
    value: "React Developer",
    label: "React Developer",
  },
  {
    value: "Frontend Engineer",
    label: "Frontend Engineer",
  },
  {
    value: "Fullstack Engineer",
    label: "Fullstack Engineer",
  },
  {
    value: "Backend Engineer",
    label: "Backend Engineer",
  },
  {
    value: "Node js Developer",
    label: "Node js Developer",
  },
  {
    value: "Angular Developer",
    label: "Angular Developer",
  },
  {
    value: "Vue Developer",
    label: "Vue Developer",
  },
  {
    value: "React Native Developer",
    label: "React Native Developer",
  },
  {
    value: "PHP Laravel Developer",
    label: "PHP Laravel Developer",
  },
  {
    value: "PHP Developer",
    label: "PHP Developer",
  },
  {
    value: "Swift Developer",
    label: "Swift Developer",
  },
  {
    value: "Python Developer",
    label: "Python Developer",
  },
  {
    value: "Q&A Engineer",
    label: "Q&A Engineer",
  },
  {
    value: "QA Engineer",
    label: "QA Engineer",
  },
  {
    value: "Quality Assurance Engineer",
    label: "Quality Asurrance Engineer",
  },
  {
    value: "Software Tester",
    label: "Software Tester",
  },
  {
    value: "DevOps Engineer",
    label: "DevOps Engineer",
  },
  {
    value: "Web Developer",
    label: "Web Developer",
  },
  {
    value: "Mobile Developer",
    label: "Mobile Developer",
  },
  {
    value: "Site Core Developer",
    label: "Site Core Developer",
  },
  {
    value: "Blockchain Developer",
    label: "Blockchain Developer",
  },
  {
    value: "Product Manager",
    label: "Product Manager",
  },
  {
    value: "Project Manager",
    label: "Project Manager",
  },
  {
    value: "Human Resource Manager",
    label: "Human Resource Manager",
  },
  {
    value: "WordPress Developer",
    label: "WordPress Developer",
  },
  {
    value: "Software Developer",
    label: "Software Developer",
  },
  {
    value: "Interaction Designer",
    label: "Interaction Designer",
  },
  {
    value: "Accessibility Specialist",
    label: "Accessibility Specialist",
  },
  {
    value: "Information Architect",
    label: "Information Architect",
  },
  {
    value: "Content Strategist",
    label: "Content Strategist",
  },
  {
    value: "Content Manager",
    label: "Content Manager",
  },
  {
    value: "Growth Hacker",
    label: "Growth Hacker",
  },
  {
    value: "Social Media Manager",
    label: "Social Media Manager",
  },
  {
    value: "Digital Marketing Manager",
    label: "Digital Marketing Manager",
  },
  {
    value: "Web Analytics Developer",
    label: "Web Analytics Developer",
  },
  {
    value: "Marketing Technologist",
    label: "Marketing Technologist",
  },
  {
    value: "Frameworks Specialist",
    label: "Frameworks Specialist",
  },
  {
    value: "Ruby on Rails Developer",
    label: "Ruby on Rails Developer",
  },
  {
    value: "Business Systems Analyst",
    label: "Business Systems Analyst",
  },
  {
    value: "Systems Engineer",
    label: "Systems Engineer",
  },
  {
    value: "Systems Administrator",
    label: "Systems Administrator",
  },
  {
    value: "Database Administrator",
    label: "Database Administrator",
  },
  {
    value: "Data Architect",
    label: "Data Architect",
  },
  {
    value: "Data Modeler",
    label: "Data Modeler",
  },
  {
    value: "Data Analyst",
    label: "Data Analyst",
  },
  {
    value: "Data Scientist",
    label: "Data Scientist",
  },
  {
    value: "Cloud Architect",
    label: "Cloud Architect",
  },
  {
    value: "Technical Lead",
    label: "Technical Lead",
  },
  {
    value: "DevOps Manager",
    label: "DevOps Manager",
  },
  {
    value: "Technical Account Manager",
    label: "Technical Account Manager",
  },
  {
    value: "Security Specialist",
    label: "Security Specialist",
  },
  {
    value: "Game Developer",
    label: "Game Developer",
  },
  {
    value: "Computer Graphics Animator",
    label: "Computer Graphics Animator",
  },
]

export const rights = [
  {
    value: "Viewer",
    label: "Viewer",
  },
  {
    value: "Commenter",
    label: "Commenter",
  },
  {
    value: "Editor",
    label: "Editor",
  },
]
