import { Box, Typography, TextField, Button } from "@mui/material"
import { FaUser } from "react-icons/fa"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/bootstrap.css"
import { titleCase } from "../../utils/helpers"

const Details = ({ formik, user }) => {
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        width: "100%",
        mt: "1rem",
        mx: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        "& .MuiTextField-root": { mt: "1rem" },
        "& .MuiSvgIcon-root": {
          color: "#000000",
        },
      }}
      onSubmit={formik.handleSubmit}
    >
      <Typography variant="body2" sx={{ fontWeight: 600 }}>
        Contact Info
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            position: "relative",
            height: "64px",
            width: "64px",
            borderRadius: "50%",
            backgroundColor: "rgb(209 213 219)",
          }}
        >
          {user?.avatar ? (
            <img
              crossOrigin="anonymous"
              src={`${process.env.REACT_APP_BASE_URL}storage/images/${user?.avatar}`}
              className="w-full h-full object-center rounded-full"
              alt=""
            />
          ) : (
            <FaUser
              size={24}
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white"
            />
          )}
        </Box>

        <Box sx={{ marginLeft: "1rem" }}>
          <Typography variant="body2">{`${titleCase(
            user?.first_name
          )} ${titleCase(user?.last_name)}`}</Typography>
          <Typography variant="body2">{titleCase(user?.job_title)}</Typography>
        </Box>
      </Box>

      <TextField
        disabled
        variant="outlined"
        size="small"
        fullWidth
        id="email"
        label="Email"
        value={formik.values.email}
        InputLabelProps={{ shrink: true }}
      />

      <Box sx={{ mt: "1rem", width: "100%" }}>
        <PhoneInput
          inputProps={{
            id: "phone",
            required: true,
          }}
          inputStyle={{ width: "100%" }}
          buttonStyle={{
            backgroundColor: "#FFFFFF",
            borderRight: "0px",
          }}
          dropdownStyle={{ height: "85px" }}
          country={"ng"}
          value={formik.values.phone_number}
          placeholder="+234 812 345 6789"
          onChange={phone => formik.setFieldValue("phone_number", phone)}
          autoComplete="off"
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
        <Button
          variant="contained"
          type="submit"
          sx={{
            backgroundColor: "#3F51B5",
            mt: "2rem",
            py: "0.5rem",
            px: "1.5rem",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
        >
          NEXT
        </Button>
      </Box>
    </Box>
  )
}

export default Details
