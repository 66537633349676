import { useState } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import { useFormik } from "formik"
import AddApplicantHeader from "../components/AddApplicantHeader"
import AdminLayout from "../components/AdminLayout"
import useAuth from "../hooks/useAuth"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import {
  Personal,
  Details,
  Preview,
  Controls,
  Stepper,
} from "../components/forms/applicant/index"
import { currency } from "../utils/data"
import Swal from "sweetalert2"
import { Helmet } from "react-helmet-async"

const personalSchema = yup.object({
  first_name: yup
    .string("Enter your first name")
    .required("This field is required"),
  last_name: yup
    .string("Select your last name")
    .required("This field is required"),
  email: yup.string("Enter your email").required("This field is required"),

  phone_number: yup
    .string("Enter your phone number")
    .required("This field is required"),
  country: yup.string("Enter your country").required("This field is required"),
  linkedin: yup
    .string("Select your linkedin")
    .required("This field is required"),
  github: yup.string("Select your github").required("This field is required"),
})

const detailsSchema = yup.object({
  resume: yup.mixed().required("Choose a file"),
  cover_letter: yup.mixed().required("Choose a file"),
  position: yup
    .string("Enter your job role")
    .required("This field is required"),
  salary: yup.string("Select your salary").required("This field is required"),
  experience: yup
    .string("Enter your job experience")
    .required("This field is required"),
  notice: yup
    .string("Enter your notice period")
    .required("This field is required"),
  availability: yup.string().required("This field is required"),
  contract_type: yup.string().required("This field is required"),
  status: yup.string().required("This field is required"),
})

const AddApplicant = () => {
  const [selectedCurrency, setSelectedCurrency] = useState(currency[0])
  const steps = ["Personal", "Details", "Preview"]
  const [currentStep, setCurrentStep] = useState(1)
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()

  const request = {
    headers: {
      authorization: `Bearer ${auth.accessToken}`,
    },
  }

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      country: "",
      linkedin: "",
      github: "",
      resume: "",
      cover_letter: "",
      position: "",
      salary: "",
      currency: "NGN",
      experience: "",
      notice: "",
      availability: "",
      contract_type: "",
      status: "",
    },
    validationSchema: currentStep === 2 ? detailsSchema : personalSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      if (currentStep < 3) {
        setCurrentStep(currentStep + 1)
      }

      if (currentStep === 3) {
        if (values.resume.name) {
          const formData = new FormData()
          formData.append("resume", values.resume)
          await axiosPrivate
            .post(`storage/upload-resume`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                authorization: `Bearer ${auth?.accessToken}`,
              },
            })
            .catch(err => console.error(err))
        }

        if (values.cover_letter.name) {
          const formData = new FormData()
          formData.append("coverLetter", values.cover_letter)
          await axiosPrivate
            .post(`storage/upload-coverLetter`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                authorization: `Bearer ${auth?.accessToken}`,
              },
            })
            .catch(err => console.error(err))
        }
        const response = await axiosPrivate
          .post(
            "applicants/",
            {
              company_id: auth?.companyId,
              first_name: values.first_name,
              last_name: values.last_name,
              email: values.email,
              phone_number: values.phone_number,
              country: values.country,
              linkedin: values.linkedin,
              github: values.github,
              resume: values.resume.name,
              cover_letter: values.cover_letter.name,
              job_role: values.position,
              salary: `${values.salary} ${values.currency}`,
              experience: `${
                values.experience === 1
                  ? `${values.experience} year`
                  : `${values.experience} years`
              }`,
              notice_period: `${
                values.notice === 1
                  ? `${values.notice} week`
                  : `${values.notice} weeks`
              }`,
              job_availability: values.availability,
              job_type: values.contract_type,
              status: values.status,
            },
            request
          )
          .catch(error => {
            Swal.fire({
              toast: true,
              icon: "error",
              title: "Oops...",
              text: error?.response?.data?.message,
              position: "top",
              timer: 3000,
              showConfirmButton: false,
            })
          })

        if (response) {
          resetForm()
          Swal.fire({
            toast: true,
            icon: "success",
            title: "New Applicant Added",
            text: response?.data?.message,
            position: "top",
            timer: 3000,
            showConfirmBottom: false,
          })
          navigate("/applicants/")
        }
      }
    },
  })
  const displayStep = step => {
    switch (step) {
      case 1:
        return <Personal formik={formik} />
      case 2:
        return (
          <Details
            formik={formik}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        )
      case 3:
        return (
          <Preview
            formik={formik}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            selectedCurrency={selectedCurrency}
          />
        )
    }
  }

  return (
    <AdminLayout page="applicants/add" role={auth?.role}>
      <Helmet>
        <title>Add Applicant | Edge</title>
      </Helmet>

      <AddApplicantHeader page="add-applicant" />
      <div className="mx-auto rounded-2xl bg-white pb-2 shadow-xl lg:w-3/5 sm:w-3/4 w-full py-6 px-4 sm:my-10 my-0">
        <div>
          <Stepper steps={steps} currentStep={currentStep} />
          <div className="my-4 py-4">{displayStep(currentStep)}</div>
        </div>
      </div>
    </AdminLayout>
  )
}

export default AddApplicant
