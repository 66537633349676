import {
  availability,
  currency,
  jobContractType,
  status,
} from "../../utils/data"
import { FaCloudUploadAlt } from "react-icons/fa"
import { Box, TextField, MenuItem, Button } from "@mui/material"
import { titleCase } from "../../utils/helpers"

const Details = ({ formik, currentStep, setCurrentStep }) => {
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        width: "100%",
        mt: "1rem",
        mx: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // "& .MuiTextField-root": { mt: "1rem" },
        "& .MuiSvgIcon-root": {
          color: "#000000",
        },
      }}
      onSubmit={formik.handleSubmit}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",

          width: "100%",
        }}
      >
        {/**resume*/}
        <Box sx={{ width: "49%" }}>
          <input
            type="file"
            id="resume"
            accept=".doc, .docx, .pdf"
            onChange={e => {
              formik.setFieldValue("resume", e.currentTarget.files[0])
            }}
            hidden
          />

          <label
            htmlFor="resume"
            className={`${
              Boolean(formik.errors.resume)
                ? "border-[#d32f2f]"
                : "border-[#cccccc]"
            } bg-white border rounded-[4px]  mt-4 text-black text-base w-full cursor-pointer flex items-center justify-between`}
          >
            <span className="py-[8.5px] px-[14px] text-sm">
              {formik.values.resume?.name
                ? formik.values.resume?.name
                : formik.values.resume
                ? formik.values.resume
                : "Choose a file..."}
            </span>
            <span className="flex items-center justify-end bg-gray-100 border-l border-[#cccccc] py-[8.5px] px-[14px]">
              <FaCloudUploadAlt style={{ marginRight: "8px" }} />
              <p className="text-sm">Upload Resume</p>
            </span>
          </label>

          {Boolean(formik.errors.resume) && (
            <p className="text-[#d32f2f] text-xs my-1 ml-[14px] tracking-[0.0333em]">
              {formik.touched.resume && formik.errors.resume}
            </p>
          )}
        </Box>

        {/**cover letter */}
        <Box sx={{ width: "49%" }}>
          <input
            type="file"
            id="cover_letter"
            accept=".doc, .docx, .pdf"
            onChange={e =>
              formik.setFieldValue("cover_letter", e.currentTarget.files[0])
            }
            hidden
          />

          <label
            htmlFor="cover_letter"
            className={`${
              Boolean(formik.errors.cover_letter)
                ? "border-[#d32f2f]"
                : "border-[#cccccc]"
            } bg-white border rounded-[4px] mt-4 text-black text-base w-full cursor-pointer flex items-center justify-between`}
          >
            <span className="py-[8.5px] px-[14px] text-sm">
              {formik.values.cover_letter?.name
                ? formik.values.cover_letter?.name
                : formik.values.cover_letter
                ? formik.values.cover_letter
                : "Choose a file..."}
            </span>
            <span className="flex items-center justify-end bg-gray-100 border-l border-[#cccccc] py-[8.5px] px-[14px]">
              <FaCloudUploadAlt style={{ marginRight: "8px" }} />
              <p className="text-xs">Upload Cover Letter</p>
            </span>
          </label>

          {Boolean(formik.errors.cover_letter) && (
            <p className="text-[#d32f2f] text-xs my-1 ml-[14px] tracking-[0.0333em]">
              {formik.touched.cover_letter && formik.errors.cover_letter}
            </p>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "1rem",
          width: "100%",
        }}
      >
        {/**job_role */}
        <Box sx={{ width: "49%" }}>
          <TextField
            required
            variant="outlined"
            fullWidth
            size="small"
            id="position"
            label="Position Applied For"
            value={formik.values.position}
            onChange={formik.handleChange("position")}
            error={formik.touched.position && Boolean(formik.errors.position)}
            helperText={formik.touched.position && formik.errors.position}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        <Box
          sx={{
            width: "49%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/**currency */}
          <Box sx={{ width: "39%" }}>
            <TextField
              required
              variant="outlined"
              fullWidth
              size="small"
              id="currency"
              label="Currency"
              defaultValue="NGN"
              value={formik.values.currency}
              onChange={formik.handleChange("currency")}
              select
              SelectProps={{ MenuProps: { disableScrollLock: true } }}
              error={formik.touched.currency && Boolean(formik.errors.currency)}
              helperText={formik.touched.currency && formik.errors.currency}
              InputLabelProps={{ shrink: true }}
              placeholder={formik.values.currency}
            >
              {currency.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box sx={{ width: "59%" }}>
            <TextField
              required
              variant="outlined"
              fullWidth
              size="small"
              id="salary"
              label="Salary Expectation"
              value={formik.values.salary}
              onChange={formik.handleChange("salary")}
              error={formik.touched.salary && Boolean(formik.errors.salary)}
              helperText={formik.touched.salary && formik.errors.salary}
              InputLabelProps={{ shrink: true }}
            />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "1rem",
          width: "100%",
        }}
      >
        {/**experience */}
        <Box sx={{ width: "49%" }}>
          <TextField
            required
            variant="outlined"
            fullWidth
            size="small"
            id="experience"
            label="Total Work Experience (years)"
            value={formik.values.experience}
            onChange={formik.handleChange("experience")}
            error={
              formik.touched.experience && Boolean(formik.errors.experience)
            }
            helperText={formik.touched.experience && formik.errors.experience}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        {/**github */}
        <Box sx={{ width: "49%" }}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            id="notice"
            label="Notice Period (weeks)"
            value={formik.values.notice}
            onChange={formik.handleChange("notice")}
            error={formik.touched.notice && Boolean(formik.errors.notice)}
            helperText={formik.touched.notice && formik.errors.notice}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "1rem",
          width: "100%",
        }}
      >
        {/**availability */}
        <Box sx={{ width: "49%" }}>
          <TextField
            required
            variant="outlined"
            fullWidth
            size="small"
            id="availability"
            select
            SelectProps={{ MenuProps: { disableScrollLock: true } }}
            label="Employment Type"
            value={titleCase(formik.values.availability)}
            onChange={formik.handleChange("availability")}
            error={
              formik.touched.availability && Boolean(formik.errors.availability)
            }
            helperText={
              formik.touched.availability && formik.errors.availability
            }
            InputLabelProps={{ shrink: true }}
          >
            {availability.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        {/**contract_type */}
        <Box sx={{ width: "49%" }}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            id="contract_type"
            select
            SelectProps={{ MenuProps: { disableScrollLock: true } }}
            label="Contract Type"
            value={titleCase(formik.values.contract_type)}
            onChange={formik.handleChange("contract_type")}
            error={
              formik.touched.contract_type &&
              Boolean(formik.errors.contract_type)
            }
            helperText={
              formik.touched.contract_type && formik.errors.contract_type
            }
            InputLabelProps={{ shrink: true }}
          >
            {jobContractType.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>

      <Box
        sx={{
          mt: "1rem",
          width: "100%",
        }}
      >
        {/**status */}
        <Box sx={{ width: "100%" }}>
          <TextField
            required
            variant="outlined"
            fullWidth
            size="small"
            id="status"
            select
            SelectProps={{ MenuProps: { disableScrollLock: true } }}
            label="Status"
            value={titleCase(formik.values.status)}
            onChange={formik.handleChange("status")}
            error={formik.touched.status && Boolean(formik.errors.status)}
            helperText={formik.touched.status && formik.errors.status}
            InputLabelProps={{ shrink: true }}
          >
            {status.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>

      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#3F51B5",
            mt: "1rem",
            py: "0.5rem",
            px: "1.5rem",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
          onClick={() => {
            setCurrentStep(currentStep - 1)
          }}
        >
          PREV
        </Button>

        <Button
          variant="contained"
          type="submit"
          sx={{
            backgroundColor: "#3F51B5",
            mt: "1rem",
            py: "0.5rem",
            px: "1.5rem",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
        >
          SUBMIT
        </Button>
      </Box>
    </Box>
  )
}

export default Details
