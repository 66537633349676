import { useState } from "react"
import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import * as yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert2"
import { FiX } from "react-icons/fi"
import Details from "./Details"
import Stepper from "./Stepper"
import Screening from "./Screening"
import About from "./About"
import Preview from "./Preview"
import { titleCase } from "../../utils/helpers"
import useAuth from "../../hooks/useAuth"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", sm: "50%" },
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: "0px",
  boxShadow: 24,
  px: 4,
  py: 2,
  outline: "none",
  overflow: "auto",
}

const detailsSchema = yup.object({
  company_logo: yup.mixed().required("Choose a file"),
  job_title: yup
    .string("Enter your job's title")
    .required("This field is required"),
  job_level: yup
    .string("Select your job's level")
    .required("This field is required"),
  company_name: yup
    .string("Enter your job's company name")
    .required("This field is required"),
  job_country: yup
    .string("Enter your job's country")
    .required("This field is required"),
  job_city: yup
    .string("Enter your job's location city")
    .required("This field is required"),
  contract_type: yup
    .string("Select your job's contract type")
    .required("This field is required"),
  employment_type: yup
    .string("Select your job's employment type")
    .required("This field is required"),
  openings: yup
    .number("Enter your job's number of openings")
    .required("This field is required"),
})

const aboutSchema = yup.object({
  currency: yup.string("Enter currency"),
  min_salary_range: yup
    .string("Enter minimum salary range"),
  max_salary_range: yup
    .string("Enter maximum salary range"),
  application_deadline: yup
    .date("Enter your job's application deadline")
    .required("This field is required"),
  receive_updates: yup
    .string("Select whether to receieve updates for this job")
    .required("This field is required"),
})

const EditJob = ({ data, setIsOpen, isOpen, salaryRange }) => {
  const [screeningQuestions, setScreeningQuestions] = useState(
    data?.screeningQuestions
  )
  const [jobDescription, setJobDescription] = useState(
    data?.job.job_description
  )

  const [companyLogo, setCompanyLogo] = useState(data?.job.company_logo)
  const steps = ["Details", "About", "Screening", "Preview"]
  const [currentStep, setCurrentStep] = useState(1)

  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const request = {
    headers: {
      authorization: `Bearer ${auth.accessToken}`,
    },
  }

  const formik = useFormik({
    initialValues: {
      company_logo: data.job.company_logo,
      job_title: titleCase(data.job.job_title),
      job_level: titleCase(data.job.job_level),
      company_name: titleCase(data.job.company_name),
      job_country: titleCase(data.job.job_country),
      job_city: titleCase(data.job.job_city),
      contract_type: titleCase(data.job.contract_type),
      employment_type: titleCase(data.job.employment_type),
      openings: titleCase(data.job.openings),
      currency: data.job.currency.toUpperCase(),
      min_salary_range: salaryRange?.min_range,
      max_salary_range: salaryRange?.max_range,
      application_deadline: titleCase(data.job.application_deadline),
      receive_updates:
        data.job.receive_updates === null
          ? "No"
          : titleCase(data.job.receive_updates),
    },
    validationSchema: currentStep === 2 ? aboutSchema : detailsSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      if (currentStep < 4) {
        setCurrentStep(currentStep + 1)
      }

      if (currentStep === 4) {
        if (typeof values.company_logo === "object") {
          const formData = new FormData()
          formData.append("file", values.company_logo)
          const res = await axiosPrivate
            .post(`storage/upload-image`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                authorization: `Bearer ${auth.accessToken}`,
              },
            })
            .catch(err => console.error(err))
          if (res) {
            const response = await axiosPrivate
              .put(
                `jobs/${data.job.id}`,
                {
                  job_title: values.job_title,
                  job_level: values.job_level,
                  company_name: values.company_name,
                  company_logo: res.data.data.filename,
                  job_country: values.job_country,
                  job_city: values.job_city,
                  contract_type: values.contract_type,
                  employment_type: values.employment_type,
                  openings: values.openings,
                  job_description: jobDescription,
                  currency: values.currency,
                  salary_range: `${values.min_salary_range}  - ${values.max_salary_range}`,
                  application_deadline: values.application_deadline,
                  receive_updates: values.receive_updates,
                  job_screening: screeningQuestions,
                },
                request
              )
              .catch(error => {
                // console.error(error.response.data.message)
                Swal.fire({
                  toast: true,
                  icon: "error",
                  title: error.response.data.message,
                  position: "top",
                  timer: 3000,
                  showConfirmButton: false,
                })
              })
            if (response) {
              setIsOpen(false)
              resetForm()
              Swal.fire({
                toast: true,
                icon: "success",
                title: "Your job has been edited successfully.",
                position: "top",
                timer: 3000,
                showConfirmButton: false,
              })
            }
          }
        } else {
          const response = await axiosPrivate
            .put(
              `jobs/${data.job.id}`,
              {
                job_title: values.job_title,
                job_level: values.job_level,
                company_name: values.company_name,
                company_logo: values.company_logo,
                job_country: values.job_country,
                job_city: values.job_city,
                contract_type: values.contract_type,
                employment_type: values.employment_type,
                openings: values.openings,
                job_description: jobDescription,
                currency: values.currency,
                salary_range: `${values.min_salary_range}  - ${values.max_salary_range}`,
                application_deadline: values.application_deadline,
                receive_updates: values.receive_updates,
                job_screening: screeningQuestions,
              },
              request
            )
            .catch(error => {
              // console.error(error.response.data.message)
              Swal.fire({
                toast: true,
                icon: "error",
                title: error.response.data.message,
                position: "top",
                timer: 3000,
                showConfirmButton: false,
              })
            })
          if (response) {
            setIsOpen(false)
            resetForm()
            Swal.fire({
              toast: true,
              icon: "success",
              title: "Your job has been edited successfully.",
              position: "top",
              timer: 3000,
              showConfirmButton: false,
            })
          }
        }
      }
    },
  })

  const displayStep = step => {
    switch (step) {
      case 1:
        return (
          <Details
            formik={formik}
            companyLogo={companyLogo}
            setCompanyLogo={setCompanyLogo}
          />
        )
      case 2:
        return (
          <About
            formik={formik}
            jobDescription={jobDescription}
            setJobDescription={setJobDescription}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        )
      case 3:
        return (
          <Screening
            screeningQuestions={screeningQuestions}
            setScreeningQuestions={setScreeningQuestions}
            jobId={data?.job.id}
            formik={formik}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        )
      case 4:
        return (
          <Preview
            formik={formik}
            screeningQuestions={screeningQuestions}
            jobDescription={jobDescription}
            companyLogo={companyLogo}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        )
      default:
    }
  }

  return (
    <div className="flex justify-center items-center py-6 px-16">
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="modal-modal-title"
        disableScrollLock
      >
        <Box sx={style}>
          <Box sx={{ position: "relative" }}>
            <IconButton
              sx={{ position: "fixed", top: "0px", right: "0px" }}
              onClick={() => {
                setIsOpen(false)
                // navigate(0)
              }}
            >
              <FiX />
            </IconButton>
          </Box>

          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", fontWeight: "700" }}
          >
            Edit Job
          </Typography>

          <Box>
            <Stepper steps={steps} currentStep={currentStep} />
            <div className="my-4 py-4">{displayStep(currentStep)}</div>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default EditJob
