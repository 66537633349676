import { useState } from "react"
import { FaUser } from "react-icons/fa"
import { getFileExt, titleCase } from "../../utils/helpers"
import { MenuItem, Select, Typography } from "@mui/material"
import { FiDownload, FiShare2 } from "react-icons/fi"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import { status } from "../../utils/data"
import ShareApplicationModal from "./ShareApplicationModal"
import AccessControl from "../accessControl/AccessControl"
import axios from "../../api/axios"

const JobApplicationDetails = ({
  item,
  screeningQuestions,
  setApplicantStatus,
  applicantStatus,
  jobId,
  auth,
  permissions,
  refetch,
  refetchApplicationEmail,
}) => {
  const axiosPrivate = useAxiosPrivate()
  const [showModal, setShowModal] = useState(false)

  const onStatusChange = async event => {
    event.preventDefault()

    const res = await axios.put(`applications/status/${item.application_id}`, {
      name: auth?.firstName ? auth?.firstName : "guest",
      status: event.target.value,
    })

    if (res) {
      setApplicantStatus(event.target.value)
    }
    refetch()
  }

  return (
    <div className="px-6 py-6 space-y-4 text-black">
      {showModal && (
        <ShareApplicationModal
          showModal={showModal}
          setShowModal={setShowModal}
          item={item}
          jobId={jobId}
          permissions={permissions}
          refetch={refetchApplicationEmail}
        />
      )}

      <div className="flex justify-between items-start">
        <div className="flex flex-col sm:flex-row">
          <div
            className={`${
              item?.avatar ? "bg-white" : "bg-gray-300"
            } w-16 h-16 rounded-full mr-4 relative`}
          >
            {item?.avatar ? (
              <img
                crossOrigin="anonymous"
                src={`${process.env.REACT_APP_BASE_URL}storage/images/${item?.avatar}`}
                className="w-full h-full object-center object-contain  rounded-full"
                alt=""
              />
            ) : (
              <FaUser
                size={24}
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white"
              />
            )}
          </div>
          <div className="flex flex-col">
            <p>{`${titleCase(item?.first_name)} ${titleCase(
              item?.last_name
            )}`}</p>
            <p>{item?.email}</p>
            <p>{item?.phone_number}</p>
            <Select
              size="small"
              value={applicantStatus !== "All" ? applicantStatus : item?.status}
              disabled={permissions === "guest" ? true : false}
              sx={{
                fontSize: "14px",
                boxShadow: "0px 2px 4px -2px rgb(0 0 0 / 0.1)",
                mt: "0.5rem",
              }}
              onChange={e => {
                onStatusChange(e)
              }}
            >
              {status.map((item, index) => (
                <MenuItem
                  value={item.value}
                  key={index}
                  sx={{ fontSize: "12px" }}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>

        <AccessControl
          userPermission={permissions ? permissions : auth?.role}
          allowedPermissions={["superAdmin", "admin", "contributor"]}
        >
          <button
            title="Share application page"
            className="py-2 px-6 rounded-full shadow text-slate-700 bg-sky-100 hover:bg-sky-200 flex items-center text-center gap-2 text-sm"
            onClick={() => {
              setShowModal(true)
            }}
          >
            <FiShare2 />
            <p className="hidden sm:block">Share</p>
          </button>
        </AccessControl>
      </div>

      {/**resume */}
      <div className="flex rounded-lg relative border border-gray-200 h-16 mt-5 sm:w-auto w-full">
        {getFileExt(item?.resume) === "PDF" ? (
          <div className="bg-red-700 rounded-l-lg text-white flex items-center justify-center text-xs px-2">
            <Typography variant="body2">{getFileExt(item?.resume)}</Typography>
          </div>
        ) : (
          <div className="bg-blue-700 rounded-l-lg text-white flex items-center justify-center text-xs px-2">
            <Typography variant="body2">{getFileExt(item?.resume)}</Typography>
          </div>
        )}

        <Typography
          variant="body2"
          sx={{ display: "flex", alignItems: "center", px: "0.75rem" }}
        >
          {item?.resume}
        </Typography>

        <a
          title="Download CV"
          href={`${process.env.REACT_APP_BASE_URL}storage/files/${item?.resume}`}
          target="_blank"
          rel="noreferrer"
          className="absolute right-2 top-0 bottom-0 my-auto flex items-center justify-end px-1.5"
        >
          <FiDownload size={24} className="mr-2" />
        </a>
      </div>

      {/**screening questions and answers */}
      <div className="mt-5">
        {screeningQuestions?.map((question, index) => (
          <div key={index} className="flex flex-col mb-2.5">
            {question.screening_question_group === "tool or technology" ? (
              <p>{`How many years of experience do you have using ${titleCase(
                question.screening_question_value
              )}?`}</p>
            ) : null}

            {question.screening_question_group === "language" ? (
              <p>{`What is your level of proficiency in ${titleCase(
                question.screening_question_value
              )}?`}</p>
            ) : null}

            {question.screening_question_group === "industry" ? (
              <p>{`How many years of ${titleCase(
                question.screening_question_value
              )} experience do you currently have?`}</p>
            ) : null}

            {question.screening_question_group === "custom" && (
              <p>{question.screening_question_value}</p>
            )}

            {item?.answers.length > 0 && <p>{item?.answers[index].answers}</p>}
          </div>
        ))}
      </div>
    </div>
  )
}

export default JobApplicationDetails
