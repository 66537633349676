import CandidateLayout from "../components/CandidateLayout"
import React, { useState } from "react"
import UserSettingsHeader from "../components/UserSettingsHeader"
import * as yup from "yup"
import { useFormik } from "formik"
import { useNavigate } from "react-router-dom"
import axios from "../api/axios"
import Swal from "sweetalert2"
import useAuth from "../hooks/useAuth"
import { Box } from "@mui/material"
import usePasswordToggle from "../hooks/usePasswordToggle"
import { BsEye, BsEyeSlash } from "react-icons/bs"
import { Helmet } from "react-helmet-async"

const validationSchema = yup.object({
  current_password: yup
    .string("Enter your current password")
    .required("This field is required"),
  new_password: yup
    .string("Enter your new password")
    .required("This field is required")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
  confirm_password: yup
    .string("Confirm your password")
    .required("This field is required")
    .oneOf([yup.ref("new_password"), null], "Passwords don't match."),
})

const UserSettings = () => {
  const [showPassword, setShowPassword] = useState("false")
  const [toggleOn, toggleOff] = useState("false")
  const navigate = useNavigate()
  const { auth } = useAuth()

  const formik = useFormik({
    initialValues: {
      current_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      const res = await axios
        .put(`users/change-password/${auth?.id}`, {
          current_password: values.current_password,
          new_password: values.new_password,
          confirm_password: values.confirm_password,
        })
        .catch(err => {
          Swal.fire({
            toast: true,
            icon: "error",
            title: err.response.data.message,
            position: "top",
            timer: 3000,
            showConfirmButton: false,
          })
        })
      if (res.status === 201) {
        resetForm()
        Swal.fire({
          toast: true,
          icon: "success",
          title: "Password has been updated successfully",
          position: "top",
          timer: 3000,
          showConfirmButton: false,
        })
        navigate("/candidate-dashboard")
      }
    },
  })

  const show = showPassword ? (
    <BsEyeSlash
      color=" #1E3A8A"
      size={18}
      icon={showPassword ? "eye-slash" : "eye"}
      onClick={() => setShowPassword(visibility => !visibility)}
    />
  ) : (
    <BsEye
      color=" #1E3A8A"
      size={18}
      icon={showPassword ? "eye-slash" : "eye"}
      onClick={() => setShowPassword(visibility => !visibility)}
    />
  )

  const turnOn = toggleOn ? (
    <BsEyeSlash
      color=" #1E3A8A"
      size={18}
      icon={toggleOff ? "eye-slash" : "eye"}
      onClick={() => toggleOff(visibility => !visibility)}
    />
  ) : (
    <BsEye
      color=" #1E3A8A"
      size={18}
      icon={toggleOff ? "eye-slash" : "eye"}
      onClick={() => toggleOff(visibility => !visibility)}
    />
  )

  const [PasswordInputType, ToggleIcon] = usePasswordToggle()

  return (
    <CandidateLayout page="settings">
      <Helmet>
        <title>Settings | Edge</title>
      </Helmet>

      <div className="min-h-screen">
        <UserSettingsHeader page="user-settings" />

        <div className="px-12 py-6 space-y-4">
          <div className="bg-white py-6 px-4 rounded-md shadow space-y-3">
            <span className=" text-black font-semibold text-xl">Settings</span>
          </div>

          <div className="bg-white py-6 px-4 rounded-md shadow space-y-3">
            <div className="space-y-3">
              <span className="text-black font-semibold text-xl">
                Change Password
              </span>
            </div>

            <form onSubmit={formik.handleSubmit} className="flex flex-col px-4">
              {/**current password */}
              <div>
                <label
                  htmlFor="current_password"
                  className="block text-gray-600 text-base"
                >
                  Current Password
                </label>
                <div className="relative md:w-1/3 w-full">
                  <input
                    type={toggleOn ? "password" : "text"}
                    className="text-black bg-indigo-50 px-4 py-2 outline-none rounded-md w-full text-base"
                    name="current_password"
                    value={formik.values.current_password}
                    onChange={formik.handleChange("current_password")}
                    onBlur={formik.handleBlur}
                    placeholder="Current Password"
                    id="current_password"
                    autoComplete="off"
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      right: "16px",
                      top: "16px",
                      cursor: "pointer",
                    }}
                  >
                    {turnOn}
                  </Box>
                </div>
                <div className="text-danger text-red-600 text-sm">
                  {formik.touched.current_password &&
                    formik.errors.current_password}
                </div>
              </div>

              {/**new password */}
              <div className="mt-2">
                <label
                  htmlFor="new_password"
                  className="block text-gray-600 text-base"
                >
                  New Password
                </label>
                <div className="relative md:w-1/3 w-full">
                  <input
                    type={showPassword ? "password" : "text"}
                    className="text-black bg-indigo-50 px-4 py-2 outline-none rounded-md w-full text-base"
                    name="new_password"
                    value={formik.values.new_password}
                    onChange={formik.handleChange("new_password")}
                    onBlur={formik.handleBlur}
                    placeholder="New Password"
                    id="new_password"
                    autoComplete="off"
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      right: "16px",
                      top: "16px",
                      cursor: "pointer",
                    }}
                  >
                    {show}
                  </Box>
                </div>
                <div className="text-danger text-red-600 text-sm">
                  {formik.touched.new_password && formik.errors.new_password}
                </div>
              </div>

              {/**confirm password */}
              <div className="mt-2">
                <label
                  htmlFor="confirm_password"
                  className="block text-gray-600 text-base"
                >
                  Confirm Password
                </label>
                <div className="relative md:w-1/3 w-full">
                  <input
                    type={PasswordInputType}
                    className="text-black bg-indigo-50 px-4 py-2 outline-none rounded-md w-full text-base"
                    name="confirm_password"
                    value={formik.values.confirm_password}
                    onChange={formik.handleChange("confirm_password")}
                    onBlur={formik.handleBlur}
                    placeholder="Confirm Password"
                    id="confirm_password"
                    autoComplete="off"
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      right: "16px",
                      top: "16px",
                      cursor: "pointer",
                    }}
                  >
                    {ToggleIcon}
                  </Box>
                </div>
                <div className="text-danger text-red-600 text-sm">
                  {formik.touched.confirm_password &&
                    formik.errors.confirm_password}
                </div>
              </div>

              <div className="mt-2">
                <button
                  type="submit"
                  className="w-1/3 bg-blue-900 font-semibold py-2 rounded-md text-base tracking-wide"
                  onClick={e => {
                    formik.handleSubmit(e)
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </CandidateLayout>
  )
}

export default UserSettings
