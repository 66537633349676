import { useState } from "react";
import { Box, Button, IconButton, Modal } from "@mui/material";
import { FiX } from "react-icons/fi";
import { BiLoaderAlt } from "react-icons/bi";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const options = {
  cMapUrl: "cmaps/",
  standardFontDataUrl: "standard_fonts/",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: { xs: "75%", sm: "37.5%", lg: "29%", xl: "23%" },
  maxWidth: "90%",
  minHeight: "25vh",
  maxHeight: "95vh",
  bgcolor: "background.paper",
  color: "#000000",
  border: "0px",
  boxShadow: 24,
  outline: "none",
  overflow: "auto",
};

const ViewResumeModal = ({ showModal, setShowModal, resume }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);

  const onDocumentLoadSuccess = (doc) => {
    setNumPages(doc.numPages);
  };

  const onPageLoadSuccess = (page) => {
    const parentDiv = document.querySelector("#container");

    if (parentDiv.clientWidth < 425) {
      let pageScale = parentDiv.clientWidth / page.originalWidth;
      if (scale !== pageScale) {
        setScale(pageScale);
      }
    } else {
      let pageScale = parentDiv.clientHeight / page.originalHeight;
      if (scale !== pageScale) {
        setScale(pageScale);
      }
    }
  };

  const nextPage = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const prevPage = () => {
    setPageNumber((prevPageNumber) => prevPageNumber - 1);
  };

  return (
    <Modal
      open={showModal}
      aria-labelledby="modal-modal-title"
      disableScrollLock
    >
      <Box sx={style} id="container">
        <IconButton
          sx={{ position: "absolute", top: "4px", right: "4px", zIndex: 50 }}
          onClick={() => {
            setShowModal(false);
          }}
        >
          <FiX />
        </IconButton>

        <Document
          file={`${process.env.REACT_APP_BASE_URL}storage/files/${resume}`}
          options={options}
          loading={() => {
            return (
              <div className="w-full h-[25vh] flex items-center justify-center my-auto">
                <BiLoaderAlt size={28} className="animate-spin" />
              </div>
            );
          }}
          error={() => {
            return (
              <div className="w-full h-[25vh] flex items-center justify-center my-auto">
                <p>Failed to load PDF file.</p>
              </div>
            );
          }}
          onSourceSuccess={() => console.log("Document source retrieved!")}
          onSourceError={(err) => console.error(err.message)}
          onLoadError={(err) => console.error(err.message)}
          onLoadSuccess={(doc) => onDocumentLoadSuccess(doc)}
        >
          <Page
            pageNumber={pageNumber}
            scale={scale}
            onLoadSuccess={(page) => onPageLoadSuccess(page)}
          />
        </Document>

        <div
          className={`${
            numPages ? "flex" : "hidden"
          } absolute bottom-2 w-full justify-between z-50 px-2`}
        >
          <Button
            disabled={pageNumber <= 1}
            onClick={() => {
              prevPage();
            }}
            sx={{ cursor: "pointer" }}
          >
            Prev
          </Button>

          <p className="cursor-default">-{pageNumber}-</p>

          <Button
            disabled={pageNumber >= numPages}
            onClick={() => {
              nextPage();
            }}
            sx={{ cursor: "pointer" }}
          >
            Next
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default ViewResumeModal;
