import React, { useState, useEffect } from "react"
import {
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom"
import { Tabs, Tab } from "@mui/material"
import AdminLayout from "../components/AdminLayout"
import AdminHeader from "../components/AdminHeader"
import useAuth from "../hooks/useAuth"
import AppCard from "../components/AppCard"
import { FaLongArrowAltLeft } from "react-icons/fa"
import { BiSortAlt2 } from "react-icons/bi"
import { statusFilters } from "../utils/data"
import { Select, MenuItem, InputAdornment } from "@mui/material"
import { FiShare2 } from "react-icons/fi"
import { useQuery } from "react-query"
import useFetcherPrivate from "../hooks/useFetcherPrivate"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import CommentSection from "../components/comment/CommentSection"
import JobApplicationDetails from "../components/JobApplications/JobApplicationDetails"
import { Helmet } from "react-helmet-async"
import ViewResumeModal from "../components/ViewResumeModal"
import AccessControl from "../components/accessControl/AccessControl"
import axios from "../api/axios"
import { titleCase } from "../utils/helpers"
import ShareCvsModal from "../components/ShareCvs/ShareCvs"
import ApplicationStatusHistory from "../components/JobApplications/ApplicationStatusHistory"
import SharedCardsHistory from "../components/JobApplications/SharedCardsHistory"

const JobApplications = () => {
  const [page, setPage] = useState(1)
  const [sortedBy, setSortedBy] = useState("Most Recent")
  const [applicantStatus, setApplicantStatus] = useState("All")
  const [item, setItem] = useState()
  const [resume, setResume] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  let params = useParams()
  let searchParams = useSearchParams()
  let location = useLocation()
  const { auth } = useAuth()
  const navigate = useNavigate()
  const fetcherPrivate = useFetcherPrivate()

  const qid = location.state?.qid
    ? location.state?.qid
    : searchParams[0].get("qid")
  const permissions = searchParams[0].get("permissions")
  const status = searchParams[0].get("status")

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const request = {
      signal: controller.signal,
    }

    if (qid) {
      const fetchData = async () => {
        const res = await axios
          .get(`applications/applicant/${qid}`, request)
          .catch(error => {
            console.error(error?.response)
          })

        if (res) {
          isMounted && setItem(res?.data?.data)
          isMounted && setPage(2)
        }
      }

      fetchData()
    }

    if (status) {
      isMounted && setApplicantStatus(titleCase(status))
    }

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [qid, status])

  const { data: screeningQuestions } = useQuery(["screening", params.id], () =>
    fetcherPrivate(`jobs/screening/${params?.id}`, auth?.accessToken)
  )

  const {
    data: applications,
    isError,
    isLoading,
    isSuccess,
  } = useQuery(["applications", params.id, applicantStatus], () =>
    fetcherPrivate(
      `applications/applicants/${params.id}?status=${applicantStatus}`
    )
  )

  const {
    data: applicationEmail,
    isSuccess: isApplicationEmailSuccess,
    refetch: refetchApplicationEmail,
  } = useQuery(["applicationemail", item?.application_id, showModal], () =>
    fetcherPrivate(`share/guestDetails/${item?.application_id}`)
  )

  const {
    data: applicationStatusHistory,
    isSuccess: isApplicationStatusHistorySuccess,
    refetch: refetchApplicationStatusHistory,
  } = useQuery(["applicationStatusHistory", item?.application_id], () =>
    fetcherPrivate(
      `applications/history/${item?.application_id}`,
      auth?.accessToken
    )
  )

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const request = {
      signal: controller.signal,
    }

    if (qid) {
      const fetchData = async () => {
        const res = await axios
          .get(`applications/applicant/${qid}`, request)
          .catch(error => {
            console.error(error?.response)
          })

        if (res) {
          isMounted && setItem(res?.data?.data)
          isMounted && setPage(2)
        }
      }

      fetchData()
    }

    if (status) {
      isMounted && setApplicantStatus(titleCase(status))
    }

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [qid, status])

  return (
    <AdminLayout page="jobs" role={permissions ? permissions : auth?.role}>
      <Helmet>
        <title>Applications | Edge</title>
      </Helmet>

      <AccessControl
        userPermission={permissions ? permissions : auth?.role}
        allowedPermissions={["superAdmin", "admin"]}
      >
        <AdminHeader page="manage-jobs" />
      </AccessControl>

      <div className="xl:px-12 sm:px-6 px-4 py-6 space-y-4 w-full">
        <AccessControl
          userPermission={permissions ? permissions : auth?.role}
          allowedPermissions={["superAdmin", "admin"]}
        >
          <button
            onClick={() => {
              if (page === 2) {
                setPage(1)
              } else {
                navigate(-1)
              }
            }}
            className="flex py-1.5 px-6 text-black items-center hover:bg-gray-200 hover:w-1/5 hover:rounded-2xl"
          >
            <FaLongArrowAltLeft className="mr-2" />
            Go back
          </button>
        </AccessControl>

        {page === 1 && (
          <div className="bg-white py-6 px-6 rounded-md shadow space-y-8">
            {showModal && (
              <ViewResumeModal
                showModal={showModal}
                resume={resume}
                setShowModal={setShowModal}
              />
            )}
            {openModal && (
              <ShareCvsModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                applicantStatus={applicantStatus}
                jobId={params.id}
              />
            )}

            <div className="flex sm:flex-row sm:space-x-6 sm:space-y-0 sm:items-center flex-col space-y-6">
              <p className="text-lg font-medium text-black">Applications</p>

              {/* filters */}
              <div className="flex sm:flex-row sm:space-x-4 sm:space-y-0 flex-col space-y-4 items-center">
                <Select
                  size="small"
                  value={sortedBy}
                  startAdornment={
                    <InputAdornment position="start">
                      <BiSortAlt2 />
                    </InputAdornment>
                  }
                  sx={{
                    fontSize: "14px",
                    boxShadow: "0px 2px 4px -2px rgb(0 0 0 / 0.1)",
                  }}
                  onChange={e => {
                    setSortedBy(e.target.value)
                  }}
                  MenuProps={{
                    MenuListProps: { sx: { padding: "0px" } },
                  }}
                >
                  <MenuItem value="Most Recent">Most Recent</MenuItem>
                </Select>

                <Select
                  size="small"
                  value={applicantStatus}
                  sx={{
                    fontSize: "14px",
                    boxShadow: "0px 2px 4px -2px rgb(0 0 0 / 0.1)",
                  }}
                  onChange={e => {
                    setApplicantStatus(e.target.value)
                  }}
                  MenuProps={{
                    MenuListProps: { sx: { padding: "0px" } },
                  }}
                >
                  {statusFilters.map((item, index) => (
                    <MenuItem
                      value={item.value}
                      key={index}
                      sx={{ fontSize: "12px" }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>

                <button
                  title="share CVs page"
                  className="py-2 px-6 rounded-full shadow text-slate-700 bg-sky-100 hover:bg-sky-200 flex items-center text-center gap-2 text-sm"
                  onClick={() => {
                    setOpenModal(true)
                  }}
                >
                  <FiShare2 />
                  <p>Share</p>
                </button>
              </div>
            </div>

            {/* isLoading */}
            {isLoading && (
              <div className="grid 4xl:grid-cols-5 3xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 w-full">
                <Skeleton style={{ height: "120px" }} />
                <Skeleton style={{ height: "120px" }} />
              </div>
            )}

            {/* isSuccess */}
            {isSuccess && (
              <div className="grid 4xl:grid-cols-5 3xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 w-full">
                {applications?.map((item, index) => (
                  <AppCard
                    applicant={item}
                    setPage={setPage}
                    setItem={setItem}
                    setResume={setResume}
                    setShowModal={setShowModal}
                    key={index}
                  />
                ))}
              </div>
            )}

            {/* isError */}
            {isError && (
              <div className="flex flex-col w-full justify-center">
                <div className="h-32 flex justify-center">
                  <img
                    src={require("../assets/no-data.webp")}
                    alt=""
                    className="h-full w-auto"
                    loading="lazy"
                  />
                </div>
                <p className="text-lg text-center text-black font-medium">
                  No data found with current filters.
                </p>
              </div>
            )}
          </div>
        )}

        {page === 2 && (
          <div className="grid sm:grid-cols-5 grid-cols-1 gap-6">
            <div className="sm:col-span-3 col-span-1 bg-white w-full rounded-md shadow">
              <JobApplicationDetails
                item={item}
                screeningQuestions={screeningQuestions}
                setApplicantStatus={setApplicantStatus}
                applicantStatus={applicantStatus}
                jobId={params?.id}
                auth={auth}
                permissions={permissions}
                refetch={refetchApplicationStatusHistory}
                refetchApplicationEmail={refetchApplicationEmail}
              />
            </div>

            <div className="sm:col-span-2 col-span-1 bg-white px-6 py-6 w-full rounded-md shadow">
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="fullWidth"
                color="primary"
                sx={{
                  color: "black",
                  "& .MuiTabs-flexContainer": {
                    justifyContent: "space-between",
                  },
                  "& .MuiTab-root": {
                    fontSize: "14px",
                    minWidth: "unset",
                    width: "fit-content",
                    marginTop: "3px",
                  },
                }}
              >
                <Tab label="Status" />
                <Tab label="Comments" />
                <Tab label="Shared" />
              </Tabs>

              {tabValue === 0 && (
                <div>
                  {isApplicationStatusHistorySuccess && (
                    <ApplicationStatusHistory data={applicationStatusHistory} />
                  )}
                </div>
              )}

              {tabValue === 1 && (
                <div className="mt-4">
                  <CommentSection permissions={permissions} item={item} />
                </div>
              )}

              {tabValue === 2 && (
                <div>
                  {isApplicationEmailSuccess && (
                    <SharedCardsHistory data={applicationEmail} />
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </AdminLayout>
  )
}

export default JobApplications
