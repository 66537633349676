import { useState, useEffect } from "react"
import LandingHeader from "../components/LandingPage/LandingHeader"
import { Link } from "react-router-dom"
import JobCard from "../components/JobCard"
import axios from "../api/axios"
import JobsTab from "../components/JobsTab"
import { filteredUnexpiredJobcards } from "../utils/helpers"
import { Helmet } from "react-helmet-async"

function UserLandingPage() {
  const [queryJob, setQueryJob] = useState("")
  const [queryCompany, setQueryCompany] = useState("")
  const [data, setData] = useState([])
  const [query, setQuery] = useState("")
  const [indexOfLastJob, setIndexOfLastJob] = useState()

  const currentJobs = array => {
    const newArray = array.slice(0, indexOfLastJob)
    return newArray
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const request = {
      signal: controller.signal,
    }

    const getData = async () => {
      const res = await axios.get("jobs/", request).catch(async err => {
        console.error(err?.response)
      })
      if (res?.data?.data) {
        isMounted && setData(res?.data?.data)
      }
    }

    getData()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const getIndexOfLastJob = () => {
      if (window.innerWidth >= 1280) {
        setIndexOfLastJob(8)
      } else if (window.innerWidth >= 1024) {
        setIndexOfLastJob(6)
      } else if (window.innerWidth >= 1740) {
        setIndexOfLastJob(10)
      } else if (window.innerWidth >= 2100) {
        setIndexOfLastJob(12)
      } else {
        setIndexOfLastJob(4)
      }
    }

    getIndexOfLastJob()

    window.addEventListener("resize", getIndexOfLastJob)

    return () => {
      window.removeEventListener("resize", getIndexOfLastJob)
    }
  }, [])

  const search = (array, query) => {
    const lowercaseQuery = query.toLowerCase()
    return array.filter(
      item =>
        (item.job_title &&
          item.job_title.toLowerCase().includes(lowercaseQuery)) ||
        (item.company_name &&
          item.company_name.toLowerCase().includes(lowercaseQuery)) ||
        (item.job_city &&
          item.job_city.toLowerCase().includes(lowercaseQuery)) ||
        (item.job_country &&
          item.job_country.toLowerCase().includes(lowercaseQuery))
    )
  }

  const search2 = (array, queryJob) => {
    const lowercaseQuery = queryJob.toLowerCase()
    return array.filter(
      item =>
        (item.job_title &&
          item.job_title.toLowerCase().includes(lowercaseQuery)) ||
        (item.company_name &&
          item.company_name.toLowerCase().includes(lowercaseQuery))
    )
  }

  const search3 = (array, queryCompany) => {
    const lowercaseQuery = queryCompany.toLowerCase()
    return array.filter(
      item =>
        (item.job_city &&
          item.job_city.toLowerCase().includes(lowercaseQuery)) ||
        (item.job_country &&
          item.job_country.toLowerCase().includes(lowercaseQuery))
    )
  }

  const unexpiredJobCards = filteredUnexpiredJobcards(data)

  return (
    <div className="flex w-full min-h-screen font-roboto relative backdrop-grayscale-[.5] bg-no-repeat bg-cover bg-fixed bg-center bg-[url('/public/techteam2.jpg')]">
      <Helmet>
        <title>Edge</title>
      </Helmet>

      <LandingHeader setQuery={setQuery} />
      <div className="xl:px-16 mt-24 md:mt-16 sm:px-8 px-4 py-10 w-full">
        <div className="text-center">
          <h1 className="text-white text-4xl uppercase font-bold">
            find your dream job.
          </h1>
          <h1 className="text-white text-2xl pt-3 uppercase font-bold">
            The home for tech jobs.
          </h1>
        </div>
        <form action="" className="mt-8 pt-10 w-full">
          <div className="w-full flex flex-wrap justify-between">
            <div className="sm:w-1/2 w-full sm:pr-2 mt-2">
              <label htmlFor="" className="text-white text-sm font-bold mb-1">
                What
              </label>
              <input
                type="text"
                placeholder="Job title or Company"
                onChange={event => setQueryJob(event.target.value)}
                className="text-gray-700 font-semi-bold text-sm py-2 w-full border placeholder:text-sm placeholder: pl-3 drop-shadow outline-offset-1 outline-cyan-500"
              />
            </div>

            <div className="sm:w-1/2 w-full sm:pl-2 mt-2">
              <label htmlFor="" className="text-white text-sm font-bold mb-1">
                Where
              </label>
              <input
                type="text"
                placeholder="City or Country"
                onChange={event => setQueryCompany(event.target.value)}
                className="text-gray-700 font-semi-bold text-sm py-2 w-full border placeholder:text-sm placeholder: pl-3 drop-shadow outline-offset-1 outline-cyan-500 "
              />
            </div>
          </div>
        </form>

        <div className="mt-8">
          <div className="bg-slate-500 bg-opacity-50 py-4 rounded-md shadow">
            <span className="text-white font-bold mx-4">Latest Jobs</span>
            <div className="grid 4xl:grid-cols-6 3xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 w-full px-3">
              {currentJobs(
                search(
                  search2(search3(data, queryCompany), queryJob),
                  query
                ).filter(job => unexpiredJobCards.includes(job))
              ).map(job => (
                <div key={job.id} className="px-3 py-4">
                  <JobCard
                    data={job}
                    page="home-job-description"
                    key={job.id}
                  />
                </div>
              ))}
            </div>

            {unexpiredJobCards.length > indexOfLastJob ? (
              <div className="flex flex-col items-center justify-center mt-2.5">
                <button
                  className="bg-blueBtn text-white text-sm py-1.5 px-2.5 rounded text-center"
                  onClick={() => setIndexOfLastJob(indexOfLastJob + 6)}
                >
                  Show More
                </button>
              </div>
            ) : unexpiredJobCards.length > 6 ? (
              unexpiredJobCards.length < indexOfLastJob ? (
                <div className="flex flex-col items-center justify-center mt-2.5">
                  <button
                    className="bg-blueBtn text-white text-sm py-1.5 px-2.5 rounded text-center"
                    onClick={() => setIndexOfLastJob(indexOfLastJob - 6)}
                  >
                    Show Less
                  </button>
                </div>
              ) : null
            ) : null}
          </div>
        </div>

        <div className="mt-8 opacity-80">
          <div className="bg-black opacity-15 text-white py-6 px-4 rounded-md shadow space-y-3">
            <JobsTab data={data} />
          </div>
          <div className="mt-8">
            <div className="bg-white sm:p-0 md:p-8 lg:py-10 lg:px-40 rounded-md shadow md:justify-between md:flex space-y-3">
              <img
                className="xl:w-96 lg:w-60 md:w-60 "
                src={require("../assets/jobs.jpeg")}
                alt=""
              />

              <div className="xl:pt-20 lg:pt-10 md:pt-10 xl:pr-20 lg:pr-0 md:pb-0 pb-6">
                <p className="text-black xl:text-base md:text-sm text-xs text-center">
                  Get instant job notifications and be the first to the latest
                  jobs.
                </p>
                <Link to="/job-alert">
                  <div className="mt-4 ml-36 md:ml-32">
                    <button className=" bg-blueBtn text-white md:p-3 p-1 rounded">
                      Create Alert
                    </button>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserLandingPage
