import { FiPlus } from "react-icons/fi"
import { Custom, Industry, Language, Tool } from "./ScreeningOptions"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"

const Screening = ({
  screeningQuestions,
  setScreeningQuestions,
  formik,
  currentStep,
  setCurrentStep,
}) => {
  const handleOnChange = (event, index) => {
    event.preventDefault()
    let data = [...screeningQuestions]
    data[index][event.target.name] = event.target.value
    setScreeningQuestions(data)
  }

  const addQuestion = value => {
    let newField = { group: value, question: "", answer: "", mustHave: false }
    setScreeningQuestions([...screeningQuestions, newField])
  }

  const removeQuestion = index => {
    const newList = [...screeningQuestions]
    newList.splice(index, 1)
    setScreeningQuestions(newList)
  }

  const handleCheckbox = (event, index) => {
    let data = [...screeningQuestions]
    data[index][event.target.name] = event.target.checked
    setScreeningQuestions(data)
  }

  return (
    <div className="py-3">
      {screeningQuestions.length > 0
        ? screeningQuestions.map((question, index) => (
            <div key={index} className="mb-4">
              {question.group === "Tool or Technology" && (
                <Tool
                  question={question}
                  index={index}
                  handleOnChange={handleOnChange}
                  removeQuestion={removeQuestion}
                  handleCheckbox={handleCheckbox}
                />
              )}

              {question.group === "Language" && (
                <Language
                  question={question}
                  index={index}
                  handleOnChange={handleOnChange}
                  removeQuestion={removeQuestion}
                  handleCheckbox={handleCheckbox}
                />
              )}

              {question.group === "Industry" && (
                <Industry
                  question={question}
                  index={index}
                  handleOnChange={handleOnChange}
                  removeQuestion={removeQuestion}
                  handleCheckbox={handleCheckbox}
                />
              )}

              {question.group === "Custom" && (
                <Custom
                  question={question}
                  index={index}
                  handleOnChange={handleOnChange}
                  removeQuestion={removeQuestion}
                  handleCheckbox={handleCheckbox}
                />
              )}
            </div>
          ))
        : null}

      <div className="border-t border-gray-200 py-3 mt-6">
        <span className="text-gray-600 text-sm">Add screening questions:</span>
        <div className="flex flex-wrap">
          <button
            className="py-2 px-4 mt-3 mr-2 text-gray-600 text-sm border border-gray-400 flex items-center space-x-2"
            onClick={() => addQuestion("Tool or Technology")}
          >
            <p>Expertise with tools</p> <FiPlus size={16} />
          </button>
          <button
            className="py-2 px-4 mt-3 mr-2 text-gray-600 text-sm border border-gray-400 flex items-center space-x-2"
            onClick={() => addQuestion("Language")}
          >
            <p>Language</p> <FiPlus size={16} />
          </button>
          <button
            className="py-2 px-4 mt-3 mr-2 text-gray-600 text-sm border border-gray-400 flex items-center space-x-2"
            onClick={() => addQuestion("Industry")}
          >
            <p>Industry</p> <FiPlus size={16} />
          </button>
          <button
            className="py-2 px-4 mt-3 mr-2 text-gray-600 text-sm border border-gray-400 flex items-center space-x-2"
            onClick={() => addQuestion("Custom")}
          >
            <p>Custom Question</p> <FiPlus size={16} />
          </button>
        </div>
      </div>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#3F51B5",
            mt: "1rem",
            py: "0.75rem",
            px: "1.5rem",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
          onClick={() => {
            setCurrentStep(currentStep - 1)
          }}
        >
          PREV
        </Button>

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#3F51B5",
            mt: "1rem",
            py: "0.75rem",
            px: "1.5rem",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
          onClick={e => {
            formik.handleSubmit(e)
          }}
        >
          NEXT
        </Button>
      </Box>
    </div>
  )
}

export default Screening
