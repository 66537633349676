import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import useAuth from "../hooks/useAuth"
import AppDetailsCard from "./AppDetailsCard"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import { useQuery } from "react-query"
import useFetcherPrivate from "../hooks/useFetcherPrivate"
import JobApplicationDetails from "./JobApplications/JobApplicationDetails"

const AppliedCard = ({ id }) => {
  const [selected, setSelected] = useState(-1)
  const [indexOfLastApplicant, setIndexOfLastApplicant] = useState(3)
  const { auth } = useAuth()
  const fetcherPrivate = useFetcherPrivate()

  const { data: screeningQuestions } = useQuery(["screening"], () =>
    fetcherPrivate(`jobs/screening/${id}`, auth?.accessToken)
  )

  const {
    data: applications,
    isError,
    isLoading,
    isSuccess,
  } = useQuery(["applications"], () =>
    fetcherPrivate(`applications/applicants/${id}?status=All`)
  )

  return (
    <div className="xl:px-8 sm:px-4 px-0 py-4">
      <h2 className="text-bold text-blue-500 text-lg">New Applicants</h2>
      <div className="grid 4xl:grid-cols-5 3xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 grid-cols-1">
        {/* {selected === -1 && */}
        {applications?.map((applicant, index) => (
          <div key={index} className="px-3 py-4">
            <AppDetailsCard
              applicant={applicant}
              index={index}
              setSelected={setSelected}
            />
          </div>
        ))}
      </div>

      {/* {selected > -1 && (
        <JobApplicationDetails
          data={data}
          selected={selected}
          setSelected={setSelected}
          screeningQuestions={screeningQuestions}
        />
      )} */}

      {applications?.length > indexOfLastApplicant && (
        <button
          className="border-none pt-3 w-full text-center text-blue-700 hover:text-blue-400"
          onClick={() => setIndexOfLastApplicant(indexOfLastApplicant + 3)}
        >
          Show More
        </button>
      )}
    </div>
  )
}

export default AppliedCard
