import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FcSettings } from "react-icons/fc";
import { MdWork, MdAssessment, MdDashboard } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import logo from "../assets/logopic_white.png";
import { SidebarContext } from "../contexts/SidebarContext";

const CandidateSidebar = ({ page }) => {
  const LinkReset = "p-0";
  const currentPage =
    "bg-slate-50 text-slate-700 font-bold cursor-pointer py-2 px-4 flex items-center w-full border-none";
  const regularPage =
    "text-white font-bold cursor-pointer py-2 px-4 flex items-center w-full hover:bg-slate-50 hover:text-slate-700";

  const navigate = useNavigate();
  const { showSidebar } = useContext(SidebarContext);

  return (
    <div
      className={`lg:w-1/5 lg:translate-x-0 w-64 fixed inset-y-0 left-0 transform transition duration-200 shadow-2xl ease-in-out flex flex-col bg-slate-700 bg-cover text-white text-sm font-light py-6 z-50 ${
        showSidebar
          ? "translate-x-0 shadow-lg"
          : "-translate-x-full shadow-none"
      }`}
    >
      <div className="relative">
        <Link to="/candidate-dashboard" className={LinkReset}>
          <div className="flex items-center px-2">
            <img src={logo} alt="Edge" className="w-20" />
            <span className="text-lg text-white font-bold">THE EDGE APP</span>
          </div>
        </Link>

        <div className="flex flex-col mt-8">
          <Link to="/candidate-dashboard" className={LinkReset}>
            <span className={page === "dashboard" ? currentPage : regularPage}>
              <MdDashboard size={24} className="mr-4" /> Dashboard
            </span>
          </Link>

          <Link to="/my-profile" className={LinkReset}>
            <span className={page === "profile" ? currentPage : regularPage}>
              <CgProfile size={24} className="mr-4" /> My Profile
            </span>
          </Link>

          <Link to="/my-applications" className={LinkReset}>
            <span
              className={page === "applications" ? currentPage : regularPage}
            >
              <MdWork size={24} className="mr-4" /> My Applications
            </span>
          </Link>
          <Link to="/assessment-page" className={LinkReset}>
            <span className={page === "quiz" ? currentPage : regularPage}>
              <MdAssessment size={24} className="mr-4" /> My Assessment
            </span>
          </Link>
          {/* <Link to="/leave" className={LinkReset}>
            <span className={page === "leave" ? currentPage : regularPage}>
              <MdAssessment size={24} className="mr-4" /> My Leave
            </span>
          </Link> */}

          {/* <div> */}
          {/* <span
            className={page === "applicant" ? currentPage : regularPage}
            onClick={() => setShowSubMenuProfile(!showSubMenuProfile)}
          >
            <FcBusinessman size={24} className="mr-4" />
            My Profile
          </span>
          <div
            className={`${
              showSubMenuProfile ? "flex flex-col" : "hidden"
            } text-center bg-slate-600`}
          >
            <span
              className="text-white font-bold cursor-pointer py-2 pl-20 flex items-center w-full hover:bg-slate-50 hover:text-slate-700"
              onClick={() => navigate("/my-profile/create")}
            >
              Create Profile
            </span>
            
            <span
              className="text-white font-bold cursor-pointer py-2 pl-20 flex items-center w-full hover:bg-slate-50 hover:text-slate-700"
              onClick={() => navigate("/my-profile/view")}
            >
              View profile
            </span>
          </div>
        </div> */}

          {/* <div>
            <span
            className={page === "jobs" ? currentPage : regularPage}
            onClick={() => setShowSubMenuJobs(!showSubMenuJobs)}
          >
            <FcParallelTasks size={24} className="mr-4" /> My Job
          </span>
          <div
            className={`${
              showSubMenuJobs ? "flex flex-col" : "hidden"
            } text-center bg-slate-600`}
          >
            <span
              className="text-white font-bold cursor-pointer py-2 pl-20 flex items-center w-full hover:bg-slate-50 hover:text-slate-700"
              onClick={() => navigate("/jobs/add")}
            >
              Applied Job
            </span>
            <span
              className="text-white font-bold cursor-pointer py-2 pl-20 flex items-center w-full hover:bg-slate-50 hover:text-slate-700"
              onClick={() => navigate("/jobs")}
            >
              Manage Jobs
            </span>
          </div>
        </div> */}

          {/* <Link to="" className={LinkReset}>
            <span className={page === "feedback" ? currentPage : regularPage}>
              <FcCalendar size={24} className="mr-4" /> Calendar
            </span>
          </Link> */}

          <Link to="/user-settings" className={LinkReset}>
            <span
              className={page === "settings" ? currentPage : regularPage}
              onClick={() => navigate("/user-settings")}
            >
              <FcSettings size={24} className="mr-4" /> Settings
            </span>
          </Link>
        </div>

        {/* <div className="flex flex-col fixed bottom-0 inset-x-0">
          <img
            src={require("../assets/D1-image6.png")}
            alt=""
            className="relative left-14 w-16 h-16"
          />
          <div className="bg-bgcolor py-4 rounded-lg text-center">
            <h3 className="text-black font-bold text-sm">Support 24/7</h3>
            <p className="text-gray-400 text-xs">Contact Us anytime</p>

            <button className="bg-blue-700 rounded-lg px-8 py-1 text-white my-3">
              Start chat
            </button>
          </div>
        </div> */}

        <div className="flex flex-col fixed bottom-0 inset-x-0">
          <div className="bg-bgcolor py-4 rounded-lg text-center">
            <h3 className="text-black font-bold text-sm px-2 text-center">
              Kindly Participate in Our Product Feedback Survey
            </h3>

            <Link to="/user-feedback">
              <button className="bg-blue-700 rounded-lg px-8 py-1 text-white my-3">
                Click Here
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateSidebar;
