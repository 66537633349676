import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { FiDownload, FiEdit, FiEye } from "react-icons/fi"
import "../styles/ApplicantList.css"
import { timestampToDate, titleCase } from "../utils/helpers"
import EditApplicant from "./editApplicant"
import ViewResumeModal from "./ViewResumeModal"

const ApplicantList = ({ data, search }) => {
  const [selected, setSelected] = useState(-1)
  const [openModal, setOpenModal] = useState(false)
  const [applicantData, setApplicantData] = useState()
  const [showModal, setShowModal] = useState(false)
  const [resume, setResume] = useState("")

  const handleEdit = (item, index) => {
    setOpenModal(true)
    setApplicantData(item)
  }

  const alternatingStyles = [
    "bg-white text-slate-800 ",
    "bg-slate-300 text-white",
  ]
  const navigate = useNavigate()

  console.log({ data })

  return (
    <div className="">
      {openModal && (
        <EditApplicant
          openModal={openModal}
          setOpenModal={setOpenModal}
          applicantData={applicantData}
        />
      )}

      {showModal && (
        <ViewResumeModal
          showModal={showModal}
          resume={resume}
          setShowModal={setShowModal}
        />
      )}

      <table className="border-none w-full">
        <thead>
          <tr className="bg-slate-600 w-full">
            <th className="w-1/12"></th>
            <th className="w-2/12">NAME</th>
            <th className="hideprop2 w-2/12">EMAIL</th>
            <th className="hideprop2 w-1/12">COUNTRY</th>
            <th className="hideprop w-1/12">PHONE</th>
            <th className="hideprop w-2/12">POSITION APPLIED</th>
            <th className="hideprop w-1/12">DATE SUBMITTED</th>
            <th className="w-1/12">STATUS</th>
          </tr>
        </thead>
        <tbody className="w-full relative">
          {data?.length > 0 &&
            search(data).map((item, index) => {
              return (
                <tr
                  key={item.applicant.id}
                  className={`${
                    alternatingStyles[index % alternatingStyles.length]
                  } py-4 relative`}
                >
                  <td>
                    {/* icons */}
                    <div className="flex items-center justify-center gap-1.5">
                      <button onClick={() => handleEdit(item, index)}>
                        <FiEdit size={16} />
                      </button>

                      <button
                        disabled={
                          item?.application?.resume.includes(".pdf")
                            ? false
                            : true
                        }
                        onClick={() => {
                          setResume(item?.application?.resume)
                          setShowModal(true)
                        }}
                      >
                        <FiEye
                          size={16}
                          color={
                            item?.application?.resume.includes(".pdf")
                              ? "inherit"
                              : "darkgrey"
                          }
                        />
                      </button>

                      <a
                        href={`${process.env.REACT_APP_BASE_URL}storage/files/${item.application.resume}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FiDownload size={16} />
                      </a>
                    </div>
                  </td>
                  <td>
                    {`${titleCase(item.applicant.first_name)} ${titleCase(
                      item.applicant.last_name
                    )}`}
                  </td>
                  <td className="hideprop2">{item.applicant.email}</td>
                  <td className="hideprop2">
                    {titleCase(item.applicant.country)}
                  </td>
                  <td className="hideprop">{item.applicant.phone_number}</td>

                  <td className="hideprop">
                    {titleCase(item.application.job_role)}
                  </td>
                  <td className="hideprop">
                    {timestampToDate(item.applicant.updated_at)}
                  </td>
                  <td className="">{titleCase(item.application.status)}</td>
                </tr>
              )
            })}
          {/* <ApplicantCard
            data={data}
            selected={selected}
            setSelected={setSelected}
          /> */}
        </tbody>
      </table>
    </div>
  )
}

export default ApplicantList
