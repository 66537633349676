import SidebarContextProvider from "../contexts/SidebarContext"
import Sidebar from "./Sidebar"
import AccessControl from "./accessControl/AccessControl"

const AdminLayout = ({ children, page, role }) => {
  return (
    <SidebarContextProvider>
      <div className="flex w-full min-h-screen bg-slate-50 font-roboto relative">
        <AccessControl
          userPermission={role}
          allowedPermissions={["superAdmin", "admin"]}
        >
          <Sidebar page={page} role={role} />
        </AccessControl>

        <div className="lg:min-w-3/4 w-full ml-auto relative">{children}</div>
      </div>
    </SidebarContextProvider>
  )
}

export default AdminLayout
