import { Box, IconButton, Modal, TextField, Typography } from "@mui/material"
import { FiLink2, FiX } from "react-icons/fi"
import * as yup from "yup"
import { useFormik } from "formik"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import useAuth from "../../hooks/useAuth"
import { rights } from "../../utils/data"
import { MenuItem } from "@material-ui/core"
import Swal from "sweetalert2"
//import { generate } from "@wcj/generate-password"
import { useMemo } from "react"
import { init } from "@paralleldrive/cuid2"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", sm: "50%" },
  maxHeight: "90vh",
  bgcolor: "background.paper",
  color: "#000000",
  border: "0px",
  borderRadius: "16px",
  boxShadow: 24,
  outline: "none",
  overflow: "auto",
  p: 0,
}

const ShareCvsModal = ({ openModal, setOpenModal, applicantStatus, jobId }) => {
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth()

  const validationSchema = yup.object().shape({
    first_name: yup.string().required("This field is required"),
    last_name: yup.string().required("This field is required"),
    email: yup
      .string()
      .email("Enter a valid email")
      .required("This field is required."),
    rights: yup.string().required("This field is required"),
  })

  const createId = init({
    random: Math.random,
    length: 24,
    fingerprint: "a-custom-host-fingerprint",
  })

  const verificationCode = useMemo(() => createId(), [applicantStatus])

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      rights: "Viewer",
    },
    validationSchema,
    validateBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      const res = await axiosPrivate.post(`share/shareCvs/`, {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        rights: values.rights,
        admin_id: auth?.id,
        company_id: auth?.companyId,
        job_id: jobId,
        verification_code: verificationCode,
        status: applicantStatus,
      })

      if (res) {
        Swal.fire({
          toast: true,
          icon: "success",
          title: "CVs has been shared successfully.",
          position: "top",
          timer: 3000,
          showConfirmButton: false,
        })

        resetForm()
        setOpenModal(false)
      }
    },
  })

  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="modal-modal-title"
      disableScrollLock
    >
      <Box sx={style}>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h6"
            sx={{ textAlign: "left", fontWeight: "700" }}
          >
            Share this CVs page
          </Typography>

          <IconButton
            onClick={() => {
              setOpenModal(false)
            }}
          >
            <FiX />
          </IconButton>
        </Box>

        <hr />

        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            width: "100%",
            mx: "auto",
            py: 2,
            px: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            "& .MuiSvgIcon-root": {
              color: "#000000",
            },
          }}
          onSubmit={formik.handleSubmit}
        >
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            Add person
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              gap: 2,
              //flexWrap: "wrap",
              width: "100%",
            }}
          >
            {/* first name */}
            <Box sx={{ width: "50%" }}>
              <TextField
                required
                variant="outlined"
                fullWidth
                size="small"
                id="first_name"
                label="First Name"
                value={formik.values.first_name}
                onChange={formik.handleChange("first_name")}
                error={
                  formik.touched.first_name && Boolean(formik.errors.first_name)
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
              />
            </Box>
            {/* last name */}
            <Box sx={{ width: "50%" }}>
              <TextField
                required
                variant="outlined"
                fullWidth
                size="small"
                id="last_name"
                label="Last Name"
                value={formik.values.last_name}
                onChange={formik.handleChange("last_name")}
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
                helperText={formik.touched.last_name && formik.errors.last_name}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              gap: 2,
              //flexWrap: "wrap",
              width: "100%",
              mt: "0.5rem",
            }}
          >
            {/* email */}
            <Box sx={{ width: "75%" }}>
              <TextField
                required
                variant="outlined"
                fullWidth
                size="small"
                id="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange("email")}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Box>

            {/* rights */}
            <Box sx={{ width: "25%" }}>
              <TextField
                required
                variant="outlined"
                fullWidth
                size="small"
                id="rights"
                value={formik.values.rights}
                onChange={formik.handleChange("rights")}
                error={formik.touched.rights && Boolean(formik.errors.rights)}
                helperText={formik.touched.rights && formik.errors.rights}
                select
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    MenuListProps: { sx: { padding: "0px" } },
                  },
                }}
              >
                {rights.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              mt: "2rem",
            }}
          >
            <button
              type="button"
              className="py-2 px-6 text-base rounded-full text-blue-600 hover:text-blue-700 border border-black flex items-center gap-1.5"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${
                    process.env.REACT_APP_PRODUCTION_URL
                  }/jobs/${jobId}/applications?usp=sharing&query=${verificationCode}&status=${applicantStatus}&permissions=${
                    formik.values.rights === "Editor"
                      ? "admin"
                      : formik.values.rights === "Commenter"
                      ? "contributor"
                      : "guest"
                  }`
                )
                Swal.fire({
                  toast: true,
                  icon: "success",
                  title: "Link has been copied to the clipboard.",
                  position: "top",
                  timer: 3000,
                  showConfirmButton: false,
                })
              }}
            >
              <FiLink2 />
              <p>Copy link</p>
            </button>

            <button
              type="submit"
              className="py-2 px-6 text-base rounded-full bg-blue-600 hover:bg-blue-700 text-white"
            >
              Send
            </button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ShareCvsModal
