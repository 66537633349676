import React, { useState } from "react";


import {
  Tab,
  Tabs,
  Tooltip,
  useMediaQuery,
  Typography,
} from "@material-ui/core";
import JobsByCategory from "./JobsByCategory";
import WorkIcon from "@material-ui/icons/Work";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import BusinessIcon from "@material-ui/icons/Business";
import CategoryIcon from "@material-ui/icons/Category";

const JobsTabs = ({ data }) => {
  const [value, setValue] = useState(0);
  const isMobile = useMediaQuery("(max-width: 710px)");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderTabLabel = (icon, tooltip, index) => {
    if (isMobile) {
      return (
        <Tooltip title={tooltip} placement="bottom" key={index}>
          <Tab icon={icon} />
        </Tooltip>
      );
    } else {
      return <Tab label={tooltip} key={index} />;
    }
  };

  const getTabLabelTypography = () => {
    if (isMobile) {
      return { fontSize: "12px" };
    } else {
      return { fontSize: "inherit" };
    }
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        scrollButtons="auto"
        indicatorColor="primary"
      >
        {renderTabLabel(
          <WorkIcon />,
          <Typography style={getTabLabelTypography()}>Jobs By Role</Typography>,
          0
        )}
        {renderTabLabel(
          <LocationOnIcon />,
          <Typography style={getTabLabelTypography()}>
            Jobs By Location
          </Typography>,
          1
        )}
        {renderTabLabel(
          <BusinessIcon />,
          <Typography style={getTabLabelTypography()}>
            Jobs By Company
          </Typography>,
          2
        )}
        {renderTabLabel(
          <CategoryIcon />,
          <Typography style={getTabLabelTypography()}>
            Jobs By Types
          </Typography>,
          3
        )}
      </Tabs>

    <JobsByCategory data={data} category={value} />
    </>
  );
};

export default JobsTabs;

