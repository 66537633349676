import React, { useState } from "react"
import Swal from "sweetalert2"
import * as yup from "yup"
import { Link, useNavigate, useParams } from "react-router-dom"
import axios from "../api/axios"
import PWDRequisite from "../components/PWDRequisite"
import "../styles/Adminlog.css"
import { Box } from "@mui/material"
import usePasswordToggle from "../hooks/usePasswordToggle"
import { BsEye, BsEyeSlash } from "react-icons/bs"
import { useFormik } from "formik"
import { Helmet } from "react-helmet-async"

const validationSchema = yup.object({
  first_name: yup
    .string("Enter your first name")
    .required("This field is required")
    .min(3, "First name must be at least 3 characters"),
  last_name: yup
    .string("Enter your last name")
    .required("This field is required")
    .min(3, "Last name must be at least 3 characters"),
  phone_number: yup
    .string("Enter your phone number")
    .required("This field is required")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    ),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("This field is required"),
  password: yup
    .string("Enter your password")
    .required("This field is required")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
  confirm_password: yup
    .string("Confirm your password")
    .required("This field is required")
    .oneOf([yup.ref("password"), null], "Passwords don't match."),
})

const UserRegistration = () => {
  const [showPassword, setShowPassword] = useState("false")
  const navigate = useNavigate()
  let [message, setMessage] = useState("")

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      password: "",
      confirm_password: "",
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      const res = await axios
        .post("users/", {
          first_name: values.first_name,
          last_name: values.last_name,
          phone_number: values.phone_number,
          email: values.email,
          password: values.password,
          confirm_password: values.confirm_password,
        })
        .catch(err => {
          setMessage(err.response.data.message)
        })
      if (res.status === 201) {
        resetForm()
        Swal.fire({
          toast: true,
          icon: "success",
          title:
            "Registration Successful! A verification link has been sent to your email.",
          position: "top",
          timer: 3000,
          showConfirmButton: false,
        })

        navigate("/user-login")
      }
    },
  })

  const show = showPassword ? (
    <BsEyeSlash
      color=" #1E3A8A"
      size={18}
      icon={showPassword ? "eye-slash" : "eye"}
      onClick={() => setShowPassword(visibility => !visibility)}
    />
  ) : (
    <BsEye
      color=" #1E3A8A"
      size={18}
      icon={showPassword ? "eye-slash" : "eye"}
      onClick={() => setShowPassword(visibility => !visibility)}
    />
  )

  const [PasswordInputType, ToggleIcon] = usePasswordToggle()

  return (
    <div
      className="min-h-screen bg-slate-200 flex justify-center overflow-y-auto overflow-x-hidden 
    items-center w-screen relative py-0"
    >
      <Helmet>
        <title>Sign Up | Edge</title>
      </Helmet>

      <div className="background">
        <div className="absolute top-4 left-4">
          <div className="h-24 w-24">
            <Link to="/">
              <img src={require("../assets/logopic.png")} alt="" />
            </Link>
          </div>
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="flex justify-center items-center flex-col w-screen py-16"
        >
          <div className="bg-slate-50 px-10 py-4 rounded-xl w-screen shadow-md max-w-sm">
            <div className="space-y-3">
              <h1 className="text-center text-2xl font-semibold text-gray-600">
                Register
              </h1>
              <div>
                {message ? (
                  <p className="text-danger text-red-600 text-sm">{message}</p>
                ) : null}
              </div>
              <div>
                <label
                  htmlFor="first_name"
                  className="block mb-1 text-gray-600 font-semibold text-lg"
                >
                  First Name
                </label>
                <input
                  type="text"
                  className="text-black bg-indigo-50 px-4 py-2 outline-none rounded-md w-full text-lg"
                  name="first_name"
                  value={formik.values.first_name}
                  onChange={formik.handleChange("first_name")}
                  onBlur={formik.handleBlur}
                  placeholder="First Name"
                  id="first_name"
                  autoComplete="off"
                />

                <div className="text-danger text-red-600 text-base">
                  {formik.touched.first_name && formik.errors.first_name}
                </div>
              </div>
              <div>
                <label
                  htmlFor="last_name"
                  className="block mb-1 text-gray-600 font-semibold text-lg"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  className="text-black bg-indigo-50 px-4 py-2 mb-3 outline-none rounded-md w-full text-lg"
                  name="last_name"
                  value={formik.values.last_name}
                  onChange={formik.handleChange("last_name")}
                  onBlur={formik.handleBlur}
                  placeholder="Last Name"
                  id="last_name"
                  autoComplete="off"
                />

                <div className="text-danger text-red-600 text-base">
                  {formik.touched.last_name && formik.errors.last_name}{" "}
                </div>

                <div>
                  <label
                    htmlFor="phone_number"
                    className="block mb-1 text-gray-600 font-semibold text-lg"
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    className="text-black bg-indigo-50 px-4 py-2 mb-3 outline-none rounded-md w-full text-lg"
                    value={formik.values.phone_number}
                    onChange={formik.handleChange("phone_number")}
                    onBlur={formik.handleBlur}
                    placeholder="Phone Number"
                    id="phone_number"
                    autoComplete="off"
                  />
                  <div className="text-danger text-red-600 text-base">
                    {formik.touched.phone_number && formik.errors.phone_number}{" "}
                  </div>
                </div>
                <label
                  htmlFor="email"
                  className="block mb-1 text-gray-600 font-semibold text-lg"
                >
                  Email
                </label>
                <input
                  type="email"
                  className="text-black bg-indigo-50 px-4 py-2 outline-none rounded-md w-full text-lg"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange("email")}
                  onBlur={formik.handleBlur}
                  placeholder="Email Address"
                  id="email"
                  autoComplete="off"
                />
                <div className="text-danger text-red-600 text-base">
                  {formik.touched.email && formik.errors.email}
                </div>
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-1 text-gray-600 font-semibold text-lg"
                >
                  Password
                </label>
                <input
                  type={showPassword ? "password" : "text"}
                  className="text-black bg-indigo-50 px-4 py-2 mb-3 outline-none rounded-md w-full text-lg"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange("password")}
                  onBlur={formik.handleBlur}
                  placeholder="Password"
                  id="password"
                  autoComplete="off"
                />
                <Box
                  sx={{
                    position: "relative",
                    left: "17rem",
                    bottom: "2.7rem",
                    cursor: "pointer",
                  }}
                >
                  {show}
                </Box>
                <div className="text-danger text-red-600 text-base">
                  {formik.touched.password && formik.errors.password}
                </div>
              </div>
            </div>
            <div>
              <label
                htmlFor="comfirm_password"
                className="block mb-1 text-gray-600 font-semibold text-lg"
              >
                Confirm Password
              </label>
              <input
                type={PasswordInputType}
                className="text-black bg-indigo-50 px-4 py-2 outline-none rounded-md w-full text-lg"
                name="confirm_password"
                value={formik.values.confirm_password}
                onChange={formik.handleChange("confirm_password")}
                onBlur={formik.handleBlur}
                placeholder="Confirm Password"
                id="confirm_password"
                autoComplete="off"
              />
              <Box
                sx={{
                  position: "relative",
                  left: "17rem",
                  bottom: "2rem",
                  cursor: "pointer",
                }}
              >
                {ToggleIcon}
              </Box>
              <div className="text-danger text-red-600 text-base">
                {formik.touched.confirm_password &&
                  formik.errors.confirm_password}{" "}
              </div>
            </div>
            <button
              className="mt-4 w-full bg-blue-900 font-semibold py-2 rounded-md text-lg tracking-wide"
              onClick={e => {
                formik.handleSubmit(e)
              }}
            >
              Register
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default UserRegistration
