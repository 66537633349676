import { useEffect, useState } from "react"
import axios from "../../api/axios"
import useAuth from "../../hooks/useAuth"
import AssessmentCard from "./AssessmentCard"
//import VideoUpload from "./VideoUpload"
import AssessmentScore from "./AssessmentScore"

const AssessmentLanding = ({ setPage, setQuery, query }) => {
  const [assessments, setAssessments] = useState([])
  const [takenAssessments, setTakenAssessments] = useState([])
  const [showModal, setShowModal] = useState(false)
  const { auth } = useAuth()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const getQuestions = async () => {
      const res = await axios
        .get(`assessment/applications/${auth?.id}`)
        .catch(error => {
          console.error(error?.res)
        })
      if (res?.data) {
        isMounted && setAssessments(res?.data?.data)
      }
    }

    const getAssessmentAnswered = async () => {
      const res = await axios
        .get(`assessment/user_answers/${auth?.id}`)
        .catch(error => {
          console.error(error?.res)
        })
      if (res?.data) {
        isMounted && setTakenAssessments(res?.data?.data)
      }
    }

    getQuestions()
    getAssessmentAnswered()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [auth])

  return (
    <div className="py-6">
      {showModal && (
        <AssessmentScore
          showModal={showModal}
          setShowModal={setShowModal}
          query={query}
        />
      )}

      <p className="text-white text-2xl font-medium px-8">
        Multiple Choice Quizzes
      </p>

      <div className="mt-10 px-8">
        <div className="bg-slate-500 bg-opacity-50 text-black rounded-lg shadow-sm py-4 mt-8">
          <p className="text-lg font-medium text-white mx-4">Assessments</p>
          <div className="flex flex-wrap w-full px-3">
            {assessments?.map((item, index) => (
              <div className="px-3 py-4 3xl:w-1/6 2xl:w-1/5 xl:w-1/4 md:w-1/3 sm:w-1/2 w-full">
                <AssessmentCard
                  item={item}
                  key={index}
                  setPage={setPage}
                  takenAssessments={takenAssessments}
                  setShowModal={setShowModal}
                  setQuery={setQuery}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssessmentLanding
