import {
  useLocation,
  useNavigate,
  useSearchParams,
  useParams,
} from "react-router-dom"
import axios from "../api/axios"
import useAuth from "../hooks/useAuth"
import AccessControl from "./accessControl/AccessControl"

const AdminMenu = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { setAuth, auth } = useAuth()
  const params = useParams()
  const searchParams = useSearchParams()
  const permissions = searchParams[0].get("permissions")
  const qid = searchParams[0].get("qid")

  /*The signOut function logs out an authenticated user by sending a POST request to the server, clearing authentication data, and navigating to the admin-login page. */

  const signOut = () => {
    axios.post(`logout/${auth?.role}/${auth?.id}`).catch(err => {
      console.error(err?.response)
    })
    setAuth({})
    localStorage.removeItem("refresh")
    navigate("/admin-login", {
      state: {
        prevURL: location.pathname,
      },
    })
  }

  return (
    <div className="origin-top-right absolute top-0 right-0 mt-16 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-50">
      <AccessControl
        userPermission={auth?.role}
        allowedPermissions={["superAdmin"]}
      >
        <div className="flex flex-col py-2 text-gray-500">
          <span className="text-bold text-lg text-black px-3 cursor-auto">
            Account
          </span>

          <span
            onClick={() => navigate("/admin-profile")}
            className="hover:bg-pinkBg hover:text-gray-800 py-1 px-3"
          >
            Profile
          </span>
        </div>
      </AccessControl>

      <div className="flex flex-col py-2 text-gray-500">
        <span className="text-bold text-lg text-black px-3 cursor-auto">
          Manage
        </span>
        <span
          className="hover:bg-pinkBg hover:text-gray-800 py-1 px-3"
          onClick={() => navigate("/applicants")}
        >
          Applicants
        </span>
        <span
          className="hover:bg-pinkBg hover:text-gray-800 py-1 px-3"
          onClick={() => navigate("/jobs")}
        >
          Jobs
        </span>
      </div>

      {permissions === "admin" ? (
        <button
          className="text-edge py-2 px-3 w-full text-left hover:bg-pinkBg hover:rounded-b-md"
          onClick={() => {
            navigate("/admin-login", {
              state: {
                prevURL: `/jobs/${params?.id}/applications`,
                qid: qid,
              },
            })
          }}
        >
          Sign In
        </button>
      ) : (
        <button
          className="text-edge py-2 px-3 w-full text-left hover:bg-pinkBg hover:rounded-b-md"
          onClick={() => {
            signOut()
          }}
        >
          Sign Out
        </button>
      )}
    </div>
  )
}

export default AdminMenu
