import { Custom } from "./Custom"
import { FiPlus } from "react-icons/fi"

const Details = ({ questions, setQuestions, id, onSubmit }) => {
  const handleOnChange = (event, index) => {
    event.preventDefault()
    let data = [...questions]
    data[index][event.target.name] = event.target.value
    setQuestions(data)
  }

  const addQuestion = () => {
    let newField = {
      question_id: +id,
      question: "",
      option_A: "",
      option_B: "",
      option_C: "",
      option_D: "",
      correct_option: "",
      time: "",
    }
    setQuestions([...questions, newField])
  }

  const removeQuestion = index => {
    const newList = [...questions]
    newList.splice(index, 1)
    setQuestions(newList)
  }
  return (
    <div className="px-8 py-4 mt-8">
      {questions.length > 0
        ? questions.map((question, index) => (
            <div key={index} className="mb-4">
              <Custom
                question={question}
                index={index}
                handleOnChange={handleOnChange}
                removeQuestion={removeQuestion}
              />
            </div>
          ))
        : null}

      <div className="py-auto">
        <span className="text-gray-600 text-sm py-3 font-semibold">
          Add Assessment Questions:
        </span>
        <div className="py-3">
          <button
            className="py-2 px-4 text-gray-600 text-sm border border-gray-400 flex items-center space-x-2"
            onClick={() => addQuestion()}
          >
            <p>Create Assessment Question</p> <FiPlus size={16} />
          </button>
        </div>
      </div>
      <div className="flex flex-row justify-end py-3 mt-6 space-x-32 md:space-x-24">
        <button
          className="bg-green-400 py-2 px-3 rounded-2xl shadow-lg text-sm text-slate-900 hover:bg-black hover:text-white hover:opacity-90"
          onClick={() => onSubmit()}
        >
          NEXT
        </button>
      </div>
    </div>
  )
}

export default Details
