import { useEffect, useState } from "react"
import axios from "../../api/axios"
import * as yup from "yup"
import { useFormik } from "formik"
import { Box, IconButton, Modal, Typography } from "@mui/material"
import { FiX } from "react-icons/fi"
import { titleCase } from "../../utils/helpers"
import Details from "./Details"
import Resume from "./Resume"
import AdditionalQuestions from "./AdditionalQuestions"
import Preview from "./Preview"
import Swal from "sweetalert2"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", sm: "50%" },
  maxHeight: "90vh",
  bgcolor: "background.paper",
  color: "#000000",
  border: "0px",
  borderRadius: "8px",
  boxShadow: 24,
  px: 2.5,
  py: 4,
  outline: "none",
  overflow: "auto",
}

const JobApplyModal = ({ showModal, setShowModal, auth, job }) => {
  const DetailsSchema = yup.object().shape({
    email: yup
      .string()
      .email("Enter a valid email")
      .required("This field is required."),
    phone_number: yup.string().required("This field is required."),
  })

  const ResumeSchema = yup.object().shape({
    resume: yup.lazy(value => {
      switch (typeof value) {
        case "string":
          return yup.string()
        case "object":
          return yup.mixed().required()
        default:
      }
    }),
  })

  const AdditionalQuestionsSchema = yup.object().shape({
    screeningAnswers: yup
      .array()
      .min(job.screeningQuestions.length, "Fill all fields")
      .required("Provide answers to screening questions"),
  })

  const [page, setPage] = useState(1)
  const [user, setUser] = useState({})

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const request = {
      signal: controller.signal,
    }

    const getUser = async () => {
      const res = await axios
        .get(`users?email=${auth.email}`, request)
        .catch(async err => {
          console.error(err?.response)
        })
      if (res) {
        isMounted && setUser(res.data.data.user)
      }
    }

    getUser()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [auth])

  const formik = useFormik({
    initialValues: {
      email: user?.email,
      phone_number: user?.phone_number,
      resume: user?.resume,
      screeningAnswers: [],
    },
    enableReinitialize: true,
    validationSchema:
      page === 1
        ? DetailsSchema
        : page === 2
        ? ResumeSchema
        : AdditionalQuestionsSchema,
    validateBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      if (page === 1) {
        setPage(2)
      }

      if (page === 2) {
        if (job.screeningQuestions.length < 1) {
          setPage(4)
        } else {
          setPage(3)
        }
      }

      if (page === 3) {
        setPage(4)
      }

      if (page === 4) {
        if (typeof values.resume === "object" && values.resume) {
          const formData = new FormData()
          formData.append("resume", values.resume)
          const upload = await axios
            .post(`storage/upload-resume`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                authorization: `Bearer ${auth?.accessToken}`,
              },
            })
            .catch(err => {
              Swal.fire({
                toast: true,
                icon: "error",
                title: err.response.data.message,
                position: "top",
                timer: 5000,
                showConfirmButton: false,
              })
              console.error(err)
              setShowModal(false)
            })

          if (upload) {
            const res = await axios
              .post("applications/", {
                resume: values.resume.name,
                user_id: user?.id,
                job_id: job.job.id,
                company_id: job.job.company_id,
                answers: values.screeningAnswers,
              })
              .catch(err => {
                Swal.fire({
                  toast: true,
                  icon: "error",
                  title: err.response.data.message,
                  position: "top",
                  timer: 5000,
                  showConfirmButton: false,
                })
                console.error(err)
                setShowModal(false)
              })

            if (res) {
              resetForm()
              Swal.fire({
                toast: true,
                icon: "success",
                title: "Application submitted successfully",
                position: "top",
                timer: 5000,
                showConfirmButton: false,
              })
              setShowModal(false)
            }
          }
        } else {
          const res = await axios
            .post("applications/", {
              resume: values.resume,
              user_id: user?.id,
              job_id: job.job.id,
              company_id: job.job.company_id,
              answers: values.screeningAnswers,
            })
            .catch(err => {
              Swal.fire({
                toast: true,
                icon: "error",
                title: err.response.data.message,
                position: "top",
                timer: 5000,
                showConfirmButton: false,
              })
              console.error(err)
              setShowModal(false)
            })

          if (res) {
            resetForm()
            Swal.fire({
              toast: true,
              icon: "success",
              title: "Application submitted successfully",
              position: "top",
              timer: 5000,
              showConfirmButton: false,
            })
            setShowModal(false)
          }
        }
      }
    },
  })

  const displayStep = step => {
    switch (step) {
      case 1:
        return <Details formik={formik} user={user} />
      case 2:
        return (
          <Resume user={user} formik={formik} page={page} setPage={setPage} />
        )
      case 3:
        return (
          <AdditionalQuestions
            job={job}
            formik={formik}
            page={page}
            setPage={setPage}
          />
        )
      case 4:
        return (
          <Preview
            user={user}
            formik={formik}
            page={page}
            setPage={setPage}
            job={job}
          />
        )
      default:
    }
  }

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="modal-modal-title"
      disableScrollLock
    >
      <Box sx={style}>
        <Box sx={{ position: "relative" }}>
          <IconButton
            sx={{ position: "fixed", top: "0px", right: "0px" }}
            onClick={() => {
              setShowModal(false)
            }}
          >
            <FiX />
          </IconButton>
        </Box>

        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ textAlign: "center", fontWeight: "700" }}
        >
          {`Apply to ${titleCase(job?.job?.company_name)}`}
        </Typography>

        {displayStep(page)}
      </Box>
    </Modal>
  )
}

export default JobApplyModal
