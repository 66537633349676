import React from "react"
import { Link } from "react-router-dom"
import Select from "react-select"
import { jobRoles } from "../../utils/data"

function AssessmentHomePage({
  currentStep,
  setCurrentStep,
  setJobRole,
  jobrole,
  roles,
}) {
  const inputStyles =
    "text-black text-sm px-2 py-1.5 border border-gray-300 rounded w-full placeholder:text-gray-400 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-0"

  const labelStyles = "block text-gray-600 font-semibold text-sm"
  const selectStyles = {
    option: provided => ({
      ...provided,
      fontSize: "14px",
      fontWeight: "400",
      color: "rgb(75, 85, 99)",
      padding: "6px 8px",
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: "14px",
      fontWeight: "400",
      color: "rgb(156, 163, 175)",
    }),
    input: provided => ({
      ...provided,
      fontSize: "14px",
      fontWeight: "400",
    }),
    multiValue: provided => ({
      ...provided,
      fontSize: "14px",
      fontWeight: "400",
    }),
    singleValue: provided => ({
      ...provided,
      fontSize: "14px",
      fontWeight: "400",
    }),
    menu: provided => ({
      ...provided,
      maxHeight: "100px",
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: "100px",
    }),
  }

  return (
    <>
      <div className="text-slate-900 border-solid border-1 border-gray-600 mx-auto my-16 px-8 py-6 drop-shadow-xl shadow-2xl w-full md:w-fit">
        <h1 className="text-lg py-6 text-center font-['Quicksand']">
          Choose A Job Role To Create Assessment
        </h1>

        <div className="w-full sm:pl-2 sm:pt-0 pl-0 pt-2 space-y-2">
          <span className={labelStyles}>JOB ROLES</span>
          <Select
            options={roles}
            defaultValue={jobrole}
            placeholder="Please choose"
            className={labelStyles}
            styles={selectStyles}
            onChange={e => setJobRole(e)}
          />
        </div>

        <div className="flex flex-row justify-between items-center py-3 mt-6">
          <Link to="/admin-dashboard">
            <button className="bg-red-500 py-2 px-3 rounded-2xl shadow-lg text-slate-900 hover:bg-black hover:text-white hover:opacity-90">
              BACK
            </button>
          </Link>
          <button
            className="bg-green-400 py-2 px-3 rounded-2xl shadow-lg text-slate-900 hover:bg-black hover:text-white hover:opacity-90"
            onClick={() => setCurrentStep(currentStep + 1)}
          >
            NEXT
          </button>
        </div>
      </div>
      {/* <Link to='/mailer'>
    <h3 className="text-black font-sm text-center px-8 py-6 drop-shadow-xl">Send video invitation</h3>
    </Link> */}
    </>
  )
}

export default AssessmentHomePage
