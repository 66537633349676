import {
  Box,
  MenuItem,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import React from "react";
import { jobType, salaryType, job_option } from "../utils/data";
import { useNavigate } from "react-router";
import { useState } from "react";
import LandingHeader from "../components/LandingPage/LandingHeader";
import axios from "../api/axios";
import { Button } from "@mui/material";
import * as yup from "yup";
import { BsToggleOn, BsToggleOff } from "react-icons/bs";
import { useFormik } from "formik";
import Swal from "sweetalert2";

const validationSchema = yup.object({
  first_name: yup
    .string("Enter your first name")
    .required("This field is required")
    .min(3, "First name must be at least 3 characters"),
  last_name: yup
    .string("Enter your last name")
    .required("This field is required")
    .min(3, "Last name must be at least 3 characters"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("This field is required"),
  job_country: yup
    .string("Enter country of your choice")
    .required("This field is required")
    .min(3, "Job country must be at least 3 characters"),
  job_city: yup
    .string("Enter city of your choice")
    .required("This field is required")
    .min(3, "Job city must be at least 3 characters"),
  job_option: yup
    .string("Enter your job option")
    .required("This field is required"),
  job_type: yup
    .string("Select your job type")
    .required("An option is required"),
  job_salary: yup
    .string("Select your job salary option")
    .required("An option is required"),
});

export const CreateJobAlertPage = () => {
  const navigate = useNavigate();
  const [queryJob, setQueryJob] = useState("");
  const [queryCompany, setQueryCompany] = useState("");
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [turnOn, setTurnOn] = useState(false);
  let [message, setMessage] = useState("");
  const [typedValue, setTypedValue] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      job_country: "",
      job_city: "",
      job_option: "",
      job_type: "",
      job_salary: "",
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      const res = await axios
        .post("/jobAlert", {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          job_country: values.job_country,
          job_city: values.job_city,
          job_option: values.job_option,
          job_type: values.job_type,
          job_salary: values.job_salary,
          receive_alert: turnOn,
        })
        .catch((err) => {
          setMessage(err.response.data.message);
        });
      if (res) {
        resetForm();
        Swal.fire({
          toast: true,
          icon: "success",
          title: "Job Alert Created Successfully",
          position: "top",
          timer: 3000,
          showConfirmButton: false,
        });
        navigate("/user-login");
      }
    },
  });

  const handleInputChange = (event, value) => {
    setTypedValue(value);
  };

  const handleOptionChange = (event, value) => {
    setSelectedOption(value);
    formik.setFieldValue("job_option", value ? value.value : "");
  };

  const handleBlur = () => {
    if (!selectedOption && typedValue) {
      const newOption = {
        label: typedValue,
        value: typedValue,
        // value: typedValue.toLowerCase().replace(/\s/g, "_"),
        // value: typedValue.trim(),
      };
      setSelectedOption(newOption);
      formik.setFieldValue("job_option", newOption.value);
    }
  };

  console.log({ formik });
  return (
    <div className="flex w-full min-h-screen bg-slate-50 font-roboto relative">
      <LandingHeader setQuery={setQuery} />
      <div className="xl:px-16 mt-24 md:mt-16 px-8 py-10 w-full min-h-screen bg-no-repeat bg-cover bg-fixed bg-center bg-[url('/public/techteam2.jpg')]">
        <div className="bg-white mt-8 py-2 pb-10 px-10 rounded-md shadow space-y-3 opacity-80">
          <Box>
            <Box borderBottom="1px solid black">
              <Typography
                fontWeight="600"
                variant="h5"
                color="black"
                sx={{
                  mt: "1rem",
                  textAlign: {
                    xs: "center",
                    sm: "left",
                  },
                }}
              >
                CREATE JOB ALERT
              </Typography>
              <div>
                {message ? (
                  <p className="text-danger text-red-600 text-base">
                    {message}
                  </p>
                ) : null}
              </div>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              sx={{
                mt: "1rem",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
              }}
            >
              <Box
                sx={{
                  width: {
                    xs: "100%",
                    sm: "47%",
                  },
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="600"
                  color="black"
                  sx={{ mt: "0.5rem" }}
                >
                  PERSONAL INFORMATION
                </Typography>
                <Box
                  sx={{
                    mt: {
                      sm: "2rem",
                      md: "0.5rem",
                    },
                  }}
                >
                  <div>
                    <TextField
                      fullWidth
                      type="text"
                      size="small"
                      variant="outlined"
                      id="first_name"
                      name="first_name"
                      label="First Name"
                      value={formik.values.first_name}
                      onChange={formik.handleChange("first_name")}
                      onBlur={formik.handleBlur}
                    />
                    <div className="text-danger text-red-600 text-base">
                      {formik.touched.first_name && formik.errors.first_name}
                    </div>
                  </div>
                  <div>
                    <TextField
                      fullWidth
                      type="text"
                      size="small"
                      variant="outlined"
                      id="last_name"
                      name="last_name"
                      label="Last Name"
                      value={formik.values.last_name}
                      onChange={formik.handleChange("last_name")}
                      onBlur={formik.handleBlur}
                      sx={{ mt: "1rem" }}
                    />
                    <div className="text-danger text-red-600 text-base">
                      {formik.touched.last_name && formik.errors.last_name}
                    </div>
                  </div>
                  <div>
                    <TextField
                      fullWidth
                      type="email"
                      size="small"
                      variant="outlined"
                      id="email"
                      name="email"
                      label="Email"
                      value={formik.values.email}
                      onChange={formik.handleChange("email")}
                      onBlur={formik.handleBlur}
                      placeholder="name@gmail.com"
                      sx={{ mt: "1rem" }}
                    />
                    <div className="text-danger text-red-600 text-base">
                      {formik.touched.email && formik.errors.email}
                    </div>
                  </div>
                  <Box>
                    <Typography
                      variant="body1"
                      fontWeight="600"
                      color="black"
                      sx={{ mt: "0.5rem" }}
                    >
                      JOB LOCATION
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      mt="0.5rem"
                      sx={{
                        flexDirection: {
                          xs: "column",
                          sm: "row",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          width: {
                            xs: "100%",
                            sm: "49%",
                          },
                        }}
                      >
                        <TextField
                          fullWidth
                          type="text"
                          size="small"
                          variant="outlined"
                          id="job_country"
                          name="job_country"
                          label="Country"
                          placeholder="Country"
                          value={formik.values.job_country}
                          onChange={formik.handleChange("job_country")}
                          onBlur={formik.handleBlur}
                        />
                        <div className="text-danger text-red-600 text-base">
                          {formik.touched.job_country &&
                            formik.errors.job_country}
                        </div>
                      </Box>
                      <Box
                        sx={{
                          width: {
                            xs: "100%",
                            sm: "49%",
                          },
                          mt: {
                            xs: "1rem",
                            sm: "0",
                          },
                        }}
                      >
                        <TextField
                          fullWidth
                          type="text"
                          size="small"
                          variant="outlined"
                          id="job_city"
                          name="job_city"
                          label="City"
                          value={formik.values.job_city}
                          onChange={formik.handleChange("job_city")}
                          onBlur={formik.handleBlur}
                          placeholder="City"
                        />
                        <div className="text-danger text-red-600 text-base">
                          {formik.touched.job_city && formik.errors.job_city}
                        </div>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: {
                    xs: "100%",
                    sm: "47%",
                  },
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="600"
                  color="black"
                  sx={{ mt: "0.5rem" }}
                >
                  WHAT KIND OF JOBS ARE YOU LOOKING FOR?
                </Typography>
                <Box sx={{ mt: "0.5rem" }}>
                  <div>
                    <Autocomplete
                      fullWidth
                      size="small"
                      options={job_option}
                      getOptionLabel={(option) => option.label}
                      id="job_option"
                      name="job_option"
                      value={selectedOption}
                      inputValue={typedValue}
                      onInputChange={handleInputChange}
                      onChange={handleOptionChange}
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Job Option (Kindly input any job option that is not provided by the list)"
                          placeholder="e.g Software Engineering, Product Designer"
                          sx={{ mt: "1rem" }}
                        />
                      )}
                    />
                    <div className="text-danger text-red-600 text-base">
                      {formik.touched.job_option && formik.errors.job_option}
                    </div>
                  </div>

                  <div>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      id="job_type"
                      name="job_type"
                      label="Job Type"
                      value={formik.values.job_type}
                      onChange={formik.handleChange("job_type")}
                      onBlur={formik.handleBlur}
                      select
                      SelectProps={{ MenuProps: { disableScrollLock: true } }}
                      sx={{ mt: "1rem" }}
                    >
                      {jobType.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <div className="text-danger text-red-600 text-base">
                      {formik.touched.job_type && formik.errors.job_type}
                    </div>
                  </div>
                  <div>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      id="job_salary"
                      name="job_salary"
                      label="Salary"
                      value={formik.values.job_salary}
                      onChange={formik.handleChange("job_salary")}
                      onBlur={formik.handleBlur}
                      sx={{ mt: "1rem" }}
                      select
                      SelectProps={{ MenuProps: { disableScrollLock: true } }}
                    >
                      {salaryType.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <div className="text-danger text-red-600 text-base">
                      {formik.touched.job_salary && formik.errors.job_salary}
                    </div>
                  </div>
                  <Box
                    display="flex"
                    mt="1.6rem"
                    justifyContent="space-between"
                  >
                    <Button
                      variant="outlined"
                      type="submit"
                      onClick={(e) => {
                        formik.handleSubmit(e);
                      }}
                      sx={{
                        color: "white",
                        backgroundColor: "#3F51B5",
                        "&:hover": {
                          backgroundColor: "#3F51B5",
                        },
                        mt: "1rem",
                      }}
                    >
                      Submit
                    </Button>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      sx={{
                        mt: "1.3rem",
                        width: {
                          xs: "62%",
                          md: "43%",
                          lg: "35%",
                        },
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontWeight="400"
                        color="black"
                        sx={{
                          pt: "0.3rem",
                        }}
                      >
                        Turn on/off job alert?
                      </Typography>
                      <Box className="on-toggle-icon">
                        {turnOn ? (
                          <BsToggleOn
                            color=" green"
                            size={30}
                            // icon={turnOn ? "turn-off" : "turn-on"}
                            onClick={() => setTurnOn(!turnOn)}
                          />
                        ) : (
                          <BsToggleOff
                            color=" #1E3A8A"
                            size={30}
                            // icon={turnOn ? "turn-off" : "turn-on"}
                            onClick={() => setTurnOn(!turnOn)}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
};
export default CreateJobAlertPage;
