import { useEffect, useState } from "react"
import { useParams, Outlet, useNavigate } from "react-router-dom"
import axios from "../api/axios"
import Loading from "./Loading"

const VerifyAdminRole = () => {
  const [data, setData] = useState()
  let params = useParams()
  let navigate = useNavigate()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const request = {
      signal: controller.signal,
    }

    const getData = async () => {
      const res = await axios
        .get(`admins/verify-email/${params?.verificationCode}`, request)
        .catch(error => {
          console.error(error?.response)
        })
      if (res) {
        isMounted && setData(res?.data?.data)
      }
    }

    getData()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  const verifyAdmin = async () => {
    const res = await axios
      .post(`admins/verify-admin`, {
        verification_code: params?.verificationCode,
      })
      .catch(err => {
        console.error(err?.response)
      })

    if (res) {
      // navigate("/admin-login")
      window.close()
    }
  }

  if (data?.role === "admin") {
    return <Outlet />
  }

  if (data?.role === "superAdmin") verifyAdmin()

  return <Loading />
}

export default VerifyAdminRole
