import { useEffect, useState } from "react"
import { useParams, useNavigate, useLocation, Link } from "react-router-dom"
import CandidateLayout from "../components/CandidateLayout"
import { ImSpinner3 } from "react-icons/im"
import { FaBriefcase } from "react-icons/fa"
import { FaLongArrowAltLeft, FaUser } from "react-icons/fa"
import parse from "html-react-parser"
import { titleCase } from "../utils/helpers"
import CandidateHeader from "../components/CandidateHeader"
import useAuth from "../hooks/useAuth"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import useProfileChecker from "../hooks/useProfileChecker"
import { Helmet } from "react-helmet-async"

const AppliedJobDescription = () => {
  const [data, setData] = useState()
  let params = useParams()

  const location = useLocation()
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()

  const profileChecker = useProfileChecker()
  profileChecker()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const request = {
      signal: controller.signal,
      headers: {
        authorization: `Bearer ${auth?.accessToken}`,
      },
    }

    const getData = async () => {
      const response = await axiosPrivate
        .get(`jobs/${params.title}`, request)
        .catch(error => {
          console.error(error?.response)
        })
      if (response?.data) {
        isMounted && setData(response?.data?.data)
      }
    }

    getData()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  return (
    <CandidateLayout page="applications">
      <Helmet>
        <title>
          {data
            ? `${data?.job.job_title} | ${data?.job.company_name} | Edge`
            : "Edge"}
        </title>
      </Helmet>

      <CandidateHeader page="candidate-dashboard" />
      {data ? (
        <div className="px-12 py-6 space-y-4">
          <div className="bg-white py-6 px-4 rounded-md shadow">
            <Link
              to={`/my-applications`}
              className="flex py-1.5 px-6 text-black items-center hover:bg-gray-200 hover:w-1/5 hover:rounded-2xl"
            >
              <FaLongArrowAltLeft className="mr-2" /> Go Back
            </Link>
            <div className="py-3 text-black">
              <div className="space-y-4">
                <div>
                  <p className="text-2xl font-semibold">
                    {`${
                      data.job.job_level === "Intermediate"
                        ? ""
                        : data.job.job_level
                    } ${titleCase(data.job.job_title)}`}
                  </p>
                  <span className="text-sm">{`${titleCase(
                    data.job.company_name
                  )}. ${titleCase(data.job.job_city)}, ${titleCase(
                    data.job.job_country
                  )} (${titleCase(data.job.employment_type)})`}</span>
                </div>

                <div>
                  <span className="flex items-center">
                    <FaBriefcase size={24} className="mr-2" />
                    <p>{titleCase(data.job.contract_type)}</p>
                  </span>
                </div>

                <div className="list-disc">
                  {parse(data.job.job_description)}
                </div>

                {data.job.salary_range ? (
                  <div>
                    <p className="text-base underline font-bold">
                      Salary Range
                    </p>
                    <p>{data.job.salary_range.toUpperCase()}</p>
                  </div>
                ) : (
                  <div>
                    <p>Pay range unavailable</p>
                    <p className="text-sm text-gray-600">
                      Salary information is not available at the moment.
                    </p>
                  </div>
                )}
                <div>
                  <p className="text-base underline font-bold">
                    Application Deadline
                  </p>
                  <p>{titleCase(data.job.application_deadline)}</p>
                </div>

                <div>
                  <p className="text-base underline font-bold">
                    Additional Questions
                  </p>
                  {data.screeningQuestions.map((question, index) => (
                    <ul key={index} className="mb-1 list-disc px-4">
                      {question.screening_question_group ===
                      "tool or technology" ? (
                        <li>{`How many years of experience do you have using ${titleCase(
                          question.screening_question_value
                        )}?`}</li>
                      ) : null}

                      {question.screening_question_group === "language" ? (
                        <li>{`What is your level of proficiency in ${titleCase(
                          question.screening_question_value
                        )}?`}</li>
                      ) : null}

                      {question.screening_question_group === "industry" ? (
                        <li>{`How many years of ${titleCase(
                          question.screening_question_value
                        )} experience do you currently have?`}</li>
                      ) : null}

                      {question.screening_question_group === "custom" && (
                        <li>{question.screening_question_value}</li>
                      )}
                    </ul>
                  ))}
                </div>
                <button className="bg-blueBtn text-slate-300 py-1 px-3 rounded-lg h-full text-sm">
                  Applied
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-screen -mt-16">
          <span className="flex text-black space-x-3 items-center justify-center">
            <ImSpinner3 size={48} className="animate-spin" />
            <p className="text-3xl">Loading</p>
          </span>
        </div>
      )}
    </CandidateLayout>
  )
}

export default AppliedJobDescription
