import React, { useState } from "react"
import { FiX } from "react-icons/fi"
//import { titleCase } from "../../utils/helpers"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
//import axios from "../../api/axios";
//import axios from "axios";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import useAuth from "../../hooks/useAuth"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
//import Select from "react-select";
//import { type } from "../../utils/data";

const LeaveRequest = ({ setShowModal }) => {
  const { auth } = useAuth()
  const navigate = useNavigate()

  const inputStyles =
    "text-black text-sm px-2 py-1.5 border border-gray-300 rounded w-full placeholder:text-gray-400 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-0"

  const labelStyles = "block text-gray-600 text-sm"

  const selectStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: "400",
      color: "rgb(75, 85, 99)",
      padding: "6px 8px",
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: "14px",
      fontWeight: "400",
      color: "rgb(156, 163, 175)",
    }),
    input: provided => ({
      ...provided,
      fontSize: "14px",
      fontWeight: "400",
    }),
    multiValue: provided => ({
      ...provided,
      fontSize: "14px",
      fontWeight: "400",
    }),
    singleValue: provided => ({
      ...provided,
      fontSize: "14px",
      fontWeight: "400",
    }),
    menu: provided => ({
      ...provided,
      maxHeight: "100px",
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: "100px",
    }),
  }

  //Init state variables
  const [email, setEmail] = useState("")
  //const [selectedType, setSelectedType] = useState(type);
  const [type, setType] = useState("")
  const [name, setName] = useState("")
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  const leaveData = {
    name,
    email,
    type,
    startDate,
    endDate,
    text: "Submit",
  }

  const emailChanged = e => {
    setEmail(e.target.value)
  }

  const nameChanged = e => {
    setName(e.target.value)
  }

  const typeChanged = e => {
    setType(e.target.value)
  }

  // const startDateChanged = e => {
  //   setStartDate(e.target.value)

  // };

  // const endDateChanged = e => {
  //   setEndDate(e.target.value);

  // };

  const onFormSubmit = event => {
    event.preventDefault()
  }
  const axiosPrivate = useAxiosPrivate()
  const request = {
    headers: {
      authorization: `Bearer ${auth.accessToken}`,
    },
  }

  const leaveRequestHandler = async e => {
    //e.preventDefault(); //Prevent refresh on button click
    //const Data = LeaveData;
    //console.log(Data)
    const res = await axiosPrivate
      .post(
        `leave/${auth.id}`,
        {
          name: leaveData.name,
          email: leaveData.email,
          type: leaveData.type,
          start_date: leaveData.startDate,
          end_date: leaveData.endDate,
        },
        request
      )
      .then(res => {
        if (res.status === 201) {
          Swal.fire({
            toast: true,
            icon: "success",
            text: res.data.message,
            position: "top",
            timer: 3000,
            showConfirmButton: false,
          })
          navigate("/candidate-dashboard/")
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          Swal.fire({
            toast: true,
            icon: "error",
            title: "Oops..",
            text: "An error occurred. Please try again",
            position: "top",
            timer: 3000,
            showConfirmButton: false,
          })
        } else {
          Swal.fire({
            toast: true,
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
            position: "top",
            timer: 3000,
            showConfirmButton: false,
          })
        }
      })
  }

  return (
    <>
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
        {/**modal content */}
        <div className="relative top-20 mx-auto p-5 border lg:w-1/2 sm:w-3/5 w-full shadow-xl rounded-md bg-white">
          <button
            className="absolute top-3 right-5 p-2.5 text-sm text-center text-black"
            onClick={() => setShowModal(false)}
          >
            <FiX size={20} />
          </button>
          <form className="flex flex-col  w-full" onSubmit={onFormSubmit}>
            <div>
              <label htmlFor="name" className={labelStyles}>
                Name
              </label>
              <input
                type="text"
                id="name"
                value={name}
                autoComplete="off"
                className={inputStyles}
                onChange={nameChanged}
              />
            </div>
            <div>
              <label htmlFor="email" className={labelStyles}>
                Email
              </label>
              <input
                type="text"
                id="reason"
                //placeholder={}
                value={email}
                autoComplete="off"
                className={inputStyles}
                onChange={emailChanged}
              />
            </div>
            <div>
              <label htmlFor="type" className={labelStyles}>
                Type
              </label>
              {/* <Select
                options={type}
                placeholder={selectedType?.type}
                defaultValue={selectedType}
                onChange={e => typeChanged(e)}
                className={inputStyles}
                //className={selectStyles}
              /> */}
              <select
                styles={selectStyles}
                className={inputStyles}
                onChange={typeChanged}
              >
                <option>Select Leave Type</option>
                <option type="Vacation">Vacation</option>
                <option type="Wedding">Wedding</option>
                <option type="Sick">Sick</option>
              </select>
            </div>
            <div>
              <label htmlFor="startDate" className={labelStyles}>
                Start Date
              </label>
              <DatePicker
                selected={startDate}
                id="startDate"
                value={startDate}
                className={inputStyles}
                onChange={date => setStartDate(date)}
              />
            </div>
            <div>
              <label htmlFor="endDate" className={labelStyles}>
                End Date
              </label>
              <DatePicker
                selected={endDate}
                className={inputStyles}
                id="endDate"
                value={endDate}
                onChange={date => setEndDate(date)}
              />
            </div>
            <button
              className="py-1.5 px-6 rounded-full bg-blueBtn  text-white"
              type={"submit"}
              onClick={leaveRequestHandler}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  )
}

export default LeaveRequest
