import { Box, IconButton, Modal, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { FiX } from "react-icons/fi"
import useAuth from "../../hooks/useAuth"
import axios from "../../api/axios"

const style = {
  position: "absolute",
  top: "20%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", sm: "50%" },
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: "0px",
  boxShadow: 24,
  px: 4,
  py: 2,
  outline: "none",
  overflow: "auto",
  color: "black",
}

const AssessmentScore = ({ showModal, setShowModal, query }) => {
  const [score, setScore] = useState()
  const { auth } = useAuth()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const getScore = async () => {
      const res = await axios
        .get(`assessment/scores/${auth?.id}/${query?.id}`)
        .catch(err => console.error(err.response))

      if (res) {
        isMounted && setScore(res.data.data)
      }
    }

    getScore()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [auth])

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="modal-modal-title"
    >
      <Box sx={style}>
        <Box sx={{ position: "relative" }}>
          <IconButton
            sx={{ position: "fixed", top: "0px", right: "0px" }}
            onClick={() => {
              setShowModal(false)
              // navigate(0)
            }}
          >
            <FiX />
          </IconButton>
        </Box>

        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ textAlign: "center", fontWeight: "700" }}
        >
          Assessment Result
        </Typography>

        <Box>
          <Typography>Role: {score?.role}</Typography>
          <Typography>Score: {score?.score}</Typography>
        </Box>
      </Box>
    </Modal>
  )
}

export default AssessmentScore
