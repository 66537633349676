import React, {useState, useEffect} from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import "react-big-calendar/lib/css/react-big-calendar.css";
import './style.scss';


const localizer = momentLocalizer(moment);

const BigCalendar = () => {
     
  const [data, setData] = useState([]);
  const { auth } = useAuth();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const request = {
      signal: controller.signal,
      headers: {
        authorization: `Bearer ${auth?.accessToken}`,
      },
    };

    const fetchData = async () => {
      const res = await axios.get(`leave/${auth.id}`, request).catch((err) => {
        console.error(err.response);
      });

      if (res?.data?.data) {
        console.log(res?.data?.data);
        isMounted && setData(res.data?.data);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [auth]);
  
   
    return (
        <div>
       <Calendar
        className={'Calendar'}
        selectable
        localizer={localizer}
        events={data?.map((item)  => ({
          user_id: item?.user_id,
          id: item?.id,
          allDay: true,  
          end: new Date(item?.end_date),
          start: new Date(item?.start_date),
          title: `${item?.type} by ${item?.name}`,
      }))
      }
        startAccessor="start"
        endAccessor="end"
        eventPropGetter={event => ({
            style: {
                backgroundColor: event.color
            },
        })}
        style={{ height: 500 }}
        titleAccessor="title"
      />
    </div>
    );
    
    }
    export default BigCalendar;
