import React, { useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import Swal from "sweetalert2"
import useAuth from "../hooks/useAuth"
import useAxiosPrivate from "../hooks/useAxiosPrivate"

const Upload = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const from = location?.state?.from?.pathname || "/applicants"
  const [file, setFile] = useState("")
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()

  const handleOnChange = e => {
    setFile(e.target.files[0])
  }

  const onSubmit = async e => {
    e.preventDefault()
    const formData = new FormData()
    formData.append("applicants", file)

    axiosPrivate
      .post("applicants/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${auth.accessToken}`,
        },
      })
      .then(res => {
        if (res.status === 201) {
          Swal.fire({
            icon: "success",
            title: "Bulk upload  Successful",
            text: res.data.message,
          })
          navigate(from, { replace: true })
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          Swal.fire({
            icon: "error",
            title: "Oops..",
            text: "An error occurred. Please try again",
          })
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
          })
        }
      })
  }

  return (
    <div className="w-full text-slate-700">
      <div className="w-full">
        <form action="" onSubmit={onSubmit} className="flex justify-center">
          <div className="space-y-4 space-x-3 flex flex-col items-center sm:w-1/2 w-full">
            <span className="py-6 ml-2 text-center">File Bulk Upload</span>
            <input
              type="file"
              name="bulk"
              id="file"
              className="w-full border border-gray-300 p-2 md:text-small"
              onChange={handleOnChange}
              accept=".csv"
            />
            <button
              className=" w-1/2 mx-auto py-2 border-2 border-slate-700 rounded-md hover:bg-slate-200"
              onClick={e => {
                onSubmit(e)
              }}
            >
              SUBMIT
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Upload
