import { useEffect, useState } from "react"
import { useParams, useNavigate, Link } from "react-router-dom"
import { ImSpinner3 } from "react-icons/im"
import { FaBriefcase } from "react-icons/fa"
import { FaLongArrowAltLeft } from "react-icons/fa"
import parse from "html-react-parser"
import {
  changeApplicationDeadlineFormat,
  changeDateFormat,
  titleCase,
} from "../utils/helpers"
import axios from "../api/axios"
import { Helmet } from "react-helmet-async"

function HomeJobDescription() {
  const [data, setData] = useState()
  const [myJobs, setMyJobs] = useState([])
  let params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const request = {
      signal: controller.signal,
    }

    const getData = async () => {
      const response = await axios
        .get(`jobs/${params.id}`, request)
        .catch(error => {
          console.error(error?.response)
        })

      if (response?.data) {
        isMounted && setData(response?.data?.data)
      }
    }

    const getMyJobs = async () => {
      const res = await axios.get(`/applications/`, request).catch(error => {
        console.error(error?.res)
      })
      if (res?.data) {
        isMounted && setMyJobs(res?.data?.data)
      }
    }

    getData()
    getMyJobs()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [params])

  const currentDate = new Date()
  const jobDeadline = new Date(data?.job.application_deadline)
  const isApplicationDeadlinePassed =
    currentDate.getTime() >= jobDeadline.getTime()

  const inputDateStr = new Date(data?.job.application_deadline)
  const inputDate = new Date(inputDateStr)
  const day = inputDate.getDate()
  const month = inputDate.getMonth() + 1
  const year = inputDate.getFullYear()

  const outputDateStr = `${day.toString().padStart(2, "0")}/${month
    .toString()
    .padStart(2, "0")}/${year.toString()}`

  return (
    <div className="bg-slate-50 min-h-screen bg-no-repeat bg-cover bg-center bg-[url('/public/techteam2.jpg')]">
      <Helmet>
        <title>
          {data
            ? `${data?.job.job_title} | ${data?.job.company_name} | Edge`
            : "Edge"}
        </title>
      </Helmet>

      {data ? (
        <div className="xl:px-12 sm:px-8 px-4 py-6 space-y-4">
          <button
            onClick={() => navigate(-1)}
            className="bg-white flex py-1.5 px-6 text-black items-center border-2 rounded-2xl hover:bg-blue-200 hover:rounded-2xl"
          >
            <FaLongArrowAltLeft className="mr-2" />
            Go back
          </button>
          <div className="bg-white py-6 px-4 rounded-md shadow">
            <div className="py-3 text-black">
              <div className="space-y-4 px-6">
                <div className="flex">
                  {/* company logo */}
                  <div className="mr-4">
                    {data?.job.company_logo ? (
                      <div className="w-12 h-12 md:w-20 md:h-20 bg-white flex items-center">
                        <img
                          crossOrigin="anonymous"
                          src={`${process.env.REACT_APP_BASE_URL}storage/images/${data?.job.company_logo}`}
                          alt=""
                        />
                      </div>
                    ) : (
                      <div className="w-12 h-12 md:w-20 md:h-20 bg-gray-100"></div>
                    )}
                  </div>

                  {/* job title, company, city and employment type */}
                  <div>
                    <p className="text-2xl font-semibold">
                      {`${
                        data.job.job_level === "Intermediate"
                          ? ""
                          : data.job.job_level
                      } ${titleCase(data.job.job_title)}`}
                    </p>
                    <span className="text-sm">{`${titleCase(
                      data.job.company_name
                    )}. ${titleCase(data.job.job_city)}, ${titleCase(
                      data.job.job_country
                    )} (${titleCase(data.job.employment_type)})`}</span>
                  </div>
                </div>

                {/* contract type */}
                <div>
                  <span className="flex items-center">
                    <FaBriefcase size={24} className="mr-2" />
                    <p>{titleCase(data.job.contract_type)}</p>
                  </span>
                </div>

                {/* job description */}
                <div className="list-disc">
                  {parse(data.job.job_description)}
                </div>

                {/* salary range */}
                {data.job.salary_range ? (
                  <div>
                    <p className="text-base underline font-bold">
                      Salary Range
                    </p>
                    <p>{`${
                      data.job.salary_range
                    } ${data.job.currency.toUpperCase()}`}</p>
                  </div>
                ) : (
                  <div>
                    <p>Pay range unavailable</p>
                    <p className="text-sm text-gray-600">
                      Salary information is not available at the moment.
                    </p>
                  </div>
                )}

                {/* application deadline */}
                <div>
                  <p className="text-base underline font-bold">
                    Application Deadline
                  </p>
                  <p>{titleCase(outputDateStr)}</p>
                </div>

                {/* screening questions */}
                {data.screeningQuestions.length > 0 && (
                  <div>
                    <p className="text-base underline font-bold">
                      Additional Questions
                    </p>
                    {data.screeningQuestions.map((question, index) => (
                      <ul key={index} className="mb-1 list-disc px-4">
                        {question.screening_question_group ===
                        "tool or technology" ? (
                          <li>{`How many years of experience do you have using ${titleCase(
                            question.screening_question_value
                          )}?`}</li>
                        ) : null}

                        {question.screening_question_group === "language" ? (
                          <li>{`What is your level of proficiency in ${titleCase(
                            question.screening_question_value
                          )}?`}</li>
                        ) : null}

                        {question.screening_question_group === "industry" ? (
                          <li>{`How many years of ${titleCase(
                            question.screening_question_value
                          )} experience do you currently have?`}</li>
                        ) : null}

                        {question.screening_question_group === "custom" && (
                          <li>{question.screening_question_value}</li>
                        )}
                      </ul>
                    ))}
                  </div>
                )}

                {/* apply button */}
                {isApplicationDeadlinePassed ? (
                  <button
                    disabled
                    className="bg-slate-500 disabled:text-white text-white py-1.5 px-4 rounded-2xl"
                  >
                    Apply
                  </button>
                ) : (
                  <button
                    id="apply-button"
                    disabled={myJobs.includes(data?.job.id)}
                    className="bg-blueBtn  disabled:bg-slate-500 disabled:text-white text-white py-1.5 px-4 rounded-2xl"
                    onClick={() => {
                      navigate("/user-login", {
                        state: {
                          prevURL: `/job-description/${data?.job.id}/${params.title}`,
                        },
                      })
                    }}
                  >
                    {myJobs.includes(data?.job.id) === true ? (
                      <p>Applied</p>
                    ) : (
                      <p>Apply</p>
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-screen">
          <span className="flex text-black space-x-3 items-center justify-center">
            <ImSpinner3 size={48} className="animate-spin" />
            <p className="text-3xl">Loading. . .</p>
          </span>
        </div>
      )}
    </div>
  )
}

export default HomeJobDescription
