import { Link } from "react-router-dom"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"

const AssessmentDataCard = ({ data }) => {
  return (
    <div className="bg-white border border-gray-200 rounded-md w-full p-4 shadow-md text-black flex flex-col items-center mx-auto">
      <p className="mt-4 font-medium">{data?.role}</p>

      <Link to={`/assessment/${data?.id}`}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#3F51B5",
              mt: "1rem",
              py: "0.375rem",
              px: "0.75rem",
              "&:hover": {
                backgroundColor: "#3F51B5",
              },
              textTransform: "none",
            }}
          >
            View Assessment
          </Button>
        </Box>
      </Link>
    </div>
  )
}

export default AssessmentDataCard
