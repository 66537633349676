import { bgColors } from "../../utils/bgColors"
import { titleCase } from "../../utils/helpers"
import moment from "moment"

const Comment = ({ item }) => {
  return (
    <div>
      <div className="flex w-full gap-2">
        <div
          className={`${
            item?.name === "guest"
              ? bgColors["guest"]
              : bgColors[`${item?.name?.charAt(0)}`]
          } w-8 h-8 rounded-full text-white flex items-center justify-center`}
        >
          <p className="text-sm">{item?.name?.charAt(0).toUpperCase()}</p>
        </div>

        <div className="flex-1">
          <p className="text-sm text-slate-800">{titleCase(item?.name)}</p>

          <div className="text-slate-800 text-xs w-full flex flex-col gap-1 items-end">
            <div className="w-full">
              <span className="text-slate-800 break-words">
                {item?.comment}
              </span>
            </div>

            <div className="">
              <span className="text-gray-400">
                {moment(item?.created_at).fromNow()}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Comment
