import { useState } from "react"
import { Modal, Box, IconButton } from "@mui/material"
import * as yup from "yup"
import { useFormik } from "formik"
import useAuth from "../../hooks/useAuth"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import { FiX } from "react-icons/fi"
import Personal from "./Personal"
import Details from "./Details"
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", sm: "50%" },
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: "0px",
  boxShadow: 24,
  px: 4,
  py: 2,
  outline: "none",
  overflow: "auto",
}

const personalSchema = yup.object().shape({
  first_name: yup.string().required("This field is required"),
  last_name: yup.string().required("This field is required"),
  email: yup.string().email().required("This field is required"),
  phone_number: yup.string().required("This field is required"),
  country: yup.string().required("This field is required"),
  linkedin: yup.string().url().required("This field is required"),
  github: yup.mixed().optional(),
})

const detailsSchema = yup.object().shape({
  resume: yup.mixed().required("Choose a file"),
  cover_letter: yup.mixed().optional(),
  position: yup.string().required("This field is required"),
  salary: yup.string().required("This field is required"),
  experience: yup.string().required("This field is required"),
  notice: yup.string().required("This field is required"),
  availability: yup.string().required("This field is required"),
  contract_type: yup.string().required("This field is required"),
  status: yup.string().required("This field is required"),
})

const EditApplicant = ({ openModal, setOpenModal, applicantData }) => {
  const [currentStep, setCurrentStep] = useState(1)
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()

  const request = {
    headers: {
      authorization: `Bearer ${auth.accessToken}`,
    },
  }

  const salary = applicantData.application.salary.split(" ")
  const experience = applicantData.application.experience.split(" ")
  const notice = applicantData.application.notice_period.split(" ")

  console.log({ salary, experience, notice })

  const formik = useFormik({
    initialValues: {
      first_name: applicantData.applicant.first_name,
      last_name: applicantData.applicant.last_name,
      email: applicantData.applicant.email,
      phone_number: applicantData.applicant.phone_number,
      country: applicantData.applicant.country,
      linkedin: applicantData.applicant.linkedin,
      github: applicantData.applicant.github,
      resume: applicantData.application.resume,
      cover_letter: applicantData.application.cover_letter,
      position: applicantData.application.job_role,
      salary: salary[0],
      currency: "NGN",
      experience: experience[0],
      notice: notice[0],
      availability: applicantData.application.job_availability,
      contract_type: applicantData.application.job_type,
      status: applicantData.application.status,
    },
    validationSchema: currentStep === 2 ? detailsSchema : personalSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      if (currentStep < 2) {
        setCurrentStep(currentStep + 1)
      }

      if (currentStep === 2) {
        if (values.resume && typeof values.resume === "object") {
          const formData = new FormData()
          formData.append("resume", values.resume)
          await axiosPrivate
            .post(`storage/upload-resume`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                authorization: `Bearer ${auth?.accessToken}`,
              },
            })
            .catch(err => console.error(err))
        }

        if (values.cover_letter && typeof values.cover_letter === "object") {
          const formData = new FormData()
          formData.append("coverLetter", values.cover_letter)
          await axiosPrivate
            .post(`storage/upload-coverLetter`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                authorization: `Bearer ${auth?.accessToken}`,
              },
            })
            .catch(err => console.error(err))
        }

        const response = await axiosPrivate
          .put(
            `applicants/${applicantData.applicant.id}`,
            {
              company_id: auth?.companyId,
              first_name: values.first_name,
              last_name: values.last_name,
              email: values.email,
              phone_number: values.phone_number,
              country: values.country,
              linkedin: values.linkedin,
              github: values.github,
              resume:
                values.resume && typeof values.resume === "object"
                  ? values.resume.name
                  : values.resume,
              cover_letter:
                values.cover_letter && typeof values.cover_letter === "object"
                  ? values.cover_letter.name
                  : values.cover_letter,
              job_role: values.position,
              salary: `${values.salary} ${values.currency}`,
              experience: `${
                values.experience === 1
                  ? `${values.experience} year`
                  : `${values.experience} years`
              }`,
              notice_period: `${
                values.notice === 1
                  ? `${values.notice} week`
                  : `${values.notice} weeks`
              }`,
              job_availability: values.availability,
              job_type: values.contract_type,
              status: values.status,
            },
            request
          )
          .catch(error => {
            Swal.fire({
              toast: true,
              icon: "error",
              title: "Oops...",
              text: error?.response?.data?.message,
              position: "top",
              timer: 3000,
              showConfirmButton: false,
            })
          })

        if (response) {
          resetForm()
          Swal.fire({
            toast: true,
            icon: "success",
            text: response?.data?.message,
            position: "top",
            timer: 3000,
            showConfirmBottom: false,
          })
          setOpenModal(false)
          navigate("/admin-dashboard")
        }
      }
    },
  })

  const displayStep = step => {
    switch (step) {
      case 1:
        return <Personal formik={formik} />
      case 2:
        return (
          <Details
            formik={formik}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        )
      default:
    }
  }

  return (
    <Modal
      open={openModal}
      aria-labelledby="modal-modal-title"
      disableScrollLock
    >
      <Box sx={style}>
        <Box sx={{ position: "relative" }}>
          <IconButton
            sx={{ position: "fixed", top: "0px", right: "0px" }}
            onClick={() => {
              setOpenModal(false)
            }}
          >
            <FiX />
          </IconButton>
        </Box>

        <Box>
          <div className="my-4 py-4">{displayStep(currentStep)}</div>
        </Box>
      </Box>
    </Modal>
  )
}

export default EditApplicant
