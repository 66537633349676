import { FiX } from "react-icons/fi"

const inputStyles =
  "text-black text-sm px-2 py-1.5 border border-gray-300 rounded w-full placeholder:text-gray-400 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-0"

const labelStyles = "block text-gray-600 font-semibold text-sm"

export const Tool = ({
  question,
  index,
  handleOnChange,
  removeQuestion,
  handleCheckbox,
}) => {
  return (
    <div className="rounded-lg border border-gray-200">
      <div className="flex justify-between items-center px-2 py-3 bg-slate-200 rounded-t-lg">
        <span
          className={labelStyles}
        >{`How many years of experience do you have using [${question.group}] ?`}</span>
        <FiX
          size={24}
          onClick={() => removeQuestion(index)}
          className="cursor-pointer"
        />
      </div>
      <div className="flex justify-between items-end px-2 py-4">
        <div className="flex items-center space-x-3">
          <div>
            <label
              htmlFor="question"
              className={labelStyles}
            >{`${question.group} *`}</label>
            <input
              type="text"
              name="question"
              value={question.question}
              onChange={e => handleOnChange(e, index)}
              className={inputStyles}
              key="toolQuestion"
            />
          </div>
          <div>
            <label htmlFor="answer" className={labelStyles}>
              Ideal Answer
            </label>
            <div className="flex items-end">
              <input
                type="text"
                name="answer"
                value={question.answer}
                className={`${inputStyles} w-10 mr-2`}
                onChange={e => handleOnChange(e, index)}
                key="toolAnswer"
              />
              <span className={labelStyles}>minimum</span>
            </div>
          </div>
        </div>
        <div className="flex items-end">
          <input
            type="checkbox"
            name="mustHave"
            className="mr-2 w-5 h-5 accent-green-600"
            value={question.mustHave}
            onChange={e => handleCheckbox(e, index)}
          />
          <label htmlFor="mustHave" className={labelStyles}>
            Must-have qualification
          </label>
        </div>
      </div>
    </div>
  )
}

export const Language = ({
  question,
  index,
  handleOnChange,
  removeQuestion,
  handleCheckbox,
}) => {
  return (
    <div className="rounded-lg border border-gray-200">
      <div className="flex justify-between items-center px-2 py-3 bg-slate-200 rounded-t-lg">
        <span
          className={labelStyles}
        >{`What is your level of proficiency in [${question.group}] ?`}</span>
        <FiX
          size={24}
          onClick={() => removeQuestion(index)}
          className="cursor-pointer"
        />
      </div>
      <div className="flex justify-between items-end px-2 py-4">
        <div className="flex items-center space-x-3">
          <div>
            <label
              htmlFor="question"
              className={labelStyles}
            >{`${question.group} *`}</label>
            <input
              type="text"
              name="question"
              value={question.question}
              className={inputStyles}
              onChange={e => handleOnChange(e, index)}
              key="languageQuestion"
            />
          </div>
          <div>
            <label htmlFor="answer" className={labelStyles}>
              Ideal Answer
            </label>
            <div className="flex items-end">
              <select
                name="answer"
                value={question.answer}
                className={`${inputStyles} mr-2`}
                onChange={e => handleOnChange(e, index)}
              >
                <option value="">Please Choose</option>
                <option value="beginner">Beginner</option>
                <option value="intermediate">Intermediate</option>
                <option value="expert">Expert</option>
                <option value="native">Native</option>
              </select>
              <span className={labelStyles}>minimum</span>
            </div>
          </div>
        </div>
        <div className="flex items-end">
          <input
            type="checkbox"
            name="mustHave"
            className="mr-2 w-5 h-5 accent-green-600"
            value={question.mustHave}
            onChange={e => handleCheckbox(e, index)}
          />
          <label htmlFor="mustHave" className={labelStyles}>
            Must-have qualification
          </label>
        </div>
      </div>
    </div>
  )
}

export const Industry = ({
  question,
  index,
  handleOnChange,
  removeQuestion,
  handleCheckbox,
}) => {
  return (
    <div className="rounded-lg border border-gray-200">
      <div className="flex justify-between items-center px-2 py-3 bg-slate-200 rounded-t-lg">
        <span
          className={labelStyles}
        >{`How many years of [${question.group}] experience do you currently have?`}</span>
        <FiX
          size={24}
          onClick={() => removeQuestion(index)}
          className="cursor-pointer"
        />
      </div>
      <div className="flex justify-between items-end px-2 py-4">
        <div className="flex items-center space-x-3">
          <div>
            <label
              htmlFor="question"
              className={labelStyles}
            >{`${question.group} *`}</label>
            <input
              type="text"
              name="question"
              value={question.question}
              className={inputStyles}
              onChange={e => handleOnChange(e, index)}
              key="industryQuestion"
            />
          </div>
          <div>
            <label htmlFor="answer" className={labelStyles}>
              Ideal Answer
            </label>
            <div className="flex items-end">
              <input
                type="text"
                name="answer"
                value={question.answer}
                className={`${inputStyles} w-10 mr-2`}
                onChange={e => handleOnChange(e, index)}
                key="industryAnswer"
              />
              <span className={labelStyles}>minimum</span>
            </div>
          </div>
        </div>
        <div className="flex items-end">
          <input
            type="checkbox"
            name="mustHave"
            className="mr-2 w-5 h-5 accent-green-600"
            value={question.mustHave}
            onChange={e => handleCheckbox(e, index)}
          />
          <label htmlFor="mustHave" className={labelStyles}>
            Must-have qualification
          </label>
        </div>
      </div>
    </div>
  )
}

export const Custom = ({
  question,
  index,
  handleOnChange,
  removeQuestion,
  handleCheckbox,
}) => {
  return (
    <div className="rounded-lg border border-gray-200">
      <div className="flex justify-between items-center px-2 py-3 bg-slate-200 rounded-t-lg">
        <span className={labelStyles}>Write a custom screening question.</span>
        <FiX
          size={24}
          onClick={() => removeQuestion(index)}
          className="cursor-pointer"
        />
      </div>
      <div className="px-2 py-4">
        <input
          type="text"
          name="question"
          value={question.question}
          placeholder="Try asking a question like, 'Will you be able to bring your own device?'"
          className="text-black text-sm px-2 py-4 border border-gray-300 rounded w-full placeholder:text-gray-400 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-0"
          onChange={e => handleOnChange(e, index)}
          key="customQuestion"
        />
      </div>
      <div className="flex justify-between items-end px-2 py-4">
        <div className="flex items-end space-x-3">
          <div>
            <label
              htmlFor={`custom-response-type-${index}`}
              className={labelStyles}
            >
              Response Type
            </label>
            <div className="flex items-end">
              <select
                name={`custom-response-type-${index}`}
                className={`${inputStyles} mr-2`}
              >
                <option value="yesOrNo">Yes / No</option>
              </select>
              <span className={labelStyles}>minimum</span>
            </div>
          </div>
          <div>
            <label htmlFor="answer" className={labelStyles}>
              Ideal Answer
            </label>
            <div className="flex items-end">
              <select
                name="answer"
                value={question.answer}
                className={`${inputStyles} mr-2`}
                onChange={e => handleOnChange(e, index)}
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
              <span className={labelStyles}>minimum</span>
            </div>
          </div>
        </div>

        <div className="flex items-end">
          <input
            type="checkbox"
            name="mustHave"
            className="mr-2 w-5 h-5 accent-green-600"
            value={question.mustHave}
            onChange={e => handleCheckbox(e, index)}
          />
          <label htmlFor="mustHave" className={labelStyles}>
            Must-have qualification
          </label>
        </div>
      </div>
    </div>
  )
}
