import React, { useState, useEffect, useRef } from 'react'
import Swal from "sweetalert2"

const Timer = ({time, gotoNextQuestion}) => {
    const Ref = useRef(null);
   
    const [timer, setTimer] = useState('00:00');

    const getTimRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        return {
            total, minutes, seconds
        }; 
    }

    const startTimer = (e) => {
        let {total, minutes, seconds} = getTimRemaining(e);
        if (total >= 0) {
            setTimer(
                (minutes > 9? minutes : '' + minutes) + ':' 
               + (seconds > 9 ? seconds : '0' + seconds) 
            )
        }
    if (total === 0.00) {
        gotoNextQuestion()
     }
    }

    const clearTimer = (e) => {
        setTimer();
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDateTime = () => {
        let timeInSeconds = time * 60
        let deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + timeInSeconds);
        return deadline;
      }

    useEffect(() => {
        clearTimer(getDateTime());
    }, [time]);

    const onClickReset = () => {
        clearTimer(getDateTime());
    }
  return (
    <div>
    <h2>{timer}</h2>
    </div>
  )
}

export default Timer