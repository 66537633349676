import { useEffect, useState, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import PhoneInput from "react-phone-input-2"
import Select from "react-select"
import * as yup from "yup"
import { useFormik } from "formik"
import "react-phone-input-2/lib/style.css"
import { Button, Grid, Typography, Paper } from "@material-ui/core"
import AdminHeader from "../components/AdminHeader"
import axios from "../api/axios"
import useAuth from "../hooks/useAuth"
import AdminLayout from "../components/AdminLayout"
import { FaUser } from "react-icons/fa"
import { FiPlus } from "react-icons/fi"
// import useProfileChecker from "../hooks/useProfileChecker";
import { titleCase } from "../utils/helpers"
import { industry } from "../utils/data"
import countryList from "react-select-country-list"
import CompanyProfile from "../components/CompanyProfileForm/CompanyProfile"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import Loading from "../components/Loading"
import { Helmet } from "react-helmet-async"

const validationSchema = yup.object().shape({
  company_email: yup
    .string()
    .email("Enter a valid email")
    .required("This field is required."),
  company_logo: yup.lazy(value => {
    switch (typeof value) {
      case "string":
        return yup.string().required("This field is required.")
      case "object":
        return yup.mixed().required("This field is required.")
      default:
        return yup.string().required("This field is required.")
    }
  }),
  company_name: yup.string().required("This field is required."),
  company_website: yup
    .string()
    .url("Enter a valid url")
    .required("This field is required."),
  company_phone_number: yup.string().required("This field is required."),
  contact_name: yup.string().required("This field is required."),
  contact_phone_number: yup.string().required("This field is required."),
  country: yup.string().required("This field is required."),
  industry: yup.string().required("This field is required."),
})

const AdminProfile = () => {
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const options = useMemo(() => countryList().getData(), [])

  const [profile, setProfile] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const request = {
      signal: controller.signal,
    }

    const getCompanyProfile = async () => {
      const res = await axiosPrivate
        .get(`companies/${auth.companyId}`, request)
        .catch(async err => {
          console.error(err.response)
        })

      if (res) {
        isMounted && setProfile(res.data.data.company)
      }
      setIsLoading(false)
    }

    getCompanyProfile()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [auth])

  const formik = useFormik({
    initialValues: {
      company_email: profile?.company_email,
      company_logo: profile?.company_logo,
      industry: profile?.industry,
      company_name: profile?.company_name,
      company_website: profile?.company_website,
      company_phone_number: profile?.company_phone_number,
      contact_name: profile?.contact_name,
      contact_phone_number: profile?.contact_phone_number,
      country: profile?.country,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      if (typeof values.company_logo === "object") {
        const formData = new FormData()
        formData.append("file", values.company_logo)
        const res = await axiosPrivate
          .post(`storage/upload-image`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: `Bearer ${auth?.accessToken}`,
            },
          })
          .catch(err => console.error(err))

        if (res) {
          const response = await axiosPrivate
            .put(`companies/${auth.companyId}`, {
              company_email: values.company_email,
              company_logo: res.data.data.filename,
              industry: values.industry,
              company_name: values.company_name,
              company_website: values.company_website,
              company_phone_number: values.company_phone_number,
              contact_name: values.contact_name,
              contact_phone_number: values.contact_phone_number,
              country: values.country,
            })
            .catch(error => {
              Swal.fire({
                toast: true,
                icon: "error",
                title: "Oops...",
                text: error.response.data.message,
                position: "top",
                timer: 3000,
                showConfirmButton: false,
              })
            })

          if (response) {
            Swal.fire({
              toast: true,
              icon: "success",
              text: "Admin Profile updated successfully",
              position: "top",
              timer: 3000,
              showConfirmButton: false,
            })
            resetForm()
            navigate("/admin-dashboard/")
          }
        }
      } else {
        const response = await axiosPrivate
          .put(`companies/${auth.companyId}`, {
            company_email: values.company_email,
            company_logo: values.company_logo,
            industry: values.industry,
            company_name: values.company_name,
            company_website: values.company_website,
            company_phone_number: values.company_phone_number,
            contact_name: values.contact_name,
            contact_phone_number: values.contact_phone_number,
            country: values.country,
          })
          .catch(error => {
            Swal.fire({
              toast: true,
              icon: "error",
              title: "Oops...",
              text: error.response.data.message,
              position: "top",
              timer: 3000,
              showConfirmButton: false,
            })
          })

        if (response) {
          Swal.fire({
            toast: true,
            icon: "success",
            text: "Admin Profile updated successfully",
            position: "top",
            timer: 3000,
            showConfirmButton: false,
          })
          resetForm()
          navigate("/admin-dashboard/")
        }
      }
    },
  })

  if (isLoading) {
    return (
      <AdminLayout page="profile">
        <Helmet>
          <title>Edge</title>
        </Helmet>

        <Loading />
      </AdminLayout>
    )
  }

  return (
    <>
      <AdminLayout page="profile" role={auth?.role}>
        <Helmet>
          <title>{profile?.company_name} | Edge</title>
        </Helmet>

        <AdminHeader />
        <div className="w-full">
          <div className="mx-auto rounded-2xl bg-white pb-2 shadow-xl lg:w-3/5 sm:w-3/4 w-full py-6 px-4 sm:mt-24 mt-0 sm:mb-12 mb-0">
            <p className="text-black text-lg text-center font-bold">
              UPDATE ADMIN PROFILE
            </p>
            <div className="my-4 p-4 text-black">
              <div className="flex justify-center items-center">
                {profile?.company_logo ? (
                  <div className="h-36 w-36 bg-white relative flex items-center justify-center rounded-full">
                    <img
                      crossOrigin="anonymous"
                      src={`${process.env.REACT_APP_BASE_URL}storage/images/${profile?.company_logo}`}
                      className="w-full h-full object-center object-contain rounded-full"
                      alt=""
                    />
                  </div>
                ) : (
                  <div className="h-36 w-36 bg-gray-100 relative flex items-center justify-center rounded-full">
                    <FaUser size={100} className="text-white" />
                  </div>
                )}
              </div>

              <CompanyProfile formik={formik} />
            </div>
          </div>
        </div>
      </AdminLayout>
    </>
  )
}

export default AdminProfile
