import { Box, TextField, Button, MenuItem } from "@mui/material"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { titleCase } from "../../utils/helpers"
import countryList from "react-select-country-list"
import { useMemo } from "react"

const Personal = ({ formik }) => {
  const options = useMemo(() => countryList().getData(), [])

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        width: "100%",
        mt: "1rem",
        mx: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // "& .MuiTextField-root": { mt: "1rem" },
        "& .MuiSvgIcon-root": {
          color: "#000000",
        },
      }}
      onSubmit={formik.handleSubmit}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "1rem",
          width: "100%",
        }}
      >
        {/**first name */}
        <Box sx={{ width: "49%" }}>
          <TextField
            required
            variant="outlined"
            fullWidth
            size="small"
            id="first_name"
            label="First Name"
            value={titleCase(formik.values.first_name)}
            onChange={formik.handleChange("first_name")}
            error={
              formik.touched.first_name && Boolean(formik.errors.first_name)
            }
            helperText={formik.touched.first_name && formik.errors.first_name}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        {/**last name */}
        <Box sx={{ width: "49%" }}>
          <TextField
            required
            variant="outlined"
            fullWidth
            size="small"
            id="last_name"
            label="Last Name"
            value={titleCase(formik.values.last_name)}
            onChange={formik.handleChange("last_name")}
            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
            helperText={formik.touched.last_name && formik.errors.last_name}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "1rem",
          width: "100%",
        }}
      >
        {/**Email Address */}
        <Box sx={{ width: "49%" }}>
          <TextField
            required
            variant="outlined"
            fullWidth
            size="small"
            id="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange("email")}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        {/*Phone Number*/}
        <Box sx={{ width: "49%", color: "#000000" }}>
          <PhoneInput
            inputProps={{
              id: "phone_number",
              required: true,
            }}
            inputStyle={{ width: "100%" }}
            buttonStyle={{
              backgroundColor: "#FFFFFF",
              borderRight: "0px",
            }}
            dropdownStyle={{ height: "120px" }}
            country={"ng"}
            value={formik.values.phone_number}
            placeholder="+234 812 345 6789"
            onChange={phone => formik.setFieldValue("phone_number", phone)}
            autoComplete="off"
          />

          {Boolean(formik.errors.phone_number) && (
            <p className="text-[#d32f2f] text-xs my-1 ml-[14px] tracking-[0.0333em]">
              {formik.touched.phone_number && formik.errors.phone_number}
            </p>
          )}
        </Box>
      </Box>

      <Box sx={{ width: "100%", mt: "1rem" }}>
        <TextField
          required
          variant="outlined"
          fullWidth
          size="small"
          id="country"
          label="Country"
          value={formik.values.country}
          onChange={formik.handleChange("country")}
          error={formik.touched.email && Boolean(formik.errors.country)}
          helperText={formik.touched.email && formik.errors.country}
          InputLabelProps={{ shrink: true }}
          select
          SelectProps={{ MenuProps: { disableScrollLock: true } }}
        >
          {options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "1rem",
          width: "100%",
        }}
      >
        {/*Linkedin */}
        <Box sx={{ width: "49%" }}>
          <TextField
            required
            variant="outlined"
            fullWidth
            size="small"
            id="linkedin"
            label="Linkedin"
            value={formik.values.linkedin}
            onChange={formik.handleChange("linkedin")}
            error={formik.touched.linkedin && Boolean(formik.errors.linkedin)}
            helperText={formik.touched.linkedin && formik.errors.linkedin}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        {/* Github */}
        <Box sx={{ width: "49%" }}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            id="github"
            label="Github"
            value={formik.values.github}
            onChange={formik.handleChange("github")}
            error={formik.touched.github && Boolean(formik.errors.github)}
            helperText={formik.touched.github && formik.errors.github}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
        <Button
          variant="contained"
          type="submit"
          sx={{
            backgroundColor: "#3F51B5",
            mt: "1rem",
            py: "0.5rem",
            px: "1.5rem",
            "&:hover": {
              backgroundColor: "#3F51B5",
            },
          }}
        >
          NEXT
        </Button>
      </Box>
    </Box>
  )
}
export default Personal
