import { useEffect, useState } from "react"
import { useParams, useNavigate, useLocation, Link } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import { FaLongArrowAltLeft } from "react-icons/fa"
import AdminLayout from "../../components/AdminLayout"
import AdminHeader from "../../components/AdminHeader"
import { ImSpinner3 } from "react-icons/im"
import EditAssessment from "../../components/ManageAssessment/EditAssessment"
import { Helmet } from "react-helmet-async"

const AssessmentDetails = () => {
  const [data, setData] = useState([])
  let params = useParams()

  const navigate = useNavigate()
  const location = useLocation()
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const request = {
      signal: controller.signal,
      headers: {
        authorization: `Bearer ${auth?.accessToken}`,
      },
    }

    const getData = async () => {
      if (!params?.id) {
        return
      }

      const response = await axiosPrivate
        .get(`assessment/question/${params?.id}`, request)
        .catch(error => {
          console.error(error?.response)
          // navigate("/admin-login", { state: { from: location }, replace: true })
        })
      if (response?.data) {
        isMounted && setData(response?.data?.data)
      }
    }

    getData()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [params.id])

  return (
    <AdminLayout page="" role={auth?.role}>
      <Helmet>
        <title>Assessments | Edge</title>
      </Helmet>
      <AdminHeader page="" />

      {isOpen && (
        <EditAssessment
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          data={data}
          id={params?.id}
        />
      )}

      {data ? (
        <div className="px-12 py-6 space-y-4">
          <button
            onClick={() => navigate(-1)}
            className="flex py-1.5 px-6 text-black items-center hover:bg-gray-200 hover:w-1/5 hover:rounded-2xl"
          >
            <FaLongArrowAltLeft className="mr-2" />
            Go back
          </button>

          <div className="bg-white py-6 px-4 rounded-md shadow transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
            <div className="py-3 text-black">
              <div className="mt-4">
                <span className="font-bold mb-2 hover:bg-gray-100 rounded-md p-3 transition duration-300 ease-in-out">
                  Questions
                </span>

                {data.map((question, index) => (
                  <div
                    key={index}
                    className="mb-2 hover:bg-gray-100 rounded-md p-3 transition duration-300 ease-in-out"
                  >
                    <p className="font-semibold">
                      {index + 1}. {question.question}
                    </p>

                    <div className="flex justify-between">
                      <p className="p-2 rounded-lg hover:bg-gray-200 hover:text-black transition duration-300">
                        A. {question.option_A}
                      </p>
                      <p className="p-2 rounded-lg hover:bg-gray-200 hover:text-black transition duration-300">
                        B. {question.option_B}
                      </p>
                      <p className="p-2 rounded-lg hover:bg-gray-200 hover:text-black transition duration-300">
                        C. {question.option_C}
                      </p>
                      <p className="p-2 rounded-lg hover:bg-gray-200 hover:text-black transition duration-300">
                        D. {question.option_D}
                      </p>
                    </div>

                    <p className="p-2 rounded-lg hover:bg-gray-200 hover:text-black transition duration-300">
                      Correct Option: {question.correct_option}
                    </p>
                    <p className="p-2 rounded-lg hover:bg-gray-200 hover:text-black transition duration-300">
                      Time allowed per question: {question.time}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex justify-between mt-12">
              <button
                onClick={() => setIsOpen(true)}
                className="bg-blueBtn text-white text-sm py-1.5 px-4 rounded-2xl mt-2.5 flex justify-between transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg"
              >
                Edit Assessment
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-screen -mt-16">
          <span className="flex text-black space-x-3 items-center justify-center ">
            <ImSpinner3 size={48} className="animate-spin" />
            <p className="text-3xl">Loading</p>
          </span>
        </div>
      )}
    </AdminLayout>
  )
}

export default AssessmentDetails
