import Sidebar from "../components/Sidebar"
import OnboardingNav from "../components/OnboardingNav"
import useProfileChecker from "../hooks/useProfileChecker"

const Onboarding = () => {
  const profileChecker = useProfileChecker()
  profileChecker()

  return (
    <div className="flex w-full min-h-screen bg-slate-50 font-roboto">
      <Sidebar />
      <OnboardingNav />
    </div>
  )
}

export default Onboarding
