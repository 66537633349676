import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import axios from "../api/axios"
import "../styles/loginBG.css"
import * as yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert2"
import { BsEye, BsEyeSlash } from "react-icons/bs"
import { Box } from "@mui/material"
import usePasswordToggle from "../hooks/usePasswordToggle"
import { Helmet } from "react-helmet-async"

const validationSchema = yup.object({
  password: yup
    .string("Enter your password")
    .required("This field is required")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
  confirm_password: yup
    .string("Confirm your password")
    .required("This field is required")
    .oneOf([yup.ref("password"), null], "Passwords don't match."),
})

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState("false")
  const navigate = useNavigate()
  const [message, setMessage] = useState("")
  const params = useParams()

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      const res = await axios
        .post(`auth/change-password/${params.code}`, {
          password: values.password,
          confirm_password: values.confirm_password,
        })
        .catch(err => {
          setMessage(err.response.data.message)
        })
      if (res.status === 201) {
        resetForm()
        Swal.fire({
          toast: true,
          icon: "success",
          title: "Your password has been reset successfully.",
          position: "top",
          timer: 3000,
          showConfirmButton: false,
        })
        navigate("/")
      }
    },
  })

  const show = showPassword ? (
    <BsEyeSlash
      color=" #1E3A8A"
      size={18}
      icon={showPassword ? "eye-slash" : "eye"}
      onClick={() => setShowPassword(visibility => !visibility)}
    />
  ) : (
    <BsEye
      color=" #1E3A8A"
      size={18}
      icon={showPassword ? "eye-slash" : "eye"}
      onClick={() => setShowPassword(visibility => !visibility)}
    />
  )

  const [PasswordInputType, ToggleIcon] = usePasswordToggle()

  return (
    <div className="h-screen bg-slate-200 flex justify-center items-center w-screen relative">
      <Helmet>
        <title>Reset Password | Edge</title>
      </Helmet>

      <div className="background">
        <div className="absolute top-4 left-4">
          <div className="h-24 w-24">
            <img src={require("../assets/logopic.png")} alt="" />
          </div>
        </div>

        <form
          className="flex justify-center items-center flex-col w-screen h-screen"
          onSubmit={formik.handleSubmit}
        >
          <div className="bg-slate-50 px-10 py-8 rounded-xl shadow-md max-w-sm">
            <div className="space-y-3">
              <h1 className="text-center text-xl font-semibold text-gray-600">
                Reset Password
              </h1>
              <div>
                {message ? (
                  <p className="text-danger text-red-600 text-sm">{message}</p>
                ) : null}
              </div>

              {/**password */}
              <div className="mt-2">
                <label
                  htmlFor="password"
                  className="block text-gray-600 font-semibold text-base"
                >
                  Password
                </label>
                <input
                  type={showPassword ? "password" : "text"}
                  className="text-black bg-indigo-50 px-4 py-2 outline-none rounded-md w-full text-base"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange("password")}
                  onBlur={formik.handleBlur}
                  placeholder="Password"
                  id="password"
                  autoComplete="off"
                />
                <Box
                  sx={{
                    position: "relative",
                    left: "9.5rem",
                    bottom: "2rem",
                    cursor: "pointer",
                  }}
                >
                  {show}
                </Box>
                <div className="text-danger text-red-600 text-sm">
                  {formik.touched.password && formik.errors.password}
                </div>
              </div>

              {/**confirm password */}
              <div className="mt-2">
                <label
                  htmlFor="confirm_password"
                  className="block text-gray-600 font-semibold text-base"
                >
                  Confirm Password
                </label>
                <input
                  type={PasswordInputType}
                  className="text-black bg-indigo-50 px-4 py-2 outline-none rounded-md w-full text-base"
                  name="confirm_password"
                  value={formik.values.confirm_password}
                  onChange={formik.handleChange("confirm_password")}
                  onBlur={formik.handleBlur}
                  placeholder="Confirm Password"
                  id="confirm_password"
                  autoComplete="off"
                />
                <Box
                  sx={{
                    position: "relative",
                    left: "9.5rem",
                    bottom: "2rem",
                    cursor: "pointer",
                  }}
                >
                  {ToggleIcon}
                </Box>
                <div className="text-danger text-red-600 text-sm">
                  {formik.touched.confirm_password &&
                    formik.errors.confirm_password}
                </div>
              </div>
            </div>

            <div className="mt-2">
              <button
                className="w-full bg-blue-900 font-semibold py-2 rounded-md text-base tracking-wide"
                onClick={e => {
                  formik.handleSubmit(e)
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
export default ResetPassword
