import React, { useState, useEffect } from "react"
import useAuth from "../../hooks/useAuth"
import { useNavigate } from "react-router-dom"
import Button from "@mui/material/Button"
import { withStyles } from "@material-ui/core/styles"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import axios from "../../api/axios"
import Swal from "sweetalert2"

import "./style.scss"
//import LeaveRequestAPI from "../../api/leaveRequestAPI";
//import Pagination from "../Pagination";

const styles = theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "solid red",
    borderRadius: "5px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    width: "30vw",
    fontFamily: "'Poppins', sans-serif",
    boxShadow: "0px 0px 13px -5px rgba(0,0,0,0.75), inset 0 0 1px #000000",
  },
  modalTitle: {
    margin: 0,
    color: "#ffffff",
    padding: "0.5em",
    backgroundColor: "#505050",
    borderBottom: "1px solid #000000",
    borderRadius: "5px 5px 0 0",
  },
  modalText: {
    padding: "1em",
  },
  modalButtons: {
    display: "flex",
    justifyContent: "space-evenly",
    padding: "1em",
  },
})

const RequestsTable = () => {
  const { auth } = useAuth()

  const [data, setData] = useState()

  //const axiosPrivate = useAxiosPrivate();
  //const [isTouch, setIsTouch] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const request = {
      signal: controller.signal,
      headers: {
        authorization: `Bearer ${auth?.accessToken}`,
      },
    }

    const fetchData = async () => {
      const res = await axios.get("leave/", request).catch(err => {
        console.error(err.response)
      })

      if (res) {
        isMounted && setData(res.data?.data)
      }
    }

    fetchData()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [auth])

  const changeFilter = e => {
    // leaveRequestAPI.getAllLeaveRequests(true, e.target.value).then(res => {
    //     const { data } = res;
    //     this.setState({ userLeaves: data });
    // });
  }

  const handleAccept = item => {
    Swal.fire({
      title: "Are you sure you want to accept this leave request?",
      text: "This action cannot be undone",
      icon: "warning",
      showCancelButton: "true",
      confirmButtonColor: "#3085d5",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, accept it!",
    }).then(async result => {
      if (result.isConfirmed) {
        const res = await axios.put(`leave/${auth.id}`).catch(err => {
          console.error(err.response)
        })

        if (res?.status === 201) {
          Swal.fire("Accepted!", "Leave Accepted.", "success")
          navigate("/admin-dashboard/")
        }
      }
    })
  }

  const handleReject = item => {
    Swal.fire({
      title: "Are you sure you want to reject this leave request?",
      text: "This action cannot be undone",
      icon: "warning",
      showCancelButton: "true",
      confirmButtonColor: "#3085d5",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reject it!",
    }).then(async result => {
      if (result.isConfirmed) {
        const res = await axios.delete(`leave/${item?.id}`).catch(err => {
          console.error(err.response)
        })

        if (res?.status === 201) {
          Swal.fire("Rejected!", "Leave Rejected.", "success")
          navigate("/admin-dashboard/")
        }
      }
    })
  }

  return (
    <div className="container-datatable">
      <div className="datatable">
        <div className="datatable-upperPanel">
          <input
            id="filterInput"
            onChange={changeFilter}
            placeholder="Enter a name:"
            maxLength="90"
          ></input>
        </div>
        <div className="datatable-lowerPanel">
          <table>
            <thead>
              <tr>
                <th>
                  <p></p>
                </th>
                <th>
                  <p>Name</p>
                </th>
                <th>
                  <p>Email</p>
                </th>
                <th>
                  <p>Type</p>
                </th>
                <th>
                  <p>Start Date</p>
                </th>
                <th>
                  <p>End Date</p>
                </th>
                <th>
                  <p>Status</p>
                </th>
              </tr>
            </thead>
            <tbody style={{ color: "black" }}>
              {/* {data?.length > 0 && */}
              {data?.map((item, index) => {
                //Data tag substitutes value in this case
                //It can be reused in the modal filed through onClick function in the state(targetLeaveValue)

                return (
                  <tr key={item?.id} data-tag={item?.id}>
                    <td>
                      {" "}
                      <div style={{ display: "flex" }}>
                        {" "}
                        <Button
                          variant="contained"
                          onClick={() => handleAccept(item, index)}
                          style={{
                            margin: "3px",
                            backgroundColor: "#3f51b5",
                            textTransform: "lowercase",
                            width: "20px",
                            fontSize: "12px",
                          }}
                        >
                          accept
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleReject(item)}
                          style={{
                            margin: "3px",
                            textTransform: "lowercase",
                            width: "20px",
                            fontSize: "12px",
                          }}
                        >
                          delete
                        </Button>
                      </div>
                    </td>
                    <td>{item?.name}</td>
                    <td>{item?.email}</td>
                    <td>{item?.type}</td>
                    <td>{item?.start_date}</td>
                    <td>{item?.end_date}</td>
                    <td>{item?.status}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(RequestsTable)
